<div *ngIf="!closeAlert" class="'alert alert-{{type}} alert-dismissible fade show" role="alert" data-cy="snackbar-component">
  <ng-container [ngSwitch]="type">
    <i *ngSwitchCase="'error' || 'retry'" class="mr-3 bi bi-exclamation-circle"></i>
    <i *ngSwitchCase="'success'" class="mr-3 bi bi-check-circle"></i>
  </ng-container>
  <p data-cy="snackbar-text">{{message}}</p>
  <button [ngSwitch]="type" type="button" class="close" aria-label="Close" (click)="handleClose()">
    <img *ngSwitchCase="'success'" src="assets/account/icon-close-circle.svg" alt="Fechar aviso" />
    <img *ngSwitchCase="'error'" src="assets/account/icon-close-circle.svg" alt="Fechar aviso" />
    <img *ngSwitchCase="'retry'" src="assets/dark-theme/icon-retry.svg" (click)="onRetry()" alt="Tentar novamente" />
  </button>
</div>
