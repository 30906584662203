import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output,
} from '@angular/core';
import { SubCategoryWSelected } from '@shared/interfaces/backoffice/products/subcategory';


@Component({
  selector: 'app-dropdown-subcategories',
  templateUrl: './dropdown-subcategories.component.html',
  styleUrls: [ './dropdown-subcategories.component.scss' ],
})
export class DropdownSubcategoriesComponent implements OnChanges {
  @Input() options: SubCategoryWSelected[];
  @Output() filterEvent = new EventEmitter<string[]>();
  selected = false;
  selectedList = [];
  allSelected: boolean;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.selected = false;
  }

  onSelectAll(filter: string = null): void {
    this.options.forEach((option) => {
      if (option.name.toLowerCase().startsWith(filter.toLowerCase())) {
        option.selected = true;
      }
    });
    this.allSelected = true;
  }

  onUnselectAll(): void {
    this.options.forEach((option) => {
      option.selected = false;
    });
    this.allSelected = false;
  }

  onFilter(): void {
    this.options.map((option) => {
      if (option.selected) {
        this.selectedList.push(option.id);
      }
    });

    if (this.selectedList.length !== 0) {
      this.selected = true;
    } else {
      this.selected = false;
    }
    this.filterEvent.emit(this.selectedList);
  }
}
