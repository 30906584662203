import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-area',
  templateUrl: './brand-area.component.html',
  styleUrls: [ './brand-area.component.scss' ],
})
export class BrandAreaComponent {
  constructor() {}
}
