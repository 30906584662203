import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-submit-button',
  templateUrl: './form-submit-button.component.html',
  styleUrls: [ './form-submit-button.component.scss' ],
})
export class FormSubmitButtonComponent implements OnInit {
  @Input() imageSrc = 'assets/dark-theme/products/add-products/single/icon-check-circle.svg';
  @Input() imageAlt: string;
  @Input() title: string;
  @Input() iconLeft = false;
  @Input() iconRight = false;
  @Input() loading = true;
  @Input() disabled: boolean;

  formGroup: FormGroup;
  constructor(private rootFromGroup: FormGroupDirective) { }

  ngOnInit(): void {
    // faz o bind com o primeiro que acha na hierarquia de componentes a partir do componente filho
    this.formGroup = this.rootFromGroup.control;
  }

}
