import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-check-feat',
  templateUrl: './check-feat.component.html',
  styleUrls: [ './check-feat.component.scss' ],
})
export class CheckFeatComponent {
  @Input() feat: string;
  @Input() state: boolean;
}
