<section class="row h-100 p-0 m-0">
  <div id="login_brand_area">
    <div class="text-center" id="logo_container">
      <img src="assets/dark-theme/account/bag.svg" alt="image placeholder"
        id="backoffice_logo_placeholder" />
      <div id="text_under_logo">
        <h1 class="font-title-regular">As melhores ofertas da <br> região</h1>
        <div class="subtext">
          <p class="font-title-regular">
            Conectamos quem quer vender com quem quer economizar
          </p>
        </div>
      </div>
      <div class="shop_badges">
        <a href="https://play.google.com/store/apps/details?id=br.com.tokenlab.my_promo" target="_blank"
        data-cy="button-play-store"><img src="assets/account/google-play-badge.png"
            alt="Disponível na Google Play" id="google-play-badge" /></a>
        <a href="#" data-cy="button-app-store"><img src="assets/account/apple_store_badge.svg"
            alt="Disponível na Apple Store" id="apple-store-badge" /></a>
      </div>
    </div>
  </div>
</section>
