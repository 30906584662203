import { PartitionItemByCSV } from './../interfaces/backoffice/addBatch';
import {
  BehaviorSubject,
  concat,
  Observable,
  of,
  pipe,
  Subscription,
  throwError,
} from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OffersService } from '@services/offers.service';
import { ProductsService } from '@services/products.service';
import { StoresService } from '@services/stores.service';
import { ItemByCSV, TargetType } from '@shared/interfaces/backoffice/addBatch';
import { ProductByCSV } from '@shared/interfaces/backoffice/products/product';
import { StoreByCSV } from '@shared/interfaces/backoffice/stores/store';
import { ItemAdditionError } from '@shared/interfaces/errorObjects';
import { BatchFormatValidationService } from '@shared/services/batch-format-validation.service';
import { ProcessCsvFileService } from '@shared/services/process-csv-file.service';
import { assertNever } from '@shared/utils/assert';

import { Error } from '../graphql/products/products-graphql';
import { OfferByCSV } from '../interfaces/backoffice/offers/offers';
import { PopupService } from '@app/core/services/popup.service';
import { catchError, finalize, map, tap, toArray } from 'rxjs/operators';
import { GlobalConstants } from '@assets/globalConstants';
import { PopupData } from '../interfaces/Popup';

@Injectable({
  providedIn: 'root',
})
export class AddBatchHandlerService {
  validationSub: Subscription;
  uploadSub: Subscription;

  itemAdditionErrors = new BehaviorSubject<ItemAdditionError[]>([]);
  partitionsToAdd = new BehaviorSubject<PartitionItemByCSV[]>([]);
  uploadSuccess = new BehaviorSubject<boolean>(false);
  uploadFailure = new BehaviorSubject<boolean>(false);
  currentFile = new BehaviorSubject<File>(null);
  uploadProgress = new BehaviorSubject<number>(0);
  abortUpload = new BehaviorSubject<boolean>(false);
  fileSubmitted = new BehaviorSubject<boolean>(false);

  itemsQueueAdditionProgress = {
    progress: new BehaviorSubject<number>(0),
    totalSize: 0,
  };
  submitErrors: Error[] = [];
  timesToTryToSubmit: number;

  constructor(
    @Inject('targetType') private targetType: TargetType,
    private productsService: ProductsService,
    private storesService: StoresService,
    private offersService: OffersService,
    private popupService: PopupService,
    private processCsvFileService: ProcessCsvFileService,
    private batchFormatValidationService: BatchFormatValidationService,
    private route: ActivatedRoute,
  ) {
    this.validationSub =
      this.batchFormatValidationService.asyncValidationProgress.progress.subscribe(
        (v) => {
          //Impede barrinha de incrementar acima de 100
          const isIncrementInvalid = this.uploadProgress.getValue() + v > 100;
          this.updateUploadProgress(isIncrementInvalid ? 0 : v);
        },
      );

    this.abortUpload.subscribe((v) => {
      this.batchFormatValidationService.abortUpload = v;
    });
  }

  resetUpload(): void {
    this.startVariablesToFileUpload();
    this.currentFile.next(null);
  }

  async handleFileValidation(
    target: EventTarget,
  ): Promise<boolean | Observable<boolean>> {
    try {
      await this.processNewFile(target);
      return await this.validateFile();
    } catch (err) {
      return false;
    }
  }

  async processNewFile(target: EventTarget): Promise<void | Observable<void>> {
    this.abortUpload.next(false);
    this.startVariablesToFileUpload();
    try {
      this.currentFile.next((target as HTMLInputElement).files[0]);

      if (this.currentFile.value.size > 614400) throw 'FileSizeExceeded';

      this.partitionsToAdd.next(
        await this.processCsvFileService
          .setHeaders()
          .processFile(this.currentFile.getValue()),
      );

      if (this.targetType === 'stores') {
        if (
          this.processCsvFileService.getNumberOfItemsCSV() +
            GlobalConstants.userAttributes.storesCreated >
          5000
        ) {
          throw 'InvalidNumberOfStores';
        }
      }

      this.itemsQueueAdditionProgress.totalSize =
        this.partitionsToAdd.value.length;
      this.itemsQueueAdditionProgress.progress.next(0);
    } catch (err) {
      const popupInfo: Partial<PopupData> = {
        title: 'Erro na Planilha',
        description:
          'Falha ao validar planilha, tente novamente ou se persistir recarregue a página.',
        cancelText: 'Fechar',
      };

      if (err === 'InvalidTemplate')
        popupInfo.description =
          'A planilha enviada não utiliza o template correto.' +
          ' Por favor, baixe o template clicando no botão "Modelo de planilha" e tente novamente.';

      if (err === 'FileSizeExceeded')
        popupInfo.description =
          'A planilha enviada excedeu o tamanho máximo de 600Kb.' +
          ' Reduza o tamanho do arquivo e tente novamente.';

      if (err === 'InvalidNumberOfStores') {
        popupInfo.title = 'Atenção!';
        popupInfo.cancelText = 'Ok, entendi';
        popupInfo.description = `Você pode ter até <span>5000</span> estabelecimentos cadastrados no total. \
          Estabelecimentos cadastrados: <span>${
            GlobalConstants.userAttributes.storesCreated
          }</span>
          Cadastros restantes: <span>${
            5000 - GlobalConstants.userAttributes.storesCreated
          }</span>`;
      }

      this.resetUpload();
      this.popupService.information(popupInfo);
    }
  }

  async validateFile(): Promise<boolean | Observable<boolean>> {
    switch (this.targetType) {
      case 'products':
        return this.batchFormatValidationService
          .validateProducts(this.partitionsToAdd.getValue())
          .pipe(
            tap((itemAdditionError) => {
              this.itemAdditionErrors.next(itemAdditionError);
            }),
            map(() => {
              return this.itemAdditionErrors.getValue().length === 0;
            }),
            catchError((err) => {
              this.uploadFailure.next(true);
              return of(false);
            }),
            finalize(() => {
              this.uploadFailure.next(
                Boolean(this.itemAdditionErrors.getValue().length),
              );
              this.fileSubmitted.next(true);
            }),
          );

      case 'stores':
        return this.batchFormatValidationService
          .validateStores(this.partitionsToAdd.getValue())
          .pipe(
            tap((itemAdditionError) => {
              let noError = true;
              itemAdditionError.forEach((item) => {
                if (item.code || item.message) noError = false;
              });
              if (!noError) this.itemAdditionErrors.next(itemAdditionError);
            }),
            map(() => {
              return this.itemAdditionErrors.getValue().length === 0;
            }),
            catchError((err) => {
              this.uploadFailure.next(true);
              return of(false);
            }),
            finalize(() => {
              this.uploadFailure.next(
                Boolean(this.itemAdditionErrors.getValue().length),
              );
              this.fileSubmitted.next(true);
            }),
          );

      case 'offers':
        return this.batchFormatValidationService
          .validateOffers(this.partitionsToAdd.getValue())
          .pipe(
            tap((itemAdditionError) => {
              let noError = true;
              itemAdditionError.forEach((item) => {
                if (item.code || item.message) noError = false;
              });
              if (!noError) this.itemAdditionErrors.next(itemAdditionError);
            }),
            map(() => {
              return this.itemAdditionErrors.getValue().length === 0;
            }),
            catchError((err) => {
              this.uploadFailure.next(true);
              return of(false);
            }),
            finalize(() => {
              if(this.itemAdditionErrors.getValue().length > 0) this.uploadFailure.next(true);
              this.fileSubmitted.next(true);
            }),
          );
      default:
        return assertNever(this.targetType);
    }
  }

  async handleFileSubmit(): Promise<boolean> {
    try {
      if (this.uploadFailure.getValue()) {
        this.uploadProgress.next(100);
        return false;
      }

      if (this.abortUpload.getValue()) {
        return false;
      }

      this.itemAdditionErrors.next([]);

      let progressIncrement = 100 / this.partitionsToAdd.value.length;
      let partitionsNotSubmitted: PartitionItemByCSV[] = [];

      this.timesToTryToSubmit = 5;
      await Promise.all(
        this.partitionsToAdd.value.map(async (itemsPartition, i) => {
          try {
            await this.submitItemsPartition(itemsPartition);
            this.updateSubmitProgress(progressIncrement);
            return true;
          } catch (error) {
            console.error(`erro ao cadastrar partição ${i}`);
            partitionsNotSubmitted.push(itemsPartition);
            // return false;
            throw new Error(error);
          }
        }),
      );

      if (partitionsNotSubmitted.length === 0) return true;

      const itemsNotSubmitted: PartitionItemByCSV[] = [].concat.apply(
        [],
        partitionsNotSubmitted,
      );
      this.partitionsToAdd.next(itemsNotSubmitted);

      console.error(
        'PartiçonUploadFileToValidatee items que não foram possíveis serem cadastrados',
      );
    } catch (err) {
      console.error('erro handleFileSubmit', err);
      throw err;
    }
  }

  private startVariablesToFileUpload(): void {
    this.itemAdditionErrors.next([]);
    this.partitionsToAdd.next([]);
    this.uploadSuccess.next(false);
    this.uploadFailure.next(false);
    this.uploadProgress.next(0);
  }

  private updateUploadProgress = (v: number): void => {
    const currentProgress = this.uploadProgress.getValue();
    this.uploadProgress.next(currentProgress + v);
    const uploadDone = Math.round(this.uploadProgress.getValue()) === 100;
    if (uploadDone) this.uploadSuccess.next(true);
  };

  private updateSubmitProgress = (v: number): void => {
    const currentProgress = this.itemsQueueAdditionProgress.progress.getValue();
    this.itemsQueueAdditionProgress.progress.next(currentProgress + v);
  };

  private async submitItemsPartition(
    partition: PartitionItemByCSV,
  ): Promise<void> {
    switch (this.targetType) {
      case 'products':
        try {
          const { data } = await this.productsService.gqlCreateProductBatch(
            partition.items as ProductByCSV[],
            partition.authorizationKey,
            );
          this.submitErrors.push(...data.createProductBatch.errors);

          if (data.createProductBatch.errors?.length > 0) {
            console.error(
              'Partição com erros, não foi possível cadastrar',
              data.createProductBatch.errors,
            );
            throw new Error('Partição com erros');
          }

          return;
        } catch (error) {
          this.resendPartitiontIfFailed(partition);
          throw new Error('Partição não submetida.');
        }
      case 'stores':
        try {
          const { errors } = await this.storesService
            .createStoreBatch(
              partition.items as StoreByCSV[],
              partition.authorizationKey,
            )
            .toPromise();

          if (!errors) return;

          const gqlError: Error[] = errors.map((er) => {
            return {
              code: 5,
              name: er.name,
              message: er.message,
            };
          });

          this.submitErrors.push(...gqlError);

          if (gqlError?.length > 0) {
            console.error(
              'Partição com erros, não foi possível cadastrar',
              errors,
            );
            throw new Error('Partição com erros');
          }
        } catch (error) {
          this.resendPartitiontIfFailed(partition);
          throw new Error('Partição não submetida.');
        }
        // TODO: Finalizar processo de adicao de estabelecimentos pos endPoint ser criado
        return;
      case 'offers':
        try {
          const offers = partition.items as OfferByCSV[];

          const cityId = this.route.snapshot.data.store?.cityId;
          const storeId = this.route.snapshot.data.store?.id;

          let productIdObservables = [];

          offers.forEach((offer) => {
            productIdObservables.push(
              this.productsService.validateBarcode(offer.barcode).pipe(
                map((value) => {
                  return {
                    ...offer,
                    productId: value.data.getProductByBarcode.product.id,
                    cityId,
                    storeId,
                  };
                }),
              ),
            );
          });


          const offersToSend = await concat(...productIdObservables)
            .pipe(toArray())
            .toPromise();

          const { data } = await this.offersService.createOfferBatch(partition.authorizationKey ,offersToSend as OfferByCSV[]).toPromise();

          if (data.createOfferBatch.errors?.length > 0) {
            console.error(
              'Partição com erros, não foi possível cadastrar',
              data.createOfferBatch.errors,
            );
            throw new Error('Partição com erros');
          }
        } catch (err) {
          // this.resendPartitiontIfFailed(partition);
          throw err;
        }
        return;

      default:
        return assertNever(this.targetType);
    }
  }

  private async resendPartitiontIfFailed(
    partition: PartitionItemByCSV,
  ): Promise<void> {
    if (this.timesToTryToSubmit > 0) {
      this.timesToTryToSubmit--;
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve(true);
        }, 5000),
      );
      this.submitItemsPartition(partition);
    }
  }
}
