import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { PopupService } from '@services/popup.service';
import { ProductsService } from '@services/products.service';
import { SnackbarService } from '@services/snackbar.service';

import { AddBatchHandlerService } from '@shared/services/add-batch-handler.service';
import { assertNever } from '@shared/utils/assert';
import { ErrorTable, TargetType } from '@shared/interfaces/backoffice/addBatch';
import { ProcessCsvFileService } from '@app/shared/services/process-csv-file.service';
import { GlobalConstants } from '@assets/globalConstants';

enum SubmitButtonTitle {
  readyToSubmit,
  submitting,
  retryValidate,
  retrySubmit,
}

@Component({
  selector: 'app-add-group-page',
  templateUrl: './add-group-page.component.html',
  styleUrls: [ './add-group-page.component.scss' ],
})
export class AddGroupPageComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('snackbar', { read: ViewContainerRef, static: true })
  snackbar: ViewContainerRef;
  @Input() batchUpload: AddBatchHandlerService;
  @Input() errorTableParams: ErrorTable;
  waitingTemplate = false;
  isFileReadyToSubmit = false;
  isSubmiting = false;
  submitButtonTitle: SubmitButtonTitle = SubmitButtonTitle.readyToSubmit;
  subscription: Subscription;
  isValidating = false;

  constructor(
    @Inject('targetType') private templateType: TargetType,
    public addBatchHandlerService: AddBatchHandlerService,
    private productsService: ProductsService,
    private processCsvFileService: ProcessCsvFileService,
    private popupService: PopupService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.snackbarService.setRootViewContainerRef(this.snackbar);
  }

  get templateLink(): string {
    const baseUrl = 'https://mypromo-assets.s3.amazonaws.com/templates/sheets/';

    const templatesNames: {
      [key in TargetType]: string
    } = {
      stores: 'ModeloAdicaoEstabelecimentos.xlsx',
      products: 'ModeloAdicaoProdutos.xlsx',
      offers: 'ModeloAdicaoOfertas.xlsx',
    };

    return baseUrl + templatesNames[this.templateType];
  }

  resetVariables(): void {
    this.fileInput.nativeElement.value = '';
    this.addBatchHandlerService.resetUpload();
    this.isFileReadyToSubmit = false;
    this.submitButtonTitle = SubmitButtonTitle.readyToSubmit;
  }

  cancelFileUpload(): void {
    this.popupService.needToConfirm(
      {
        title: 'Parar o upload da planilha?',
        description: 'Voce poderá adicionar outra planilha a qualquer momento.',
        buttonTitle: 'Parar',
        buttonIcon: 'cancel',
      },
      () => {
        this.resetVariables();
        this.isValidating = false;
        this.addBatchHandlerService.abortUpload.next(true);
      },
    );
  }

  discardFile(): void {
    this.popupService.needToConfirm(
      {
        title: 'Descartar planilha?',
        description: 'Voce poderá adicionar outra planilha a qualquer momento.',
        buttonTitle: 'Descartar',
        buttonIcon: 'delete',
      },
      () => {
        this.resetVariables();
        this.isValidating = false;
        this.snackbarService.successSnackbar('Planilha descartada com sucesso!');
      },
    );
  }

  getMainButtonTitle(): string {
    switch(this.submitButtonTitle) {
      case SubmitButtonTitle.readyToSubmit:
        return 'Cadastrar';
      case SubmitButtonTitle.retryValidate:
      case SubmitButtonTitle.retrySubmit:
        return 'Tentar novamente';
      case SubmitButtonTitle.submitting:
        return 'Cadastrando...';
      default:
        return assertNever(this.submitButtonTitle);
    }
  }

  getMainButtonIsDisabled(): boolean {
    switch(this.submitButtonTitle) {
      case SubmitButtonTitle.retryValidate:
        return false;
      case SubmitButtonTitle.readyToSubmit:
      case SubmitButtonTitle.retrySubmit:
        return (
          !this.isFileReadyToSubmit ||
          this.batchUpload.itemAdditionErrors.getValue().length !== 0
        );
      case SubmitButtonTitle.submitting:
        return true;
      default:
        return assertNever(this.submitButtonTitle);
    }
  }

  async handleMainButton(): Promise<void> {
    switch (this.submitButtonTitle) {
      case SubmitButtonTitle.retryValidate:
        return this.retryValidateFile();
      case SubmitButtonTitle.readyToSubmit:
        this.popupService.needToConfirm(
          {
            title: 'Cadastrar planilha?',
            description:
              // eslint-disable-next-line max-len
              `Ao clicar em cadastrar, todos os ${this.errorTableParams.title} serão cadastrados e a planilha não poderá mais ser descartada.`,
            buttonTitle: 'Cadastrar',
            buttonIcon: 'create',
          },
          () => {
            return this.onSubmitFile();
          },
        );
        return;
      case SubmitButtonTitle.retrySubmit:
        return this.onSubmitFile();
    }
  }

  async uploadFileToValidate(target: EventTarget): Promise<void> {
    const didInputReceivedFile = !!(target as HTMLInputElement).files[0];
    if(!didInputReceivedFile){
      return;
    }

    if(this.isSubmiting) {
      this.popupService.information({
        title: 'Não é possível substituir a planilha',
        description: 'Não é possível substituir uma planilha em processo de cadastro.',
        cancelText: 'Ok',
      });
      return;
    }

    else if(this.isValidating || !!this.addBatchHandlerService.currentFile.value) {
      this.popupService.needToConfirm({
        title: 'Substituir planilha?',
        description: 'Você já possui uma planilha em andamento de cadastro no momento. Ao substituí-la, os dados serão perdidos.',
        cancelText: 'Cancelar',
        buttonTitle: 'Substituir planilha',
        buttonIcon: 'cancel',
      }, async () => {
        await this.onUploadFileToValidate(target);
      });
      return;
    }

    await this.onUploadFileToValidate(target);
  }

  async onUploadFileToValidate(target: EventTarget): Promise<void> {
    this.isValidating = true;
    this.addBatchHandlerService.resetUpload();
    this.isFileReadyToSubmit = false;
    this.subscription?.unsubscribe();

    const handleFileValidationReturn =
      await this.addBatchHandlerService.handleFileValidation(target);
    if (handleFileValidationReturn instanceof Observable) {
      this.subscription = handleFileValidationReturn
        .pipe(
          tap((value) => {
            this.isFileReadyToSubmit = value;
            this.isValidating = false;
            this.submitButtonTitle = SubmitButtonTitle.readyToSubmit;
          }),
          catchError((err) => {
            this.snackbarService.errorSnackbar(
              'Houve um erro na validação de sua planilha. Por favor, tente novamente.', 10000,
            );
            this.isValidating = false;
            this.submitButtonTitle = SubmitButtonTitle.retryValidate;
            return err;
          }),
          finalize(() => {
            this.subscription = null;
          }),
        )
        .subscribe();
    }
    (target as HTMLInputElement).value = '';
  }

  async retryValidateFile(): Promise<void> {
    try {
      this.submitButtonTitle = SubmitButtonTitle.readyToSubmit;
      const handleFileValidation =
        await this.addBatchHandlerService.validateFile();

      if (handleFileValidation instanceof Observable) {
        this.subscription = handleFileValidation
          .pipe(
            tap((value) => {
              this.isFileReadyToSubmit = value;
              this.isValidating = false;
              this.submitButtonTitle = SubmitButtonTitle.readyToSubmit;
            }),
            catchError((err) => {
              this.snackbarService.errorSnackbar(
                'Houve um erro na validação de sua planilha. Por favor, tente novamente.',
                10000,
              );
              this.isValidating = false;
              this.submitButtonTitle = SubmitButtonTitle.retryValidate;
              return err;
            }),
            finalize(() => {
              this.subscription = null;
            }),
          )
          .subscribe();
      }
    } catch {}
  }

  async submitFile(): Promise<void> {
    this.popupService.needToConfirm(
      {
        title: 'Cadastrar planilha?',
        description:
          `Ao clicar em cadastrar, todos os ${this.errorTableParams.title} serão cadastrados e a planilha não poderá mais ser descartada.`,
        buttonTitle: 'Cadastrar',
        buttonIcon: 'create',
      },
      () => {
        return this.onSubmitFile();
      },
    );
  }

  async onSubmitFile(): Promise<void> {
    if (this.isSubmiting) return;

    try {
      this.isSubmiting = true;
      this.submitButtonTitle = SubmitButtonTitle.submitting;
      const submitted = await this.addBatchHandlerService.handleFileSubmit();

      if (!submitted) throw new Error('Não foi possível fazer o submit.');

      this.snackbarService.successSnackbar(
        'Cadastro de planilha realizado com sucesso.',
      );

      this.isSubmiting = false;
      this.isFileReadyToSubmit = false;
      this.submitButtonTitle = SubmitButtonTitle.readyToSubmit;
      GlobalConstants.userAttributes.storesCreated += this.processCsvFileService.getNumberOfItemsCSV();
      this.resetVariables();
    } catch (error) {
      console.error('houve algum erro', error);
      this.snackbarService.errorSnackbar(
        'Ocorreu um erro no servidor. Clique em "Tentar novamente" para continuar o cadastro da planilha.',
        10000,
      );
      this.submitButtonTitle = SubmitButtonTitle.retrySubmit;
      this.isSubmiting = false;
    }
  }
}
