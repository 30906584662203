<div class="content_area justify-content-center text-center">
  <div class="new-password-area">
    <img
      class="dark-img"
      src="assets/dark-theme/account/backoffice_logo_login_dark.svg"
      alt="my promo logo dark"
    />
    <img
      class="light-img"
      src="assets/light-theme/logo_light.svg"
      alt="my promo logo white"
    />

    <div class="title text-center">
      <h3 [ngClass]="{ error: responseError }">{{ title }}</h3>
      <p [ngClass]="{ error: responseError }">{{ textMessage }}</p>
    </div>

    <ng-container *ngIf="!successSubmit; then formNewPassword"></ng-container>
  </div>
</div>

<div class="snackbar-container">
  <div class="snackbar">
    <ng-template #snackbar></ng-template>
  </div>
</div>

<ng-template #formNewPassword>
  <form [formGroup]="passwordForm" (ngSubmit)="submitNewPassword()">
    <app-form-text-input
      [inputType]="hide ? 'password' : 'text'"
      label="Senha"
      (iconClick)="hide = !hide"
      [iconName]="hide ? 'visibility_off' : 'visibility'"
      controlName="password"
      label="Senha"
      dataCy="input-password"
      (focusInput)="openTooltip = $event"
      [inputError]="handleFormError(passwordForm.get('password'))"
    >
    </app-form-text-input>
    <app-form-text-input
      [inputType]="hide ? 'password' : 'text'"
      label="Senha"
      [inputError]="handleFormError(passwordForm.get('confirmPassword'))"
      [iconName]="hide ? 'visibility_off' : 'visibility'"
      (iconClick)="hide = !hide"
      controlName="confirmPassword"
      label="Confirmar senha"
      dataCy="input-new-password"
    ></app-form-text-input>
    <app-form-submit-button
      title="Redefinir senha"
      [loading]="loading"
      [disabled]="passwordForm.invalid || loading"
    ></app-form-submit-button>
    <div class="pass-tooltip">
      <app-password-tooltip
        [controlPassword]="passwordForm.get('password')"
        *ngIf="openTooltip"
      ></app-password-tooltip>
    </div>
  </form>
</ng-template>
