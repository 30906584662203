<app-brand-area>
  <div class="col order-first order-xl-last text-center d-flex" id="login_area">
    <span class="return" (click)="navigateToRegister()">
      <img src="assets/dark-theme/account/arrow-back.svg" alt="my promo logo" />
      <p>Voltar</p>
    </span>
    <div class="card">
      <img src="assets/dark-theme/account/backoffice_logo_login_dark.svg" alt="my promo logo" />

      <div class="title text-center">
        <h3 class="font-subtitle-regular">Confirme sua conta</h3>
        <p class="font-subtitle-regular">
          Enviamos as instruções para confirmação da conta para <br>
          <b data-cy="text-email">{{userEmail}}</b>
        </p>
      </div>
      <div class="footer text-center">
        <p class="font-subtitle-regular">
          Não recebeu seu e-mail? <span matRipple class="dark-btn" (click)="resendEmail()">Clique para reenviar</span>
        </p>
      </div>
    </div>
    <ng-template #snackbar></ng-template>
  </div>
</app-brand-area>
