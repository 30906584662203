import { AccountRoutingModule } from './account-routing.module';
import { LoginPageComponent } from './login/login-page.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '@app/shared/shared.module';
import { MatRippleModule } from '@angular/material/core';

import { UserAccountService } from '@services/user-account.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrandAreaComponent } from './components/brand-area/brand-area.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ConfirmationEmailComponent } from './confirmation-email/confirmation-email.component';
import { SendEmailComponent } from './send-email/send-email.component';

import { CheckEmailComponent } from './reset-password/subpages/check-email/check-email.component';
import { ForgotPasswordComponent } from './reset-password/subpages/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './reset-password/subpages/new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordTooltipComponent } from './components/password-tooltip/password-tooltip.component';

@NgModule({
  declarations: [
    LoginPageComponent,
    BrandAreaComponent,
    CreateUserComponent,
    ConfirmationEmailComponent,
    SendEmailComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    ForgotPasswordComponent,
    CheckEmailComponent,
    ResetPasswordComponent,
    PasswordTooltipComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    AccountRoutingModule,
  ],
  providers: [ UserAccountService ],
  exports: [ PasswordTooltipComponent ],
})
export class AccountModule {}
