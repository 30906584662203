import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import {
  ListCategoriesGQL,
  ValidateProductsGQL,
  ValidateProductsInput,
  ValidateProductsQuery,
} from '@app/shared/graphql/products/products-graphql';
import { Measurement } from '@app/shared/interfaces/backoffice/products';
import { removeObjectEmptyFields } from '@app/shared/utils/removeObjectEmptyFields-util';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ImageHandlerService } from '@core/services/image-handler.service';
import {
  CreateProductBatchGQL,
  CreateProductBatchMutation,
  CreateProductGQL,
  CreateProductInput,
  CreateProductMutation,
  DeleteProductBatchGQL,
  DeleteProductBatchMutation,
  DeleteProductByIdGQL,
  DeleteProductByIdMutation,
  GetProductByBarcodeGQL,
  GetProductByBarcodeQuery,
  ListMeasureUnitsGQL,
  ListSubCategoriesGQL,
  MeasureUnit,
  Product,
  Scalars,
  UpdateProductGQL,
  UpdateProductInput,
  UpdateProductMutation,
} from '@shared/graphql/products/products-graphql';
import {
  SearchProductsGQL,
  SearchProductsInput,
  SearchProductsQuery,
  SearchProductsWOffersGQL,
  SearchProductsWOffersQuery,
} from '@shared/graphql/stores/stores-graphql';
import { CategoryWAsset } from '@shared/interfaces/backoffice/products/category';
import { ProductByCSV } from '@shared/interfaces/backoffice/products/product';

import { UserAccountService } from './user-account.service';
import { map, tap } from 'rxjs/operators';
import Categories from '@assets/mocks/categories';
import Subcategories from '@assets/mocks/subcategories';


const CATEGORIES_KEY = 'categories';
const MEASURE_UNITS_KEY = 'measure_units';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private ACCESS_TOKEN: string;

  private listMeasureUnits: Measurement[];

  constructor(
    private http: HttpClient,
    private userAccountService: UserAccountService,
    private createProductGQL: CreateProductGQL,
    private createProductBatchGQL: CreateProductBatchGQL,
    private updateProductGQL: UpdateProductGQL,
    private getProductByBarcodeGQL: GetProductByBarcodeGQL,
    private listCategoriesGQL: ListCategoriesGQL,
    private listSubCategoriesGQL: ListSubCategoriesGQL,
    private deleteProductByIdGQL: DeleteProductByIdGQL,
    private deleteProductBatchGQL: DeleteProductBatchGQL,
    private searchProductsGQL: SearchProductsGQL,
    private searchProductsWOffersGQL: SearchProductsWOffersGQL,
    private listMeasureUnitsGQL: ListMeasureUnitsGQL,
    private validateProductsGQL: ValidateProductsGQL,
    private ls: LocalStorageService,
    private snackbarService: SnackbarService,
    private imageHandlerService: ImageHandlerService,
  ) {
    this.ACCESS_TOKEN = this.userAccountService.userAccessToken;
  }

  validateProductsSubscription(
    params: ValidateProductsInput,
  ): Observable<ApolloQueryResult<ValidateProductsQuery>> {
    return this.validateProductsGQL.fetch(
      { params },
      { fetchPolicy: 'no-cache' },
    );
  }

  updateProductMutation(
    params: UpdateProductInput,
  ): Observable<
    FetchResult<UpdateProductMutation, Record<string, any>, Record<string, any>>
  > {
    return this.updateProductGQL.mutate({ params });
  }

  validateBarcode(
    barcode: string,
  ): Observable<ApolloQueryResult<GetProductByBarcodeQuery>> {
    return this.getProductByBarcodeGQL.fetch({ params: { barcode } });
  }

  createProductMutation(
    params: CreateProductInput,
  ): Observable<
    FetchResult<CreateProductMutation, Record<string, any>, Record<string, any>>
  > {
    return this.createProductGQL.mutate({ params });
  }

  searchProducts({
    name,
    barcode,
    categoriesIds,
    subCategoriesIds,
    maxResults,
    nextTokenText,
    sortType = 'ASC',
  }: SearchProductsInput): Observable<ApolloQueryResult<SearchProductsQuery>> {
    const params: SearchProductsInput = {
      name: name || null,
      barcode: barcode || null,
      categoriesIds: categoriesIds || null,
      subCategoriesIds: subCategoriesIds || null,
      maxResults: maxResults || 20,
      includeProductsWithoutOffer: true,
      nextTokenText: nextTokenText || null,
      sortType: sortType,
    };
    return this.searchProductsGQL.fetch({ params }, { fetchPolicy: 'network-only' });
  }

  searchProductsWOffers(
    params: SearchProductsInput,
  ): Observable<ApolloQueryResult<SearchProductsWOffersQuery>> {
    return this.searchProductsWOffersGQL.fetch({
      params: { ...params, includeProductsWithoutOffer: false },
    }, { fetchPolicy: 'network-only' });
  }

  getStoredCategories(): CategoryWAsset[] {
    const categoriesCache = this.ls.getItem(CATEGORIES_KEY);
    return categoriesCache as CategoryWAsset[];
  }

  getCategories(): CategoryWAsset[] {
    const categories = Categories as CategoryWAsset[];
    return categories;
  }

  getCategoriesSubscription(): Observable<CategoryWAsset[]> | CategoryWAsset[] {
    const categoriesCache = this.ls.getItem(CATEGORIES_KEY);
    if (!!categoriesCache) return categoriesCache as CategoryWAsset[];

    const categoryObservable = this.listCategoriesGQL.fetch().pipe(
      map((value) => {
          return this.setCategoriesImage(value.data.listCategories.categories);
      }),
    );

    const subCategoryObservable = this.listSubCategoriesGQL.fetch();

    return forkJoin([ categoryObservable, subCategoryObservable ]).pipe(
      tap(
        ([
          categories,
          {
            data: {
              listSubCategories: { subCategories },
            },
          },
        ]) => {
          subCategories.forEach((subCategory) => {
            let categoryRef = categories.find(
              (category) => category.id === subCategory.category.id,
            );
            categoryRef.subCategories.push({
              id: subCategory.id,
              name: subCategory.name,
            });
          });

          this.ls.setItem(CATEGORIES_KEY, categories);
        },
      ),
      map((value) => value[0]),
    );
  }

  async getMeasureUnits(): Promise<MeasureUnit[]> {
    try{
      const measureUnitsCache: MeasureUnit[] = this.ls.getItem(MEASURE_UNITS_KEY);
      if (!!measureUnitsCache) return measureUnitsCache;
      const measureUnits = (await this.listMeasureUnitsGQL.fetch().toPromise())
        .data.listMeasureUnits.measureUnits as MeasureUnit[];
      this.ls.setItem(MEASURE_UNITS_KEY, measureUnits);
      return measureUnits;
    }catch(error){
      this.snackbarService.retry(
        'Ocorreu um erro ao carregar as unidades de medida. Tente novamente.', () => this.getMeasureUnits(),
      );
    }

  }

  setCategoriesImage(categories): CategoryWAsset[] {
    return categories.map(
      (category) =>
        ({
          ...category,
          image: `assets/categoryIcons/ic_${category.id}.svg`,
          subCategories: [],
        } as CategoryWAsset),
    );
  }

  async gqlCreateProductBatch (
    productsBrut: ProductByCSV[], authorizationToken: string,
  ): Promise<
    FetchResult<
      CreateProductBatchMutation,
      Record<string, any>,
      Record<string, any>
    >
  >  {
    try {
      const products = productsBrut.map((product) =>
        removeObjectEmptyFields({
          barcode: product.barcode,
          brand: product.brand,
          description: product.description,
          imageUrl: '',
          measurementID: product.measurementId,
          measurementValue: product.measurementValue,
          model: product.model,
          name: product.name,
          subCategoryID: product.subCategoryId,
        }),
      );
      return await this.createProductBatchGQL
        .mutate({ params: { products, authorizationToken, accessToken: this.ACCESS_TOKEN } })
        .toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  getCategoryById(categoryId: Scalars['ID']): Observable<CategoryWAsset> {
    const categories: CategoryWAsset[] = this.ls.getItem(CATEGORIES_KEY);

    for (let category of categories) {
      if (category.id == categoryId) {
        return of(category);
      }
    }

    return null;
  }

  deleteProductById(
    productId: string,
  ): Observable<
    FetchResult<
      DeleteProductByIdMutation,
      Record<string, any>,
      Record<string, any>
    >
  > {
    return this.deleteProductByIdGQL.mutate({
      params: { id: productId, accessToken: this.ACCESS_TOKEN },
    });
  }

  async deleteProductsBatch(
    selectedProducts: Product[],
  ): Promise<
    FetchResult<
      DeleteProductBatchMutation,
      Record<string, any>,
      Record<string, any>
    >
  > {
    return await this.deleteProductBatchGQL
      .mutate({
        params: {
          accessToken: this.ACCESS_TOKEN,
          ids: selectedProducts.map((p) => p.id),
        },
      })
      .toPromise();
  }

  async uploadProductImage(file: File, barcode: string): Promise<string> {
    return await this.imageHandlerService.uploadImageToBucket(file, 'product', barcode);
  }
}
