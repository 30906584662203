<div class="input_container d-flex align-items-center" [ngClass]="{ 'input-active map-ripple': handler.showInputBarcode | async }" (click)="handler.showInput()">
  <img alt="Código de barras" class="input_placement_image"
       src="assets/dark-theme/products/add-products/single/barcode.svg">
  <span
    *ngIf="(handler.showInputBarcode | async) === false else barCodeInput"
    class="input_link_label"
    id="addBarcodeLabel"
    data-cy="button-show-input"
  >
    Adicionar código
  </span>
  <ng-template #barCodeInput>
    <div class="w-100 d-flex align-items-center justify-content-between ">
      <div class="barCodeInput d-flex align-items-center" [ngStyle]="{'width': 'calc(0% + ' + (barCode?.value?.toString().length + 4) + 'ch)'}">
        <input #barCode="ngModel" aria-required="true" class="font-subtitle-regular" id="barCode_input" matInput data-cy="input-barcode"
          [readonly]="handler.inputIsReadOnly() | async" maxlength="43" minlength="6" required type="text"
          [ngModel]="handler.barcode | async" (ngModelChange)="handler.barcode.next($event); setBarcode($event)"
        >
      </div>
      <button matRipple (click)="handler.setWrittenBarcode()"
        id="barCode_button"
        *ngIf="(handler.barCodeNotSetted | async) && (handler.barcodeLoading | async) === false"
        [disabled]="barCode.invalid || (handler.barcodeLoading | async)"
        class="btn"
        data-cy="button -barcode-submit"
      >
        <img alt="Editar código de barras" class="button_icon"
          src="assets/dark-theme/products/add-products/single/check-barcode.svg">
      </button>
    </div>
    <div *ngIf="handler.barcodeLoading | async" class="loading_barcode_validation">
      <div class="spinner-border text-orange" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div *ngIf="handler.barcodeValidated | async" class="barcode_validated">
      <img alt="Barcode aprovado" class="checked_icon"
          src="assets/dark-theme/products/add-products/single/checked-true.svg">
    </div>
  </ng-template>
</div>
