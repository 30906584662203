<mat-form-field
  appearance="outline"
  class="mat_bottom_form"
  [ngClass]="{
    error: currentControl.invalid && currentControl.touched,
    empty: !!!currentControl.value,
    disabled: currentControl.disabled
  }"
>
  <mat-label>{{ label }}</mat-label>
  <div class="d-flex">
    <input
      class="font-subtitle-regular"
      [maxlength]="maxLength"
      [mask]="mask"
      [prefix]="mask && prefix ? prefix : ''"
      [thousandSeparator]="thousandSeparator"
      (focusout)="onFocusOut()"
      (focusin)="onFocusIn()"
      [formControl]="currentControl"
      matInput
      [required]="required"
      [type]="inputTypeAtInput"
      [attr.data-cy]="dataCy"
      [autocomplete]="autocomplete"
      [min]="min"
      [max]="max"
      (keydown)="checkForDot($event)"
    />
    <mat-icon
      (click)="iconClick.emit()"
      *ngIf="!!iconName"
      color="accent"
      class="outlined"
      >{{ iconName }}</mat-icon
    >

    <ng-container *ngIf="tooltipText" [ngSwitch]="loading">
      <div *ngSwitchCase="true" class="spinner-border" role="status"></div>

      <img
        *ngSwitchCase="false"
        (click)="tooltipAction ? tooltipAction() : false"
        class="tooltip-icon"
        src="{{ getTooltipIcon() }}"
        alt="Ícone de ajuda"
      />
      <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
    </ng-container>
  </div>
  <mat-error
    *ngIf="!currentControl.valid && inputError"
    class="input_error_message font-subtitle-medium-14"
    >{{ handleInputError(inputError) }}</mat-error
  >
</mat-form-field>

<ng-template #tooltip>
  <div class="tooltip">
    <div class="arrow"></div>
    <p class="font-subtitle-medium text" data-cy="tooltip-help">
      {{ tooltipText }}
    </p>
  </div>
</ng-template>
