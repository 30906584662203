import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { Popup, PopupData } from '@shared/interfaces/Popup';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  config: MatDialogConfig = {
    width: '22.625rem',
    minHeight: '11.25rem',
    height: 'auto',
    direction: 'ltr',
  };

  constructor(private dialog: MatDialog) {}

  needToConfirm(popupInfo: PopupData, cb: () => void): void {
    this.dialog.open(ConfirmationPopupComponent, {
      ...this.config,
      data: {
        title: popupInfo.title,
        description: popupInfo.description,
        buttonTitle: popupInfo.buttonTitle,
        buttonIcon: popupInfo.buttonIcon,
        onConfirm: () => {
          return cb();
        },
      } as Popup,
    });
  }

  confirmFormChanged(cb: () => void): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      ...this.config,
      data: {
        title: 'Deseja fechar a janela?',
        description:
          'Ao fechar essa janela você irá perder todas as alterações.',
        buttonTitle: 'Sair da página',
        buttonIcon: 'none',
        onConfirm: () => {
          return cb();
        },
      } as Popup,
    });
    return dialogRef.afterClosed().toPromise();
  }

  information(popupInfo: Partial<PopupData>): void {
    this.dialog.open(ConfirmationPopupComponent, {
      ...this.config,
      data: {
        title: popupInfo.title,
        description: popupInfo.description,
        cancelText: popupInfo.cancelText,
      } as Popup,
    });
  }
}
