import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Popup } from '@shared/interfaces/Popup';

import { PopupButtonIcon } from '../../interfaces/Popup';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: [ './confirmation-popup.component.scss' ],
})
export class ConfirmationPopupComponent {
  title: string;
  description: SafeHtml;
  buttonTitle: string;
  buttonIcon: string;
  cancelText = 'Cancelar';
  onConfirm: () => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    private sanitizer:DomSanitizer,
  ) {
    this.passDialogDataToComponent(data);

    switch (this.buttonIcon as PopupButtonIcon) {
      case 'cancel': {
        this.buttonIcon = 'assets/dark-theme/icon-close.svg';
        return;
      }
      case 'delete': {
        this.buttonIcon = 'assets/dark-theme/icon_deletar.svg';
        return;
      }
      case 'create': {
        this.buttonIcon = 'assets/dark-theme/icon-file-check.svg';
        return;
      }
      case 'check': {
        this.buttonIcon = 'assets/dark-theme/icon-check.svg';
        return;
      }
      case 'none': {
        this.buttonIcon = null;
        return;
      }
      default: {
        return;
      }
    }
  }

  onConfirmButton(): void {
    this.onConfirm();
    this.dialogRef.close(true);
  }

  private passDialogDataToComponent(data$: Popup): void {
    this.title = data$.title;
    this.description = this.sanitizer.bypassSecurityTrustHtml(data$.description);
    this.buttonTitle = data$.buttonTitle;
    this.buttonIcon = data$.buttonIcon;
    this.cancelText = data$.cancelText || 'Cancelar';
    this.onConfirm = data$.onConfirm;
  }
}
