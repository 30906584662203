<button matRipple
  class="btn primary-btn submitButton d-flex align-items-center justify-content-center"
  type="submit" data-cy="button-submit-form"
  [disabled]="disabled"
>
  <div *ngIf="!loading else loadingSpinner" >
    <img *ngIf="iconLeft" [alt]="imageAlt" class="submit-button-icon" [src]="imageSrc">
    {{title}}
    <img *ngIf="iconRight" [alt]="imageAlt" class="submit-button-icon" [src]="imageSrc">
  </div>
</button>

<ng-template #loadingSpinner>
  <div data-cy="submit-loading-spinner" class="row spinner-border loadingSpinner" role="status"></div>
</ng-template>
