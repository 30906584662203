<ng-template #appbarSupport>
    <div class="filters-section">
        <div class="search-bar">
            <app-search-bar-lg></app-search-bar-lg>
        </div>

        <div class="d-flex justify-content-between sort-by">
            <app-sort-by class='sort-support-status' [options]="sortTypes" [(selected)]='sortType'></app-sort-by>
            <div class="d-flex">
                <p>Classificar por</p>
                <app-sort-by></app-sort-by>
            </div>
        </div>
    </div>
</ng-template>