<app-brand-area>
  <div
    class="col order-first order-xl-last text-center d-flex justify-content-center"
    id="login_area"
  >
    <div class="card">
      <img
        src="assets/dark-theme/account/backoffice_logo_login_dark.svg"
        alt="my promo logo"
      />

      <div class="title text-start">
        <h3 class="font-subtitle-regular">Login</h3>
        <p class="font-subtitle-regular">
          Ainda não possui uma conta?
          <span (click)="navigateToCreateUser()">Criar</span>
        </p>
      </div>

      <form
        (ngSubmit)="submitLogin()"
        [formGroup]="loginForm"
        name="loginForm$"
        data-cy="form-add"
      >
        <app-form-text-input
          controlName="email"
          label="E-mail"
          [inputError]="handleInputErrors('email')"
          dataCy="input-email"
        >
        </app-form-text-input>

        <app-form-text-input
          controlName="password"
          [inputType]="hide ? 'password' : 'text'"
          label="Senha"
          [iconName]="hide ? 'visibility_off' : 'visibility'"
          [inputError]="handleInputErrors('password')"
          (iconClick)="hide = !hide"
          dataCy="input-password"
        ></app-form-text-input>

        <app-form-submit-button title="Iniciar sessão" [loading]="loading"
          [disabled]="loginForm.invalid || loading"></app-form-submit-button>
      </form>

      <button matRipple class="dark-btn font-button-medium forgot_password" routerLink="/conta/esqueci-senha" type="button">
        Esqueci a senha
      </button>
    </div>
    <div class="snackbar">
      <ng-template #snackbar></ng-template>
    </div>
  </div>
</app-brand-area>
