import { APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../environments/environment';

const env =
  environment.ENVIRONMENT == 'local' ? 'dev' : environment.ENVIRONMENT;
const uriTemplate = `https://services.$service.${env}.mypromo.app/graphql`;

const uriUsers = uriTemplate.replace('$service', 'users');
const apiKeyUsers = environment.API_KEY_USERS;

const uriStores = uriTemplate.replace('$service', 'stores');
const apiKeyStores = environment.API_KEY_STORES;

const uriProducts = uriTemplate.replace('$service', 'products');
const apiKeyProducts = environment.API_KEY_PRODUCTS;

// TODO: VER COM ALGUEM MAIS EXPERIENTE SE DEIXA DEFAULT OU NAO
// export function createApolloUsers(httpLink: HttpLink) {
//   const auth = setContext((operation, context) => ({
//     headers: {
//       'x-api-key': apiKeyUsers,
//     },
//   }));

//   const link = ApolloLink.from([auth, httpLink.create({ uri: uriUsers })]);
//   const cache:InMemoryCache  = new InMemoryCache();

//   return {
//     link,
//     cache,
//   };
// }

export function createApolloStores(
  httpLink: HttpLink,
): Record<string, ApolloClientOptions<any>> {
  const auth = (option: string): ApolloLink => {
    const apiKey =
      option === 'users'
        ? apiKeyUsers
        : option === 'stores'
        ? apiKeyStores
        : option === 'products'
        ? apiKeyProducts
        : '';

    return setContext((_, { headers }) => ({
      headers: { ...headers, 'x-api-key': apiKey },
    }));
  };

  // TODO: remove needAuth / notUseApiKey

  const cache: InMemoryCache = new InMemoryCache();

  return {
    users: {
      name: 'users',
      link: ApolloLink.from([
        auth('users'),
        httpLink.create({ uri: uriUsers }),
      ]),
      cache,
    },
    stores: {
      name: 'stores',
      link: ApolloLink.from([
        auth('stores'),
        httpLink.create({ uri: uriStores }),
      ]),
      cache,
    },
    products: {
      name: 'products',
      link: ApolloLink.from([
        auth('products'),
        httpLink.create({ uri: uriProducts }),
      ]),
      cache,
    },
  };
}

@NgModule({
  providers: [
    // {
    //   provide: APOLLO_OPTIONS,
    //   useFactory: createApolloUsers,
    //   deps: [HttpLink],
    // },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApolloStores,
      deps: [ HttpLink ],
    },
  ],
})
export class GraphQLModule {}
