import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppbarChanges } from '@app/core/services/appbarChanges.service';

@Component({
  selector: 'app-appbar-support',
  templateUrl: './appbar-support.component.html',
  styleUrls: [ './appbar-support.component.scss' ],
})
export class AppbarSupportComponent implements OnInit {

  @ViewChild('appbarSupport', { static: true })
  appbarTemplate: TemplateRef<any>;

  @Input() sortType: string;

  sortTypes = [
    {
      name: 'Todas as dúvidas',
      value: 'ALL',
    },
    {
      name: 'Em aberto',
      value: 'OPEN',
    },
    {
      name: 'Respondidas',
      value: 'CLOSED',
    },
   ];

  constructor(private appbarChanges: AppbarChanges) { }

  ngOnInit(): void {
    this.appbarChanges.setAppbar('Ajuda', [], this.appbarTemplate);
  }


}
