<pagination-template  #p="paginationApi"
  [id]="id"
  [maxSize]="maxSize"
  (pageChange)="pageChange.emit($event)"
  (pageBoundsCorrection)="pageBoundsCorrection.emit($event)">

  <ul class="pagination justify-content-center"
    [attr.aria-label]="screenReaderPaginationLabel"
    [class.responsive]="responsive"
    *ngIf="!(autoHide && p.pages.length <= 1)">

    <li class="page-item" [class.disabled]="p.isFirstPage()" *ngIf="directionLinks">
      <a class="page-link" tabindex="0" (keyup.enter)="p.previous()" (click)="p.previous()" [attr.aria-label]="previousLabel + ' ' + screenReaderPageLabel">
        <mat-icon aria-hidden="false" aria-label="Arrow left">chevron_left</mat-icon>
      </a>
    </li>
    <li class="page-item" [class.current]="p.getCurrent() === page.value"
        [class.ellipsis]="page.label === '...'"
        *ngFor="let page of p.pages; trackBy: trackByIndex">
      <a class="page-link" tabindex="0" (keyup.enter)="p.setCurrent(page.value)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
        <span>{{ (page.label === '...') ? page.label : (page.label | number:'') }}</span>
      </a>
      <ng-container *ngIf="p.getCurrent() === page.value">
        <span class="page-link active">{{ (page.label === '...') ? page.label : (page.label | number:'') }}</span>
      </ng-container>
    </li>
    <li class="page-item" [class.disabled]="p.isLastPage()" *ngIf="directionLinks">
      <a class="page-link" tabindex="0" (keyup.enter)="p.next()" (click)="p.next()" [attr.aria-label]="nextLabel + ' ' + screenReaderPageLabel">
        <mat-icon aria-hidden="false" aria-label="Arrow right">chevron_right</mat-icon>
      </a>
    </li>
  </ul>
</pagination-template>
