import { handleErrors } from '@app/shared/utils/handleErrors';
import { tap } from 'rxjs/operators';
import { handleFormError } from '@shared/utils/handleErrors';
import { FormValidator } from '@core/validators/formValidator';
import { Exact } from '@shared/graphql/products/products-graphql';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { UserAccountService } from '@app/core/services/user-account.service';
import { RecoverPasswordInput } from '@app/shared/graphql/users/users-graphql';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { Regex } from '@shared/utils/regex';
@UntilDestroy()
@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: [ './new-password.component.scss' ],
})
export class NewPasswordComponent implements OnInit {
  @ViewChild('snackbar', { read: ViewContainerRef, static: true })
  snackbar: ViewContainerRef;

  passwordForm: FormGroup;
  handleFormError: any;

  loading = false;
  code: string;
  email: string;
  permissionLevel: string;

  hide = true;

  successSubmit = false;
  responseError = false;

  title = 'Definir nova senha';
  textMessage = 'Por favor, digite sua nova senha.';

  openTooltip: boolean;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    private userAccountService: UserAccountService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    const routePath = this.activatedRoute.snapshot.routeConfig.path;
    this.handleFormError = handleFormError;

    this.activatedRoute.queryParams
      .pipe(
        untilDestroyed(this),
        tap((params) => {
          this.code = params['code'];
          this.email = params['email'];
          this.permissionLevel = params['permissionLevel'];
        }),
      )
      .subscribe();
    this.startFormGroup();
    this.snackbarService.setRootViewContainerRef(this.snackbar);

    // Escondendo o queryParams por questão de segurança
    routePath === 'resetPassword'
      ? this.location.replaceState('/resetPassword')
      : this.location.replaceState('/conta/redefinir-senha');
  }

  async submitNewPassword(): Promise<void> {
    this.loading = true;
    const params: Exact<{ params: RecoverPasswordInput }> = {
      params: {
        emailObject: { email: this.email, permissionLevel: this.permissionLevel },
        password: this.passwordForm.get('password').value,
        code: this.code,
      },
    };

    try {
      await this.userAccountService.resetPassword(params).toPromise();
      this.successSubmit = true;
      this.title = 'Senha redefinida';
      this.textMessage = 'Sua senha foi redefinida com sucesso';
    } catch (err) {
      const error = handleErrors(err);
      if (error.canRetry)
        this.snackbarService.errorSnackbar('Ocorreu um erro. Tente novamente.');
      else this.snackbarService.errorSnackbar(error.message);
    }

    this.loading = false;
  }

  private startFormGroup(): void {
    this.passwordForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            // Verifica se a senha tem número
            FormValidator.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // Verifica se a senha tem letra maíuscula
            FormValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // Verifica se a senha tem letra minúscula
            FormValidator.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // Verifica se a senha tem caractere especial
            FormValidator.patternValidator(
              /[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\':;|_~`=+\\ ]/,
              {
                hasSpecialCharacters: true,
              },
            ),
            // Verifica se a senha tem espaço no começo
            FormValidator.patternValidator(
              /^[\S]+.*[\S]+$/,
              {
                hasSpace: true,
              },
            ),
            Validators.minLength(8),
            Validators.maxLength(70),
          ]),
        ],
        confirmPassword: [ '', Validators.required ],
      },
      {
        validators: [ FormValidator.equalsTo('password', 'confirmPassword') ],
      },
    );
  }
}
