import { AbstractControl } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-tooltip',
  templateUrl: './password-tooltip.component.html',
  styleUrls: [ './password-tooltip.component.scss' ],
})
export class PasswordTooltipComponent {
  @Input() controlPassword: AbstractControl;

  constructor() { }
}
