import { MockInterceptor } from './interceptors/mock.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeactivateGuardService } from '@app/core/guards/deactivate.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationEmailGuard } from './guards/confirmation-email.guard';

@NgModule({
  declarations: [],
  imports: [ CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule ] ,
  providers: [
    DeactivateGuardService,
    ConfirmationEmailGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
