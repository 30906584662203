import { Regex } from '@shared/utils/regex';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { UserAccountService } from '@services/user-account.service';
import { SnackbarService } from '@core/services/snackbar.service';

import { handleFormError, handleErrors } from '@shared/utils/handleErrors';
import { FormValidator } from '@app/core/validators/formValidator';

@Component({
  selector: 'app-user',
  templateUrl: './create-user.component.html',
  styleUrls: [ './create-user.component.scss' ],
})
export class CreateUserComponent implements OnInit {
  @ViewChild('snackbar', { read: ViewContainerRef, static: true })
  snackbar: ViewContainerRef;

  loading = false;
  createUserForm: FormGroup;
  hide = true;

  accessToken: string;
  refreshToken: string;

  errorCode: number;

  handleFormError: any;
  handleSubmitError: any;

  openTooltip: boolean;

  constructor(
    private accountLoginService: UserAccountService,
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  async submitForm(): Promise<void> {
    this.loading = true;

    const params = {
      email: this.createUserForm.value[ 'email' ],
      name: this.createUserForm.value[ 'name' ],
      password: this.createUserForm.value[ 'password' ],
      permissionLevel: 'BACKOFFICE_USER',
    };

    try {
      await this.accountLoginService.signUp(params).toPromise();
      await this.router.navigate([ '/conta/envio' ], {
        queryParams: { email: params.email },
      });
    } catch (err) {
      const error = handleErrors(err);
      if (error.canRetry)
        this.snackbarService.errorSnackbar(
          'Não foi possível realizar o cadastro. Tente novamente.',
        );
      else this.snackbarService.errorSnackbar(error.message);
    }

    this.loading = false;
  }

  ngOnInit(): void {
    this.handleFormError = handleFormError;
    this.startFormGroup();
    this.snackbarService.setRootViewContainerRef(this.snackbar);
  }

  navigateToLogin(): void {
    this.router.navigateByUrl('/conta/login');
  }

  private startFormGroup(): void {
    this.createUserForm = this.fb.group(
      {
        name: [
          '',
          Validators.compose([
            Validators.required,
            FormValidator.patternValidator(Regex.nameRegex, {
                name: true,
            }),
          ]),
      ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            FormValidator.patternValidator(Regex.emailRegex, {
              emailWithoutSpecialCharacters: true,
            }),
           ]),
         ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            // Verifica se a senha tem número
            FormValidator.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // Verifica se a senha tem letra maíuscula
            FormValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // Verifica se a senha tem letra minúscula
            FormValidator.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // Verifica se a senha tem caractere especial
            FormValidator.patternValidator(
              /[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\':;|_~`=+\\ ]/,
              {
                hasSpecialCharacters: true,
              },
            ),
            // Verifica se a senha tem espaço no começo
            FormValidator.patternValidator(
              /^[\S]+.*[\S]+$/,
              {
                hasSpace: true,
              },
            ),
            Validators.minLength(8),
            Validators.maxLength(70),
           ]),
         ],
        confirmPassword: [ '', Validators.required ],
        checkbox: [ false, Validators.requiredTrue ],
      },
      {
        validators: [ FormValidator.equalsTo('password', 'confirmPassword') ],
      },
    );
  }
}
