<div matRipple class="category d-inline-flex m-0 g-0" [ngClass]="{'dark-theme': storedTheme==='dark-theme'}"
  *ngIf="item" data-cy="category-item">
  <div class="category-image-div" [ngStyle]="{'background-color': '#' + item.color.split('0xFF')[1]}">
    <img class="category-image" [src]="item.image" [alt]="item.name" />
  </div>
  <div class="category-text-div">
    <span class="font-subtitle-medium-14" [ngClass]="{ selected: selected }" data-cy="category-name">{{ item.name
      }}</span>
  </div>
</div>
