import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { StoreTypes } from '@shared/interfaces/backoffice/storeTypes';
import { CreateAppbarButton } from '@shared/utils/createAppbarButton';
import { AppbarChanges } from '@services/appbarChanges.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppbarButton } from '@shared/interfaces/backoffice/appbar';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, Output, EventEmitter, TemplateRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import {
  GetCitiesByStateIdCity as City,
  GetStatesState as State,
} from '@app/shared/graphql/stores/stores-graphql';
import { PopupService } from '@app/core/services/popup.service';

@UntilDestroy()
@Component({
  selector: 'app-appbar-stores',
  templateUrl: './appbar-stores.component.html',
  styleUrls: [ './appbar-stores.component.scss' ],
})
export class AppbarStoresComponent implements OnInit, OnChanges {
  @ViewChild('appbar', { static: true }) appbarTemplate: TemplateRef<any>;

  @Input() states: Omit<State, 'region'>[] = [];
  @Input() selectedCities: string[] = [];
  @Input() selectedState: FormControl;
  @Input() availableCities: City[] = [];
  @Input() selectedCity: FormControl;
  @Input() storeTypes: StoreTypes[];
  @Input() selectedTypes = new FormControl([]);

  @Input() showSelectOptions = true;
  @Input() loadingCities = false;
  @Input() storesLength = 0;
  @Input() selectedStoresLength = 0;
  @Input() componentTitle: 'estabelecimentos' | 'ofertas' = 'estabelecimentos';
  @Input() sortType: string;
  @Input() selectAll = false;
  @Input() inputSearch = '';

  @Input() isSelectOn = false;

  @Output() triggerOnDeleteSelected = new EventEmitter<boolean>();
  @Output() triggerSearch = new EventEmitter<string>();
  @Output() triggerResetSearch = new EventEmitter<void>();
  @Output() triggerOnSort = new EventEmitter<string>();
  @Output() triggerIsSelectOn = new EventEmitter<boolean>();
  @Output() triggerSelectAll = new EventEmitter<boolean>();
  @Output() triggerUnselectAll = new EventEmitter<boolean>();
  @Output() triggerRetryLoadStates = new EventEmitter<void>();


  addButton: AppbarButton;
  deleteButton: AppbarButton;

  sortOptions = [
    {
      name: 'Ordem A-Z',
      value: 'ASC',
    },
    {
      name: 'Ordem Z-A',
      value: 'DESC',
    },
    {
      name: 'Distância',
      value: 'DISTANCE',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private appbarChanges: AppbarChanges,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
    this.loadInitialButtons();

    if(this.route.snapshot.queryParams.search) {
      const initialInput = this.route.snapshot.queryParams.search;
      this.inputSearch = initialInput;
      this.triggerSearch.emit(initialInput);
    }

    this.route.queryParams
    .pipe(untilDestroyed(this))
    .subscribe((queryParams) => {
      if(!queryParams.search) {
        this.inputSearch = '';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedStoresLength']) {
      this.changeAppbar();
    }
  }

  changeAppbar(): void {
    if (this.isSelectOn) {
      this.appbarChanges.setAppbar(
        `${this.selectedStoresLength} selecionado${
          this.selectedStoresLength > 1 ? 's' : ''
        }`,
        [ this.deleteButton ],
        this.appbarTemplate,
      );
    } else {
      this.appbarChanges.setAppbar(
        `${
          this.componentTitle[0].toUpperCase() +
          this.componentTitle.slice(1).toLowerCase()
        }`,
        this.componentTitle === 'estabelecimentos' ? [ this.addButton ] : [],
        this.appbarTemplate,
      );
    }

  }

  retryLoadStates(): void{
    this.triggerRetryLoadStates.emit();
  }

  onSearch(newInput: any): void {
    this.isSelectOn = false;
    this.triggerIsSelectOn.emit(this.isSelectOn);
    this.changeAppbar();

    this.inputSearch = newInput;
    this.triggerSearch.emit(newInput);
  }

  onResetSearch(): void {
    this.isSelectOn = false;
    this.triggerIsSelectOn.emit(this.isSelectOn);
    this.triggerResetSearch.emit();
  }

  changeSelectOn(): void {
    this.isSelectOn = !this.isSelectOn;
    this.triggerIsSelectOn.emit(this.isSelectOn);
    this.changeAppbar();
  }

  onSort(): void {
    this.triggerOnSort.emit(this.sortType);
  }

  onSelectAll(): void {
    this.selectAll = true;
    this.triggerSelectAll.emit(true);
  }

  onUnselectAll(): void {
    this.selectAll = false;
    this.triggerUnselectAll.emit(true);
  }

  navigateToAddStores = (): void => {
    this.router.navigateByUrl('backoffice/estabelecimentos/adicionar');
  };

  onDeleteConfirmationSelected(): void {
    this.popupService.needToConfirm({
      title: 'Excluir estabelecimentos?',
      description: 'Você poderá adicionar outros estabelecimentos a qualquer momento.',
      buttonTitle: 'Excluir',
      buttonIcon: 'delete',
    }, this.onDeleteSelected.bind(this));
  }

  onDeleteSelected(): void {
    this.triggerOnDeleteSelected.emit(true);
    this.onCancelSelect();
  }

  onCancelSelect(): void {
    this.triggerUnselectAll.emit(true);
    this.changeSelectOn();
  }

  loadInitialButtons(): void {
    let appbarText = this.componentTitle[0].toUpperCase() + this.componentTitle.slice(1).toLowerCase();
    let appbarButton = 'Novo Estabelecimento';

    if(this.componentTitle === 'estabelecimentos')
      this.addButton = CreateAppbarButton.adicionarButton(
        appbarButton,
        this.navigateToAddStore,
      );

    this.deleteButton = CreateAppbarButton.deletarButton(
      'Apagar itens',
      this.onDeleteConfirmationSelected.bind(this),
      '#f69116',
    );

    this.changeAppbar();
  }

  navigateToAddStore = (): void => {
    if (this.route.snapshot.data.saveQuery) {
      this.breadcrumbService.setData(
        this.route.snapshot.data.saveQuery,
        this.route.snapshot.queryParams.search,
      );
    }
    this.router.navigate([ 'adicionar' ], { relativeTo: this.route });
  };
}
