import { FormControl, FormGroupDirective } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-calendar-input',
  templateUrl: './form-calendar-input.component.html',
  styleUrls: [ './form-calendar-input.component.scss' ],
})
export class FormCalendarInputComponent implements OnInit {
  @Input() label: string;
  @Input() inputError: string | object;
  @Input() title: string;
  @Input() placeholder = 'Data';
  @Input() controlName: string;
  @Input() min: Date = new Date();
  @Output() focusInput = new EventEmitter<boolean>();
  currentControl: FormControl;

  constructor(private rootFromGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.currentControl = this.rootFromGroup.control.controls[
      this.controlName
    ] as FormControl;
  }

  onFocusOut(): void {
    this.focusInput.emit(false);
  }

  onFocusIn(): void {
    this.focusInput.emit(true);
  }

  /**
   * Trata o inputError se é uma string ou um objeto com as mensagens de erros. Somente um erro é exibido por vez
   * @param errorMsg pode ser a string do erro ou então o objeto com a chave o nome do erro e o valor a mensagem do erro
   * @returns string a ser exibida no erro
   */
   handleInputError(errorMsg: string | object): string {
    if (typeof errorMsg === 'string') return errorMsg;

    const errors = this.currentControl.errors;
    return errorMsg[Object.keys(errors || {})[0]];
  }
}
