import { tap } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBarcodeService } from '@services/form-barcode.service';


@UntilDestroy()
@Component({
  selector: 'app-form-barcode-input',
  templateUrl: './form-barcode-input.component.html',
  styleUrls: [ './form-barcode-input.component.scss' ],
})
export class FormBarcodeInputComponent implements OnInit, OnChanges {
  @Output() inputResponse: EventEmitter<string> = new EventEmitter();
  @Input() resetForm: boolean;
  barcode: string;

  constructor(public handler: FormBarcodeService) { }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.resetForm) this.resetBarcodeInput();
  }

  ngOnInit(): void {
    this.handler.barcodeValidated.pipe(
      untilDestroyed(this),
      tap((valid) => {
        if (!valid) return;
        this.inputResponse.emit(this.barcode);
      }),
    ).subscribe();
  }

  setBarcode(v: string): void {
    this.barcode = v;
  }

  private resetBarcodeInput(): void {
    this.barcode = '';
    this.handler.resetValues();
  }
}
