import { LoginAuthGuard } from '@core/guards/login-auth.guard';
import { DeactivateGuardService } from '@core/guards/deactivate.guard';
import { SendEmailComponent } from './send-email/send-email.component';
import { ConfirmationEmailGuard } from '@core/guards/confirmation-email.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './login/login-page.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ConfirmationEmailComponent } from './confirmation-email/confirmation-email.component';

import { CheckEmailComponent } from './reset-password/subpages/check-email/check-email.component';
import { ForgotPasswordComponent } from './reset-password/subpages/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './reset-password/subpages/new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [LoginAuthGuard],
      },
      {
        path: 'cadastro',
        canActivate: [LoginAuthGuard],
        component: CreateUserComponent,
      },
      {
        path: 'envio',
        component: SendEmailComponent,
        canActivate: [ConfirmationEmailGuard],
        canLoad: [ConfirmationEmailGuard],
      },
      {
        path: 'confirmar-email',
        component: ConfirmationEmailComponent,
        canActivate: [ConfirmationEmailGuard],
        canLoad: [ConfirmationEmailGuard],
      },
      {
        path: '',
        component: ResetPasswordComponent,
        children: [
          {
            path: 'esqueci-senha',
            component: ForgotPasswordComponent,
            canActivate: [LoginAuthGuard],
          },
          {
            path: 'verificar-email',
            component: CheckEmailComponent,
            canActivate: [ConfirmationEmailGuard],
            canLoad: [ConfirmationEmailGuard],
          },
          {
            path: 'redefinir-senha',
            component: NewPasswordComponent,
            canActivate: [ConfirmationEmailGuard],
            canLoad: [ConfirmationEmailGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
