import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-snackbar-alert',
  templateUrl: './snackbar-alert.component.html',
  styleUrls: ['./snackbar-alert.component.scss'],
})
export class SnackbarAlertComponent implements OnInit {
  @Input() message: string;
  @Input() type: 'success' | 'error' | 'retry' = 'success';
  @Input() cb: () => void;
  closeAlert = false;
  closeTime = 4000;

  constructor() {}

  ngOnInit(): void {
    if (this.closeTime !== 0) {
      setTimeout(() => {
        this.closeAlert = true;
      }, this.closeTime);
    }
  }

  handleClose(): void {
    this.closeAlert = true;
  }

  onRetry(): void {
    return this.cb();
  }
}
