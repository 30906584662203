<div class="dropdown">
  <button matRipple class="btn btn-secondary dark-btn d-flex justify-content-between" type="button" id="dropdownMenuButton1"
    data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"
    [ngClass]="{'selected': selected === true}" data-cy="button-dropdown-subcategories">
    <p>Subcategorias</p>
    <mat-icon aria-hidden="false" aria-label="Expandir menu">expand_more</mat-icon>
  </button>

  <div class="dropdown-menu dropdown-menu-start" aria-labelledby="dropdownMenuButton1" *ngIf="options">
    <mat-form-field [floatLabel]="'never'" class="filterSelectBar">
      <span matPrefix><img alt="Ícone Buscar" src="assets/appbar/dark-theme/icon_busca.svg" class="icon-search"></span>
      <mat-label>Buscar</mat-label>
      <input #filterSubcategories autocomplete="off" autofocus data-cy="input-search-subcategories" matInput type="text"
        class="input-search">
    </mat-form-field>

    <span matRipple class="float-end dark-btn" tabindex="0" *ngIf="!allSelected" (click)="onSelectAll(filterSubcategories.value)" data-cy="button-select-all">Selecionar tudo</span>
    <span matRipple class="float-end dark-btn" tabindex="0" *ngIf="allSelected" (click)="onUnselectAll()" data-cy="button-unselect-all">Desselecionar tudo</span>

    <cdk-virtual-scroll-viewport itemSize="10" class="scroll-viewport">
      <ul>
        <li *cdkVirtualFor="let option of options">
          <app-select-item [item]="option" [(selected)]="option.selected" *ngIf="((
            (option.name.toLowerCase().startsWith(filterSubcategories.value.toLowerCase())
            )) && filterSubcategories.value)
            || !filterSubcategories.value"></app-select-item>
        </li>
      </ul>
    </cdk-virtual-scroll-viewport>

    <span matRipple class="float-end dark-btn" tabindex="0" (click)="onFilter()">Filtrar</span>
  </div>
</div>
