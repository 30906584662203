import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';

import { LocalStorageService } from '@services/local-storage.service';
import { UserAccountService } from '@services/user-account.service';
import { BlobUtil } from '@shared/utils/blob-util';

import {
  GetUploadUrlGQL as GetUploadUrlGQLToProdutcs,
  S3ImageRequest,
} from '@shared/graphql/products/products-graphql';
import { GetUploadUrlGQL as GetUploadUrlGQLToStores } from '@app/shared/graphql/stores/stores-graphql';

interface Image extends S3ImageRequest {
  cache?: string;
}

enum ImageFormat {
  JPEG = 'jpeg',
  JPG = 'jpeg',
  JPE = 'jpeg',
  JFIF = 'jpeg',
  PNG = 'png',
  TIF = 'tiff',
  TIFF = 'tiff',
  RAW = 'raw',
  SVG = 'svg%2Bxml',
}

@Injectable({
  providedIn: 'root',
})
export class ImageHandlerService {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private getUploadUrlGQLToProdutcs: GetUploadUrlGQLToProdutcs,
    private getUploadUrlGQLToStores: GetUploadUrlGQLToStores,
    private userAccountService: UserAccountService,

    private ls: LocalStorageService,
  ) {}

  public fetchUrl(image): Observable<SafeUrl> {
    const imageSubject = new Subject<any>();

    if (image.cache) {
      const baseImageType = image.cache.split(',')[0].split(/:|;/)[1];
      const baseImage = image.cache.split(',')[1];

      const blob = BlobUtil.b64toBlob(baseImage, baseImageType);

      const imageUrl = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(blob),
      );
      imageSubject.next(imageUrl);

      return of(imageUrl);
    } else if (image.headers) {
      this.fetchBase(image).subscribe(
        (next) => {
          const imageUrl = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(next),
          );
          imageSubject.next(imageUrl);
        },
        (err) => {
          imageSubject.error(err);
        },
      );
    }

    return imageSubject.asObservable();
  }

  public fetchBase(image): Observable<any> {
    const imageSubject = new Subject<any>();

    if (image.headers) {
      const url = new URL('https://' + image.url);

      const data = this.http
        .get('https://' + image.headers.Host + url.pathname, {
          headers: {
            'x-amz-content-sha256': image.headers.X_Amz_Content_Sha256,
            'x-amz-date': image.headers.X_Amz_Date,
            Authorization: image.headers.Authorization,
          },
          responseType: 'blob',
        })
        .pipe(
          map((baseImage) => {
            return baseImage;
          }),
        );

      data.subscribe(
        (next) => {
          imageSubject.next(next);
        },
        (err) => {
          imageSubject.error(err);
        },
      );

      return imageSubject.asObservable();
    }
  }

  public checkImage(image: File): void {
    const maxSize = 5 * 1024 * 1024;

    this.checkImageExtension(image.name);

    if (image.size > maxSize)
      throw new Error('O tamanho da imagem não pode exceder 5Mb.');
  }

  public checkImageExtension(imageName: string): void {
    const imageExtension = this.getImageExtension(imageName);

    if (!imageExtension) throw new Error('Imagens sem extensões são inválidas');

    const extensionMap = this.mapImageExtension(imageExtension);

    if (!extensionMap)
      throw new Error(
        `Formato do arquivo incorreto. Tente novamente com os formatos permitidos: \
      ${Object.values(ImageFormat)
        .toString()
        .replace(/(.+?(,|$))/g, '.$1 ')}`,
      );
  }

  public getImageExtension(imageName: string): string {
    const regexExt = /(?:\.([^.]+))?$/;

    return regexExt.exec(imageName)[1];
  }

  public mapImageExtension(extension: string): string | undefined {
    return ImageFormat[extension.toUpperCase()];
  }

  public async uploadImageToBucket(
    file: File,
    bucketName: 'product' | 'store',
    fileName: string,
  ): Promise<string> {
    const contexts = {
      product: {
        bucket: 'my-promo-photo-products',
        getUploadUrlGQL: this.getUploadUrlGQLToProdutcs,
      },
      store: {
        bucket: 'my-promo-photo-stores',
        getUploadUrlGQL: this.getUploadUrlGQLToStores,
      },
    };
    const fileExtension = this.getImageExtension(file?.name);
    const filename = fileName + '.' + fileExtension;

    const { bucket, getUploadUrlGQL } = contexts[bucketName];

    this.checkImageExtension(file?.name);

    try {
      const {
        data: {
          getUploadUrl: { url },
        },
      } = await getUploadUrlGQL
        .fetch({
          params: {
            accessToken: this.userAccountService.userAccessToken,
            filename: filename,
          },
        })
        .toPromise();
      await this.http.put(url, file).toPromise();
    } catch (error) {
      const err = new Error('Não foi possível fazer o upload da imagem');
      err.name = 'ErrImageNotUploaded';
      throw err;
    }

    return `https://${bucket}.s3.amazonaws.com/temp-${fileName}.jpg`;
  }
}
