import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageHandlerService } from '@core/services/image-handler.service';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { StoreThemeService } from '@services/storeTheme.service';
import { StoreWSelected } from '@shared/interfaces/backoffice/stores/store';
import { isNumber } from '@shared/utils/isNumber-util';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: [ './card-item.component.scss' ],
})
export class CardItemComponent implements OnInit, OnChanges {
  @Input() item: any;
  @Input() selectable: boolean;
  @Input() page: 'offers' | 'stores' | 'products' | 'requests';
  @Input() searchText = '';

  @Output() eventOpenModal = new EventEmitter();

  image: string = null;
  storedTheme: string;

  constructor(
    private breacrumbService: BreadcrumbService,
    private imageHandler: ImageHandlerService,
    private themeService: StoreThemeService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.themeService.themeValue.subscribe((nextValue) => {
      this.storedTheme = nextValue;
    });
  }

  ngOnInit(): void {
    this.loadImage();
  }

  ngOnChanges(): void {
    this.loadImage();
  }

  loadImage(): void {
    if (this.item?.image?.url) {
      if(!!this.item.image.headers) {
        this.imageHandler.fetchUrl(this.item?.image).subscribe((img) => {
          this.image = img as string;
        });
      } else {
        this.image = this.item.image.url;
      }
    }
  }

  openModal(value: string): void {
    this.eventOpenModal.emit({
      data: this.item,
      image: this.image,
      typeModal: value,
    });
  }


  openOffers(store: StoreWSelected): void {
    this.breacrumbService.setData(
      this.route.snapshot.data.saveQuery,
      this.route.snapshot.queryParams.search,
    );
    this.router.navigate([ store.id ], { relativeTo: this.route });
  }

  isBarcode(): boolean {
    return isNumber(this.searchText) && this.searchText.length > 6;
  }
}
