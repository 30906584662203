<div class="interval-input">
  <span>{{ label }}: </span>
  <mat-form-field
    appearance="outline"
    class="mat_bottom_form"
    [ngClass]="{
      'red-border': handleErrors()
    }"
    (click)="picker.open()"
  >
    <mat-date-range-input
      [formGroup]="rangeGroup"
      [rangePicker]="picker"
      [min]="today"
    >
      <input
        class="font-subtitle-regular"
        matStartDate
        formControlName="controlStart"
        placeholder="Início"
        readonly
      />
      <input
        class="font-subtitle-regular"
        matEndDate
        formControlName="controlEnd"
        placeholder="Fim"
        readonly
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon class="icon" matDatepickerToggleIcon
        >keyboard_arrow_down</mat-icon
      >
    </mat-datepicker-toggle>
    <mat-date-range-picker
      [dateClass]="dateClass"
      #picker
    ></mat-date-range-picker>
  </mat-form-field>
</div>
<p
  *ngIf="handleErrors() && !picker.opened"
  class="input_error_message font-subtitle-medium-14"
>
  {{ errorMessage }}
</p>
