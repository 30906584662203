import { Component, Input } from '@angular/core';
import { StoreThemeService } from '@services/storeTheme.service';
import { CategoryWAsset } from '@shared/interfaces/backoffice/products/category';


@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: [ './category-item.component.scss' ],
})
export class CategoryItemComponent {
  @Input() item: CategoryWAsset;
  @Input() selected = false;
  storedTheme: string;
  categoryImage: any = '';

  constructor(
    private themeService: StoreThemeService,
  ) {
    this.themeService.themeValue.subscribe((nextValue) => {
      this.storedTheme = nextValue;
    });
  }
}
