<app-to-go-back path="/conta/login"></app-to-go-back>

<div class="reset_password_area container d-grid justify-content-center text-center">
  <div>
    <img
      src="assets/dark-theme/account/backoffice_logo_login_dark.svg"
      alt="image placeholder"
      id="backoffice_logo_placeholder"/>
    <h2>Esqueceu a senha?</h2>
    <p>
      Não se preocupe. Digite seu endereço de e-mail abaixo e enviamos as
      instruções para recuperação da conta.
    </p>
  </div>

  <form
    action=""
    [formGroup]="resetPasswordForm"
    (ngSubmit)="handleClickRedefinedPassword()"
  >
    <div class="d-grid justify-content-center">
      <app-form-text-input
        controlName="email"
        label="E-mail"
        dataCy="input-email"
        [inputError]="handleFormError(resetPasswordForm.get('email'))"
      ></app-form-text-input>
      <app-form-submit-button
        class="btn_reset_password"
        title="Redefinir senha"
        [loading]="loading"
        [disabled]="resetPasswordForm.invalid || loading"
      ></app-form-submit-button>
    </div>
  </form>
</div>
<div class="snackbar container">
  <ng-template #snackbar></ng-template>
</div>
