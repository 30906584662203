import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-search-bar-lg',
  templateUrl: './search-bar-lg.component.html',
  styleUrls: [ './search-bar-lg.component.scss' ],
})
export class SearchBarLgComponent {
  @Input() searchInput = '';
  @Input() searchText = 'Buscar';
  @Output() triggerSearch = new EventEmitter<any>();
  @Output() resetSearch = new EventEmitter<any>();
  inputOnFocus = false;

  onReset(): void {
    this.searchInput = '';
    this.resetSearch.emit();
  }

  onSearch(): void {
    this.triggerSearch.emit(this.searchInput.replace(/\s+/g, ' '));
  }
}
