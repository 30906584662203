<ng-container>
  <button
    matRipple
    class="d-flex align-items-center justify-content-between"
    [ngClass]="{'selected': hightlightOn && !!selected?.value && selected?.value?.length && options.length, 'reason-style': type === 'denyReasons'}"
    type="button"
    id="dropdownMenuButton"
    [disabled]="disabled"
    aria-expanded="false"
    data-bs-toggle="dropdown"
    [attr.data-bs-auto-close]="hasSelections ? 'outside' : 'true'"
    [attr.data-cy]="dataCy"
    (click)="selected.markAsTouched()"
  >
    <span data-cy="text-state-selected">
      {{ label }}
    </span>
    <mat-icon aria-hidden="false" aria-label="Expandir menu">expand_more</mat-icon>
  </button>
  <mat-error *ngIf="inputError && !selected.valid && selected.touched" class="input_error_message font-subtitle-medium-14">{{inputError}}</mat-error>

  <div
    *ngIf="options"
    class="dropdown-menu"
    [ngClass]="{'menu-full-width': menuFullWidth, 'menu-possession': type === 'denyReasons'}"
    aria-labelledby="dropdownMenuButton"
  >
    <div [ngClass]="hideByType() ? 'hide': 'search-input'">
      <img alt="Ícone Buscar" src="assets/appbar/dark-theme/icon_busca.svg">
      <input [(ngModel)]="searchInput" type="text" placeholder="Procurar" data-cy="input-search">
    </div>

    <div [ngClass]="hideByType() ? 'hide' : 'div-ext'" id="select-all" *ngIf="hasSelections">
      <span  (click)="onSelectAll()" class="span-ext dark-btn" data-cy="button-unselect-all">{{getSelectAllLabel()}}</span>
    </div>

    <ul id="dropdown-list">
      <li *ngIf="options.length === 0 && !searchInput" class="justify-content-center">
        <div class="row spinner-border" role="status"></div>
      </li>

      <li
        *ngFor="let option of getOptions()"
        (click)="onSelect(option.id)"
        [ngClass]="{'selected': hasSelections ?
          (selectedList.includes(option.id)) :
          (option.id === this.selected.value)}"
        data-cy="item"
      >
        <app-input-checkbox
          *ngIf="hasSelections" data-cy="checkbox-item"
          [selected]="selectedList.includes(option.id)"
          class="checkbox"></app-input-checkbox>
        <span [appHighlight]="searchInput">{{option.name}}</span>
      </li>
    </ul>
  </div>
  <mat-error
    *ngIf="hasError"
    class="error-message font-subtitle-medium-14"
    >{{ error }}</mat-error
  >
</ng-container>
