import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable, TemplateRef } from '@angular/core';
import { AppbarButton } from '@app/shared/interfaces/backoffice/appbar';

import { StoreThemeService } from './storeTheme.service';


@Injectable()
export class AppbarChanges {
  title = new BehaviorSubject('Title');
  buttons = new BehaviorSubject([]);
  expanded = new BehaviorSubject(true);
  secondaryButtons = new BehaviorSubject<AppbarButton[]>([]);
  template = new BehaviorSubject<TemplateRef<any>>(null);

  constructor(private storeThemeService: StoreThemeService) {}

  setAppbar(title: string = '', buttons: AppbarButton[] = [], template: TemplateRef<any> = null): void{
    this.setTitle(title);
    this.setButtons(buttons);
    this.setTemplate(template);
  }

  setTitle(title: string): void {
    this.title.next(title);
  }

  setButtons(buttons: AppbarButton[]): void {
    const buttonArray = this.setButtonsIcon<AppbarButton>(buttons);
    this.buttons.next(buttonArray);
  }

  setSecondaryButtons(buttons: AppbarButton[]): void {
    const buttonArray = this.setButtonsIcon<AppbarButton>(buttons);
    this.secondaryButtons.next(buttonArray);
  }

  setTemplate(template: TemplateRef<any>): void {
    this.template.next(template);
  }

  setExpanded(value: boolean): void {
    this.expanded.next(value);
  }

  getExpanded(): Observable<boolean> {
    return this.expanded.asObservable();
  }

  private setButtonsIcon<Type extends AppbarButton>(buttons: Type[]): Type[] {
    const buttonArray: Type[] = [];
    const storedTheme = this.storeThemeService.theme;
    for (const button of buttons) {
      buttonArray.push({
        ...button,
        iconPath: button ? `assets/appbar/${storedTheme}/${button.iconPath}` : '',
      });
    }
    return buttonArray;
  }
}
