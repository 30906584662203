<form class="form">
  <div class="d-flex line">
    <div class="input-group">
      <img src="assets/dark-theme/icon-busca.svg" alt="Buscar ícone" />
      <input
        type="text"
        class="form-control"
        id="search_input"
        name="search_input"
        maxlength="50"
        [placeholder]="searchText"
        [(ngModel)]="searchInput"
        (focus)="this.inputOnFocus = true"
        (focusout)="this.inputOnFocus = false"
        data-cy="input-search"
      />
      <div
        *ngIf="!!searchInput"
        (click)="onReset()"
        data-cy="button-search-reset"
      >
        <mat-icon aria-hidden="false" aria-label="Search" class="close"
          >highlight_off</mat-icon
        >
      </div>
    </div>
    <button
      matRipple
      class="btn-search"
      [disabled]="!searchInput || searchInput.trim().length < 3"
      (click)="onSearch()"
    >
      Buscar
    </button>
  </div>
</form>
