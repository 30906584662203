import { GlobalConstants } from '@assets/globalConstants';
import {
  SearchUsersByPermissionLevelQuery,
  SearchUsersByPermissionLevelInput,
  SearchUsersByPermissionLevelGQL,
  DeleteAnotherUserInput,
  DeleteAnotherUserMutation,
  DeleteAnotherUserGQL,
  CreateUserAdminGQL,
  CreateUserAdminInput,
  CreateUserAdminMutation,
} from '@shared/graphql/users/users-graphql';
import { MutationResult } from 'apollo-angular';
import { LocalStorageService } from '@core/services/local-storage.service';
import { FetchResult, ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ConfirmSignUpGQL,
  ConfirmSignUpMutation,
  ConfirmSignUpMutationVariables,
  GetUserInfoGQL,
  GetUserInfoQuery,
  RecoverPasswordGQL,
  RecoverPasswordMutation,
  RecoverPasswordMutationVariables,
  RefreshSessionGQL,
  RefreshSessionMutation,
  SignInGQL,
  SignInMutation,
  SignInInput,
  SignUpGQL,
  SignOutInput,
  SignOutGQL,
  SignOutMutation,
  RequestPasswordRecoveryGQL,
  RequestPasswordRecoveryMutationVariables,
  SignUpInput,
  SignUpMutation,
  ResendEmailConfirmationGQL,
  ResendEmailConfirmationInput,
  ResendEmailConfirmationMutation,
  UpdatePasswordMutationVariables,
  UpdatePasswordMutation,
  UpdateNameGQL,
  UpdateNameMutationVariables,
  UpdateNameMutation,
  UpdateEmailMutation,
  UpdateEmailMutationVariables,
  UpdatePasswordGQL,
  UpdateEmailGQL,
  ConfirmPasswordGQL,
  ConfirmPasswordInput,
  ConfirmPasswordQuery,
} from '@shared/graphql/users/users-graphql';
import { UserData } from '@shared/interfaces/backoffice/users/usersData';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  constructor(
    private ls: LocalStorageService,
    private signInGQL: SignInGQL,
    private signUpGQL: SignUpGQL,
    private signOutGQL: SignOutGQL,
    private recoverPasswordGQL: RecoverPasswordGQL,
    private confirmSignUpGQL: ConfirmSignUpGQL,
    private getUserInfoGQL: GetUserInfoGQL,
    private refreshSessionGQL: RefreshSessionGQL,
    private requestPasswordRecoveryGQL: RequestPasswordRecoveryGQL,
    private resendEmailGQL: ResendEmailConfirmationGQL,
    private updatePasswordGQL: UpdatePasswordGQL,
    private updateEmailGQL: UpdateEmailGQL,
    private updateNameGQL: UpdateNameGQL,
    private confirmPasswordGQL: ConfirmPasswordGQL,
    private deleteAnotherUserGQL: DeleteAnotherUserGQL,
    private searchUsersByPermissionLevelGQL: SearchUsersByPermissionLevelGQL,
    private createUserAdminGQL: CreateUserAdminGQL
  ) {}

  doLogin(params: SignInInput): Observable<FetchResult<SignInMutation>> {
    return this.signInGQL.mutate({ params });
  }

  resetPassword({
    params,
  }: RecoverPasswordMutationVariables): Observable<
    FetchResult<
      RecoverPasswordMutation,
      Record<string, any>,
      Record<string, any>
    >
  > {
    return this.recoverPasswordGQL.mutate({ params });
  }

  updateName({
    params,
  }: UpdateNameMutationVariables): Observable<
    MutationResult<UpdateNameMutation>
  > {
    return this.updateNameGQL.mutate({ params });
  }

  updatePassword({
    params,
  }: UpdatePasswordMutationVariables): Observable<
    FetchResult<
      UpdatePasswordMutation,
      Record<string, any>,
      Record<string, any>
    >
  > {
    return this.updatePasswordGQL.mutate({ params });
  }

  updateEmail({
    params,
  }: UpdateEmailMutationVariables): Observable<
    FetchResult<UpdateEmailMutation, Record<string, any>, Record<string, any>>
  > {
    return this.updateEmailGQL.mutate({ params });
  }

  sendEmail({
    params,
  }: RequestPasswordRecoveryMutationVariables): Observable<
    FetchResult<RecoverPasswordMutation>
  > {
    return this.requestPasswordRecoveryGQL.mutate({ params });
  }

  signUp(params: SignUpInput): Observable<FetchResult<SignUpMutation>> {
    return this.signUpGQL.mutate({ params });
  }

  signOut(params: SignOutInput): Observable<MutationResult<SignOutMutation>> {
    return this.signOutGQL.mutate({ params });
  }

  deleteAnotherUser(
    params: DeleteAnotherUserInput
  ): Observable<MutationResult<DeleteAnotherUserMutation>> {
    return this.deleteAnotherUserGQL.mutate({ params });
  }

  confirmAccount({
    params,
  }: ConfirmSignUpMutationVariables): Observable<
    FetchResult<ConfirmSignUpMutation>
  > {
    return this.confirmSignUpGQL.mutate({ params });
  }

  resendEmailConfirmation(
    params: ResendEmailConfirmationInput
  ): Observable<
    FetchResult<
      ResendEmailConfirmationMutation,
      Record<string, any>,
      Record<string, any>
    >
  > {
    return this.resendEmailGQL.mutate({ params });
  }

  confirmPassword(
    params: ConfirmPasswordInput
  ): Observable<ApolloQueryResult<ConfirmPasswordQuery>> {
    return this.confirmPasswordGQL.fetch({ params });
  }

  createUserAdmin(
    params: CreateUserAdminInput
  ): Observable<MutationResult<CreateUserAdminMutation>> {
    return this.createUserAdminGQL.mutate({ params });
  }

  refreshSession(): Observable<FetchResult<RefreshSessionMutation>> {
    return this.refreshSessionGQL.mutate({
      params: {
        refreshToken: this.userData.refreshToken,
        accessToken: this.userData.accessToken,
      },
    });
  }

  getUserInfo(): Observable<ApolloQueryResult<GetUserInfoQuery>> {
    return this.getUserInfoGQL.fetch({
      params: { accessToken: this.userAccessToken },
    });
  }

  isAdmin(): boolean {
    return GlobalConstants.userAttributes?.permissionsLevel.includes('ADMIN');
  }

  searchUsersByPermissionLevel(
    params: SearchUsersByPermissionLevelInput
  ): Observable<ApolloQueryResult<SearchUsersByPermissionLevelQuery>> {
    return this.searchUsersByPermissionLevelGQL.fetch({ params });
  }

  get userAccessToken(): string {
    return (this.ls.getItem('SESSION_DATA') as UserData)?.accessToken;
  }

  get userData(): UserData {
    return this.ls.getItem('SESSION_DATA') as UserData;
  }

  set userData(userData: UserData) {
    const { accessToken, refreshToken } = userData;
    this.ls.setItem('SESSION_DATA', { accessToken, refreshToken });
  }
}
