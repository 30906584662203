import { HttpErrorResponse } from '@angular/common/http';
import { FormValidator } from '@app/core/validators/formValidator';

interface Error {
  message: string;
  code: number;
  name: string;
  errors?: any[];
  canRetry: boolean,
}

/**
 * função pra transformar os erros em um objeto "palpável" e já retorna um objeto pronto com o código/mensagem do erro
 * @param err Resultado que vem do catch
 * @returns Objeto de erro padronizado
 */
export function handleErrors(err: HttpErrorResponse): Error {
  const error = Object.getOwnPropertyNames(err).reduce((acc, key) => {
    acc[key] = err[key];
    return acc;
  }, {});

  if(error['networkError']?.name === 'HttpErrorResponse') {
    return {
      message: 'Ocorreu um erro. Por favor, tente novamente.',
      code: err.status ?? error['networkError'].status,
      name: 'HttpErrorResponse',
      canRetry: true,
    };
  }

  if(error['graphQLErrors'] === undefined || error['graphQLErrors'].length === 0) {
    return {
      message: err.message,
      code: err.status,
      name: err.name,
      canRetry: true,
    };
  }

  const errorInfo: Error = error['graphQLErrors'][0]['errorInfo'];
  return {
    code: errorInfo?.code,
    message: errorInfo?.errors
      ? (Object.values(errorInfo?.errors[0].constraints)[0] as string)
      : errorInfo?.message,
    name: errorInfo?.name,
    canRetry: false,
  };
}

/**
 * função pra pegar os erros de validação de formulários
 * @param control Resultado que vem do formulario
 */
export function handleFormError(control): Error {
  for (const propertyName in control.errors) {
    if (control.errors.hasOwnProperty(propertyName) && control.touched) {
      return FormValidator.getError(propertyName, control.errors[propertyName]);
    }
  }
}
