import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AppbarChanges } from '@app/core/services/appbarChanges.service';
import { AppbarButton } from '@app/shared/interfaces/backoffice/appbar';
import { CreateAppbarButton } from '@app/shared/utils/createAppbarButton';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap, filter, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-appbar-requests',
  templateUrl: './appbar-requests.component.html',
  styleUrls: [ './appbar-requests.component.scss' ],
})
export class AppbarRequestsComponent implements OnInit, OnChanges {
  @ViewChild('appbarRequests', { static: true })
  appbarTemplate: TemplateRef<any>;

  @Output() triggerSearch = new EventEmitter<string>();
  @Output() triggerOnSort = new EventEmitter<string>();
  @Output() triggerSelectedTypes = new EventEmitter<any>();

  @Input() hasFilter = true;

  @Input() sortType: string;
  @Input() sortRequestStatusType = new FormControl([] as string[]);

  @Input() requestsLength: number;

  componentTitle = this.route.routeConfig.component.name;

  inputSearch = '';

  params$: Subscription;

  historyButton: AppbarButton;

  requestTypes = [
    {
      name: 'Todas as solicitações',
      id: 'ALL',
    },
    {
      name: 'Solicitações de posse',
      id: 'OWNERSHIP',
    },
    {
      name: 'Solicitações de exclusão',
      id: 'DELETION',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private appbarChanges: AppbarChanges,
    private router: Router,
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((navEvent) => {
      if (
        navEvent instanceof NavigationEnd &&
        !this.route.snapshot.queryParams.search
      ) {
        this.resetSearch();
      }
    });
  }

  ngOnInit(): void {
    this.loadInitialButtons();
    this.listenRouterEvents();

    if(!this.sortRequestStatusType.value) this.sortRequestStatusType.setValue([ 'ALL' ]);

    this.sortRequestStatusType.valueChanges
      .pipe(
        untilDestroyed(this),
        map((types) => {
          if (types.length !== 0) this.triggerSelectedTypes.emit(types);
        }),
      )
      .subscribe();

    if (this.route.snapshot.queryParams.search)
      this.onSearch(this.route.snapshot.queryParams.search);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['requestsLength']) this.changeAppbar();
  }

  changeAppbar(): void {
    this.appbarChanges.setAppbar(
      `Solicitações (${this.requestsLength})`,
      this.componentTitle == 'RequestsHistoryComponent'
        ? []
        : [ this.historyButton ],
      this.appbarTemplate,
    );
  }

  onResetSearch(): void {
    this.triggerSearch.emit('');
  }

  onSearch(newInput: any): void {
    this.inputSearch = newInput;
    this.triggerSearch.emit(newInput);
  }

  resetSearch(): void {
    this.inputSearch = '';
  }

  onSort(type: string): void {
    this.sortType = type;
    this.triggerOnSort.emit(this.sortType);
  }

  loadInitialButtons(): void {
    this.historyButton = CreateAppbarButton.historicoButton(
      'Histórico de solicitações',
      this.showHistory.bind(this),
    );
    this.changeAppbar();
  }

  showHistory(): void {
    this.componentTitle = 'history';
    this.router.navigate([ 'historico' ], { relativeTo: this.route });
  }

  private listenRouterEvents(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        tap((event: NavigationEnd) => {
          const { search } = this.route.snapshot.queryParams;

          if (search) {
            this.onSearch(search);
          } else {
            this.resetSearch();
          }
        }),
      )
      .subscribe();
  }
}
