<div class="product d-inline-flex w-100 placeholder-wave" [ngClass]="{'dark-theme': storedTheme==='dark-theme'}" *ngIf="show">
  <div class="product-image-div placeholder-wave">
    <div class="product-image placeholder"></div>
  </div>
  <div class="product-text-div placeholder-wave">
    <span class="font-subtitle-medium placeholder"></span>
    <span class="font-subtitle-regular placeholder"></span>
    <span class="font-body-regular-14 placeholder"></span>
  </div>
</div>
