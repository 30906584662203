export function readFileAsync(file: File, readerType: 'text' | 'dataURL' | 'binary'): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (): void => {
      resolve(reader.result as string);
    };

    reader.onerror = reject;

    switch (readerType) {
      case 'text':
        reader.readAsText(file);
        break;
      case 'dataURL':
        reader.readAsDataURL(file);
        break;
      case 'binary':
        reader.readAsBinaryString(file);
        break;
    }
  });
}
