import { MatDividerModule } from '@angular/material/divider';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { FormSectionTitleComponent } from './components/form-elements/form-section-title/form-section-title.component';
import { FormImageInputComponent } from './components/form-elements/form-image-input/form-image-input.component';
import { FormTextInputComponent } from './components/form-elements/form-text-input/form-text-input.component';
import { FormSelectInputComponent } from './components/form-elements/form-select-input/form-select-input.component';
import { FormSubmitButtonComponent } from './components/form-elements/form-submit-button/form-submit-button.component';
import { FormBarcodeInputComponent } from './components/form-elements/form-barcode-input/form-barcode-input.component';
import { CheckFeatComponent } from './components/check-feat/check-feat.component';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SearchBarLgComponent } from './components/search-bar-lg/search-bar-lg.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SnackbarAlertComponent } from './components/snackbar-alert/snackbar-alert.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { SortByComponent } from './components/sort-by/sort-by.component';
import { InfiniteLoadingItemComponent } from './components/infinite-loading-item/infinite-loading-item.component';
import { AddObjectComponent } from './components/add-object/add-object.component';
import { SelectItemComponent } from './components/product-elements/appbar-products-search/components/select-item/select-item.component';
import { CategoryItemComponent } from
'./components/product-elements/appbar-products-search/components/category-item/category-item.component';
import { AppbarProductsSearchComponent } from './components/product-elements/appbar-products-search/appbar-products-search.component';

import { FileSizePipe } from './pipes/filesize.pipe';

import { InfiniteLoadingDropzoneContainerComponent } from
'./components/infinite-loading-dropzone-container/infinite-loading-dropzone-container.component';
import { InfiniteLoadingDownloadTemplateComponent } from
'./components/infinite-loading-download-template/infinite-loading-download-template.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { AddGroupPageComponent } from './components/add-group-page/add-group-page.component';
import { CardItemComponent } from './components/card-item/card-item.component';

import { DropdownComponent } from './components/dropdown/dropdown.component';

import { DropdownCategoriesComponent } from
'./components/product-elements/appbar-products-search/components/dropdown-categories/dropdown-categories.component';
import { DropdownSubcategoriesComponent } from
'./components/product-elements/appbar-products-search/components/dropdown-subcategories/dropdown-subcategories.component';

import { SearchBarSmComponent } from './components/search-bar-sm/search-bar-sm.component';
import { ToGoBackComponent } from './components/to-go-back/to-go-back.component';
import { InputCheckboxComponent } from './components/input-checkbox/input-checkbox.component';
import { HighlightDirective } from './directives/highlight.directive';
import { AppbarProductsComponent } from './components/appbar-products/appbar-products.component';
import { BaseLocationComponent } from './components/base-location/base-location.component';
import { AppbarStoresComponent } from './components/appbar-stores/appbar-stores.component';
import { FormCalendarInputComponent } from './components/form-elements/form-calendar-input/form-calendar-input.component';
import { AppbarRequestsComponent } from './components/appbar-requests/appbar-requests.component';
import { FormCalendarIntervalInputComponent } from
'./components/form-elements/form-calendar-interval-input/form-calendar-interval-input.component';
import { AppbarSupportComponent } from './components/appbar-support/appbar-support.component';
import { DeleteUserModalComponent } from './components/delete-user-modal/delete-user-modal.component';
import { InfiniteLoadingTableItemComponent } from
'./components/infinite-loading-table-item/infinite-loading-table-item.component';
import { HomeComponent } from './components/home/home.component';

@NgModule({
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  declarations: [
    CheckFeatComponent,
    ErrorAlertComponent,
    FileSizePipe,
    PaginationComponent,
    SnackbarAlertComponent,
    SearchBarLgComponent,
    LoadingSpinnerComponent,
    ConfirmationPopupComponent,
    FormSectionTitleComponent,
    FormImageInputComponent,
    FormTextInputComponent,
    FormSelectInputComponent,
    FormSubmitButtonComponent,
    FormBarcodeInputComponent,
    FormCalendarInputComponent,
    InfiniteLoadingItemComponent,
    InfiniteLoadingDropzoneContainerComponent,
    InfiniteLoadingDownloadTemplateComponent,
    SortByComponent,
    AddObjectComponent,
    AddGroupPageComponent,
    DropdownComponent,
    CardItemComponent,
    AddObjectComponent,
    AppbarProductsSearchComponent,
    DropdownCategoriesComponent,
    DropdownSubcategoriesComponent,
    SelectItemComponent,
    CategoryItemComponent,
    SearchBarSmComponent,
    ToGoBackComponent,
    HighlightDirective,
    InputCheckboxComponent,
    AppbarProductsComponent,
    BaseLocationComponent,
    AppbarStoresComponent,
    FormCalendarIntervalInputComponent,
    AppbarRequestsComponent,
    AppbarSupportComponent,
    DeleteUserModalComponent,
    InfiniteLoadingTableItemComponent,
    HomeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatSnackBarModule,
    ScrollingModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    ErrorAlertComponent,
    CheckFeatComponent,
    PaginationComponent,
    SnackbarAlertComponent,
    SearchBarLgComponent,
    LoadingSpinnerComponent,
    SortByComponent,
    FileSizePipe,
    FormSectionTitleComponent,
    FormImageInputComponent,
    FormTextInputComponent,
    FormSelectInputComponent,
    FormSubmitButtonComponent,
    FormBarcodeInputComponent,
    FormCalendarInputComponent,
    InfiniteLoadingItemComponent,
    InfiniteLoadingDropzoneContainerComponent,
    InfiniteLoadingDownloadTemplateComponent,
    AddObjectComponent,
    AddGroupPageComponent,
    AppbarProductsSearchComponent,
    DropdownComponent,
    DropdownCategoriesComponent,
    DropdownSubcategoriesComponent,
    CardItemComponent,
    SearchBarSmComponent,
    ToGoBackComponent,
    AppbarProductsComponent,
    AppbarStoresComponent,
    HighlightDirective,
    AppbarRequestsComponent,
    InputCheckboxComponent,
    FormCalendarIntervalInputComponent,
    AppbarSupportComponent,
    DeleteUserModalComponent,
    InfiniteLoadingTableItemComponent,
    HomeComponent,
  ],
  entryComponents: [
    SnackbarAlertComponent,
  ],
})
export class SharedModule {}
