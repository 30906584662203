import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { assertNever } from '@shared/utils/assert';

interface Option {
  name: string;
  id: string;
  federativeUnit?: string;
}
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: [ './dropdown.component.scss' ],
})
export class DropdownComponent implements OnChanges, OnInit {
  @Input() options: Option[] = [];
  @Input() selected: FormControl = new FormControl(null);
  @Input() selectedPlaceholder: string = null;
  @Input() type:
    | 'states'
    | 'cities'
    | 'storeTypes'
    | 'storeTypesMulti'
    | 'subcategories'
    | 'subcategory'
    | 'denyReasons'
    | 'measurements'
    | 'requests'
    | 'deleteReasons';
  @Input() hasSelections: boolean;
  @Input() disabled = false;
  @Input() hightlightOn = true;
  @Input() menuFullWidth = false;
  @Input() inputError: string;
  @Input() dataCy = 'dropdown-component';
  @Input() showFilter = true;
  @Input() error: string;
  @Output() triggerUpdateSelectedReasons = new EventEmitter<string[]>();

  searchInput = '';
  selectedAll: boolean;
  selectedList: string[] = [];
  hasError = false;
  label: string;

  constructor() {}

  ngOnInit(): void {
    if (this.selected.value) this.selectedList = this.selected.value;
    this.label = this.getLabel();
    this.selected.valueChanges.subscribe(() => {
      this.label = this.getLabel();
      this.hasError = this.selected.touched && !this.selected.valid;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const options = changes['options'];
    if (
      options &&
      options.currentValue?.length !== options.previousValue?.length
    ) {
      this.selectedList = [];
      this.selectedPlaceholder = null;
      this.selectedAll = false;
      if(this.type === 'subcategories') this.selected.setValue(null);
      this.label = this.getLabel();
    }
  }

  getLabel(): string {
    if (this.selectedPlaceholder && !this.selected?.value) {
      return this.selectedPlaceholder;
    }

    const selectedObj = (): Option => {
      if (!this.selected?.value) return { id: '', name: '' };

      return this.options?.find((option) => {
        return option.id === this.selected.value || option.id === this.selected.value[0];
      });
    };

    const selectedObject = selectedObj();

    switch (this.type) {
      case 'states':
        return !!this.selected.value && !!selectedObject?.name
          ? selectedObject.name
          : 'Estado';
      case 'cities':
        return !!this.selected.value && !!selectedObj.name
          ? selectedObject?.name
          : 'Cidade';
      case 'storeTypesMulti':
        return !this.selected.value || !this.selected.value.length
          ? 'Tipo'
          : this.selected.value?.length === 1 && !!selectedObj.name
          ? selectedObject?.name
          : 'Tipos selecionados';
      case 'storeTypes':
        return (!this.selected.value || !this.selected.value.length)
          ? 'Tipo de estabelecimento *' :
          selectedObject?.name;
      case 'subcategories':
        return !this.selected?.value || !this.selected.value?.length
          ? 'Subcategoria'
          : this.selected.value?.length === 1
          ? selectedObject?.name
          : 'Subcategorias selecionadas';
      case 'subcategory':
        return !this.selected.value || !this.selected.value.length
          ? 'Subcategoria *'
          : selectedObject?.name;
      case 'measurements':
        return this.selected.value === null || !this.selected.value.length
          ? 'Medida *'
          : selectedObject?.name;
      case 'denyReasons':
      case 'deleteReasons':
        return 'Selecione os motivos';
      case 'requests':
        return !this.selected.value || !this.selected.value.length
          ? 'Todas as solicitações'
          : selectedObject?.name;
      default:
        return assertNever(this.type);
    }
  }

  hideByType(): boolean {
    return (
      this.type === 'denyReasons' ||
      this.type === 'deleteReasons' ||
      this.type === 'requests'
    );
  }

  getOptions(): Option[] {
    let options = this.options;

    if (this.type === 'states') {
      options = this.options.map((option) => {
        return { ...option, name: `${option.name} (${option.federativeUnit})` };
      });
    }

    if (this.searchInput === '') return options;

    const handleText = (text: string): string => {
      return text
        ?.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    };

    const filteredOptions = options.filter((option) =>
      handleText(option.name).includes(handleText(this.searchInput)),
    );

    return filteredOptions;
  }

  getSelectAllLabel(): string {
    if (this.searchInput === '') {
      return this.selectedList.length === this.options.length
        ? 'Desselecionar tudo'
        : 'Selecionar tudo';
    } else {
      const filteredOptions = this.options.filter((option) => {
        if (
          option.name.toLowerCase().startsWith(this.searchInput.toLowerCase())
        ) {
          return option;
        }
      });

      return this.selectedList.length === filteredOptions.length
        ? 'Desselecionar tudo'
        : 'Selecionar tudo';
    }
  }

  onSelect(optionId: string): void {
    this.selected.markAsTouched();

    if (this.hasSelections) {
      this.onSelectSelection(optionId);
    } else {
      this.onSelectOne(optionId);
    }
  }

  onSelectAll(): void {
    if (this.getSelectAllLabel() === 'Desselecionar tudo') {
      this.selectedList = [];
      this.selected.setValue([]);
      return;
    }

    if (this.searchInput !== '') {
      this.selectedList = this.options
        .filter((option) => {
          if (
            option.name.toLowerCase().startsWith(this.searchInput.toLowerCase())
          ) {
            return option.id;
          }
        })
        .map((option) => option.id);
      this.selected.setValue(this.selectedList);
      return;
    }

    this.selectedList = this.options.map((option) => option.id);
    this.selected.setValue(this.selectedList);
  }

  private onSelectSelection(optionId: string): void {
    let found = this.selectedList.includes(optionId);

    if (found) {
      this.selectedList = this.selectedList.filter(
        (selectedListOptionId) => selectedListOptionId !== optionId,
      );
    } else {
      if(this.type === 'requests') {
        if(optionId === 'ALL') this.selectedList = [];
        if(optionId !== 'ALL') this.selectedList = this.selectedList.filter(option => option !== 'ALL');
      }

      this.selectedList.push(optionId);
    }

    if(this.type === 'requests' && this.selectedList.length === 0) this.selectedList.push('ALL');

    this.selected.setValue(this.selectedList);
    this.triggerUpdateSelectedReasons.emit(this.selectedList);
  }

  private onSelectOne(value: string = null): void {
    if (this.selected.value === value) {
      if(this.type === 'requests') return;
      this.selected.setValue(null);
    } else {
      this.selected.setValue(value);
    }
  }
}
