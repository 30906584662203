import { AccountModule } from './features/account/account.module';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgsFormsModule } from '@ng-stack/forms';
import { ApolloModule } from 'apollo-angular';

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    ApolloModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AccountModule,
    FormsModule,
    ReactiveFormsModule,
    GraphQLModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    CommonModule,
    CoreModule,
    ApolloModule,
    NgsFormsModule,
  ],
  providers: [],
  bootstrap: [ AppComponent ],
})
export class AppModule {}
