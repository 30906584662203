import { ActivatedRoute } from '@angular/router';
import { OffersService } from '@core/services/offers.service';
import { ProcessCsvFileService } from '@app/shared/services/process-csv-file.service';
import { PartitionItemByCSV } from './../interfaces/backoffice/addBatch';
import { Inject, Injectable } from '@angular/core';
import { PopupService } from '@app/core/services/popup.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { StoreTypesTranslator } from '@assets/StoreTypeTranslation';
import { ProductsService } from '@services/products.service';
import { StoresService } from '@services/stores.service';
import { UserAccountService } from '@services/user-account.service';
import { OfferByCSV } from '@shared//interfaces/backoffice/offers/offers';
import { ItemByCSV, TargetType } from '@shared/interfaces/backoffice/addBatch';
import { ProductByCSV } from '@shared/interfaces/backoffice/products/product';
import { StoreByCSV } from '@shared/interfaces/backoffice/stores/store';
import { ItemAdditionError } from '@shared/interfaces/errorObjects';
import { assertNever } from '@shared/utils/assert';
import { numToSSColumn } from '@shared/utils/numberToAlpha-util';
import {
  BehaviorSubject,
  concat,
  defer,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  delay,
  map,
  retryWhen,
  tap,
  toArray,
} from 'rxjs/operators';

const REQUIRED_FIELD_ERROR = 1;
const CATEGORY_SUBCATEGORY_NOT_RELETED_ERROR = 2;
const BARCODE_IN_USE_ERROR = 3;
const STATE_CITY_NOT_RELETED_ERROR = 4;
const NON_EXISTENT_PRODUCT = 5;
const INVALID_PRICE = 6;
const INVALID_QUANTITY = 7;
const TAKEPAY_INVALID_QUANTITIES = 8;
const FIELD_NOT_BELONGING_OFFER = 9;
const DUPLICATED_OFFER = 10;
const DUPLICATED_STORE = 11;
const LOWER_START_DATE = 12;
const LOWER_END_DATE = 13;

@Injectable({
  providedIn: 'root',
})
export class BatchFormatValidationService {
  private ACCESS_TOKEN: string;
  private invertedStoreTypeTranslation;
  private validationAttempts: number;

  private productRequiredFields = [
    'name',
    'barcode',
    'categoryId',
    'subCategoryId',
    'measurementValue',
    'measurementId',
  ];

  // TODO: Conferir se vai ter o CEP como obrigatório ou não
  private storeFields = [
    'name',
    'storeType',
    'state',
    'city',
    'district',
    'street',
    'number',
    'complement',
    'cnpj',
  ];

  private offerRequiredFields = [
    'barcode',
    'offerType',
    'initialTime',
    'endingTime',
  ];

  private offersField = [
    'barcode',
    'offerType',
    'unityPrice',
    'fromQuantity',
    'fromPrice',
    'fixedQuantity',
    'fixedPrice',
    'takepayX',
    'takepayY',
    'takepayPrice',
    'initialTime',
    'endingTime',
  ];

  private FieldTranslator: Record<string, string> = {
    name: 'nome',
    storeType: 'tipo de loja',
    state: 'estado',
    city: 'cidade',
    district: 'bairro',
    street: 'rua/avenida/estrada',
    number: 'número',
    cnpj: 'CNPJ',
    barcode: 'código de barras',
    categoryId: 'ID da categoria',
    subCategoryId: 'ID da subcategoria',
    measurementValue: 'valor da medida',
    measurementId: 'ID da medida',
    offerType: 'tipo de oferta',
    unityPrice: 'Unitária – Preço',
    fromQuantity: 'A partir de – Quantidade mínima',
    fromPrice: 'A partir de – Preço unitário',
    fixedQuantity: 'Comprando X – Quantidade',
    fixedPrice: 'Comprando X – Preço unitário',
    takepayX: 'Leve X Pague Y – Leve X',
    takepayY: 'Leve X Pague Y – Pague Y',
    takepayPrice: 'Leve X Pague Y – Preço unitário',
    initialTime: 'Data de início',
    endingTime: 'Data de término',
  };

  asyncValidationProgress = {
    progress: new BehaviorSubject<number>(0),
    totalSize: 0,
  };

  currentIndex = 0;

  abortUpload = false;

  authorizationProductKey: string;

  constructor(
    @Inject('targetType') private targetType: TargetType,
    private userAccountService: UserAccountService,
    private productsService: ProductsService,
    private storesService: StoresService,
    private snackbarService: SnackbarService,
    private popupService: PopupService,
    private processCsvFileService: ProcessCsvFileService,
    private offerService: OffersService,
    private route: ActivatedRoute,
  ) {
    this.ACCESS_TOKEN = this.userAccountService.userAccessToken;
    this.invertedStoreTypeTranslation =
      this.swapRecordsKeyValues(StoreTypesTranslator);
  }

  validateProducts(partitions: PartitionItemByCSV[]): Observable<ItemAdditionError[]> {
    const products = partitions.reduce((acc, currVal) => acc.concat(currVal.items), []);
    this.asyncValidationProgress.totalSize = partitions.length;
    this.validationAttempts = 3;

    const categoryAndFieldValidation = products.map((product, i) => {
      const requiredFieldErros = this.productRequiredFields.map((field) =>
        this.validateIfColumnIsNull(
          product,
          field,
          i,
          this.productRequiredFields,
        ),
      );
      let subCategoryCategoryNotRelatedError: ItemAdditionError | undefined;
      this.validateSubCategoryCategoryRelation(product, i)
        .pipe(
          tap((value) => {
            subCategoryCategoryNotRelatedError = value;
          }),
        )
        .subscribe();


      return [ ...requiredFieldErros, subCategoryCategoryNotRelatedError ];
    });

    const barcodeValidation: ItemAdditionError[][] = [];
    const observables = [];


    for (let i = 0; i < partitions.length; i++) {
      observables.push(
        defer(() => {
          if (!this.abortUpload && this.validationAttempts > 0) {
            return this.barcodeAsyncValidation(
              partitions[i].items as ProductByCSV[],
              i,
              this.processCsvFileService.itemQueueSliceSize,
            ).pipe(
              tap((validatedBarcode) => {
                barcodeValidation.push(validatedBarcode);
                partitions[i].authorizationKey = this.authorizationProductKey;
              }),
            );
          }
        }),
      );
    }

    const validatedBarcodes = concat(...observables).pipe(
      toArray(),
      map(() => {
        return this.flatten([
          ...categoryAndFieldValidation,
          this.flatten(barcodeValidation),
        ]).filter(Boolean);
      }),
      catchError((error) => {
        this.popupService.information({
          title: 'Erro de validação',
          description:
            'Houve um erro na validação de sua planilha. Por favor, tente novamente.',
          cancelText: 'Fechar',
        });
        return of([ error ]);
      }),
    );

    return validatedBarcodes;
  }

  validateStores(partitions: PartitionItemByCSV[]): Observable<ItemAdditionError[]> {
    const stores = partitions.reduce((acc, currVal) => acc.concat(currVal.items), []);

    this.asyncValidationProgress.totalSize = partitions.length;
    this.validationAttempts = 3;

    const storesFieldValidation = stores.map((store, i) => {
      const requiredFieldErrors = this.storeFields.map((field) =>
        field !== 'complement' ? this.validateIfColumnIsNull(store, field, i, this.storeFields) : null,
      );

      return [ ...requiredFieldErrors ];
    });

    const duplicatedStoreError = this.validateDuplicatedStores(stores);
    duplicatedStoreError.length > 0 ? storesFieldValidation.push(duplicatedStoreError) : null;


    const localValidation = this.flatten(storesFieldValidation).filter(Boolean);
    if(localValidation.length !== 0) {
      this.asyncValidationProgress.progress.next(100);
      return of(localValidation);
    }

    const storeValidation: ItemAdditionError[][] = [];
    const observables = [];

    for (let i = 0; i < partitions.length; i++) {
      observables.push(
        defer(() => {
          if (!this.abortUpload && this.validationAttempts > 0) {
            return this.storeAsyncValidation(
              partitions[i],
              i,
              this.processCsvFileService.itemQueueSliceSize,
            ).pipe(
              tap((validatedStore) => {
                storeValidation.push(validatedStore);
              }),
            );
          }
        }),
      );
    }

    const validatedStores = concat(...observables).pipe(
      toArray(),
      map(() => {
        return this.flatten([
          ...storesFieldValidation,
          this.flatten(storeValidation),
        ]).filter(Boolean);
      }),
      catchError((error) => {
        this.popupService.information({
          title: 'Erro de validação',
          description:
            'Houve um erro na validação de sua planilha. Por favor, tente novamente.',
          cancelText: 'Fechar',
        });
        return of([ error ]);
      }),
    );

    return validatedStores;
  }

  validateOffers(partitions: PartitionItemByCSV[]): Observable<ItemAdditionError[]> {
    const offers = partitions.reduce((acc, currVal) => acc.concat(currVal.items), []);

    this.asyncValidationProgress.totalSize = partitions.length;
    this.validationAttempts = 3;

    const offersSingleValidation = offers.map((offer, i) => {
      return this.validateSingleOffer(offer, i);
    }).filter((item) => item.length > 0);

    const dateFieldValidation = offers.map((offer, i) => {
      return this.validateOfferTime(offer, i);
    }).filter((item) => item.length > 0);

    const duplicatedOfferError = this.validateDuplicatedOffersFromCSV(offers);
    duplicatedOfferError.length > 0 ? offersSingleValidation.push(duplicatedOfferError) : null;

    const observables = [];

    const duplicatedAsyncOffers: ItemAdditionError[][] = [];
    for (let i = 0; i < partitions.length; i++) {
      observables.push(
        defer(() => {
          if (!this.abortUpload && this.validationAttempts > 0) {
            return this.duplicateOfferAsyncValidation(
              partitions[i],
              i,
              this.processCsvFileService.itemQueueSliceSize,
            ).pipe(
              tap((value) => {
                duplicatedAsyncOffers.push(value);
              }),
            );
          }
        }),
      );
    }

    const validatedOffers = concat(...observables).pipe(
      toArray(),
      map(() => {
        return this.flatten([
          ...offersSingleValidation,
          ...dateFieldValidation,
          this.flatten(duplicatedAsyncOffers),
        ]).filter(Boolean);
      }),
      catchError(() => {
        this.popupService.information({
          title: 'Erro de validação',
          description:
            'Houve um erro na validação de sua planilha. Por favor, tente novamente.',
          cancelText: 'Fechar',
        });
        return null;
      }),
    );

    return validatedOffers as Observable<ItemAdditionError[]>;
  }

  validateOfferTime(offer: OfferByCSV, position: number): ItemAdditionError[] {
    const validationErrors: ItemAdditionError[] = [];

    const initTime = offer.initialTime.split('/');
    const endTime = offer.endingTime.split('/');

    const initTimeFormatted = new Date(initTime[2] + '-' + initTime[1] + '-' + initTime[0] + 'T03:00:00.000Z');
    const endTimeFormatted = new Date(endTime[2] + '-' + endTime[1] + '-' + endTime[0] + 'T03:00:00.000Z');

    const tempDate = new Date(new Date().setHours(0,0,0,0));

    if(initTimeFormatted < tempDate) {
      validationErrors.push(this.errorFactory(
        offer,
        position,
        this.offersField.findIndex((v) => v === 'initialTime') + 1,
        LOWER_START_DATE,
        'Data de início inferior a data atual',
      ));
    }

    if(endTimeFormatted.toISOString() == initTimeFormatted.toISOString() ||
    endTimeFormatted < initTimeFormatted) {
      validationErrors.push(this.errorFactory(
        offer,
        position,
        this.offersField.findIndex((v) => v === 'endingTime') + 1,
        LOWER_END_DATE,
        'Data de término deve ser maior que data de início',
      ));
    }

    return validationErrors.filter((err) => err);
  }

  validateSingleOffer(offer: OfferByCSV, position: number): ItemAdditionError[] {
    const validationErrors: ItemAdditionError[] = [];

    const fromOfferRequiredFields = [ 'fromQuantity', 'fromPrice' ];
    const fixedOfferRequiredFields = [ 'fixedQuantity', 'fixedPrice' ];
    const takepayOfferRequiredFields = [ 'takepayX', 'takepayY', 'takepayPrice' ];

    this.offerRequiredFields.map(field =>
      validationErrors.push(this.validateIfColumnIsNull(offer, field, position, this.offerRequiredFields)));

    switch (offer.offerType) {
      case 'Unitária':
        validationErrors.push(this.validateIfColumnIsNull(offer, 'unityPrice', position, [ 'unityPrice' ]));
        this.validateCorrectFields(offer, [ 'unityPrice' ], position).forEach(error => validationErrors.push(error));
        this.validateNumber(offer, [ 'unityPrice' ], position, this.offersField).forEach(error => validationErrors.push(error));
        break;

      case 'A partir de':
        fromOfferRequiredFields.map((requiredField) =>
          validationErrors.push(this.validateIfColumnIsNull(offer, requiredField, position, fromOfferRequiredFields)));

        this.validateCorrectFields(offer, fromOfferRequiredFields, position).forEach(error => validationErrors.push(error));
        this.validateNumber(offer, fromOfferRequiredFields, position, this.offersField).forEach(error => validationErrors.push(error));
        break;

      case 'Comprando X':
        fixedOfferRequiredFields.map((requiredField) =>
          validationErrors.push(this.validateIfColumnIsNull(offer, requiredField, position, fixedOfferRequiredFields)));

        this.validateCorrectFields(offer, fixedOfferRequiredFields, position).forEach(error => validationErrors.push(error));
        this.validateNumber(offer, fixedOfferRequiredFields, position, this.offersField).forEach(error => validationErrors.push(error));
        break;

      case 'Leve X Pague Y':
        takepayOfferRequiredFields.map((requiredField) =>
          validationErrors.push(this.validateIfColumnIsNull(offer, requiredField, position, takepayOfferRequiredFields)));

        this.validateCorrectFields(offer, takepayOfferRequiredFields, position).forEach(error => validationErrors.push(error));
        this.validateNumber(offer, takepayOfferRequiredFields, position, this.offersField).forEach(error => validationErrors.push(error));
        this.validateTakepayOffer(offer, position)?.forEach(error => validationErrors.push(error));
        break;

      default:
        break;
    }

    return validationErrors.filter((err) => err);
  }

  private validateCorrectFields(
    item: OfferByCSV,
    fields: string[],
    line: number,
    ): ItemAdditionError[] | undefined {
      const numberValidationErrors: ItemAdditionError[] = [];

      Object.keys(item).forEach((key) => {
        if(key === 'barcode' || key === 'name' || key === 'offerType' || key === 'initialTime' || key === 'endingTime') return;
        if(fields.includes(key)) return;
        if(item[key] !== '') {
          numberValidationErrors.push(this.errorFactory(
            item,
            line,
            this.offersField.findIndex((v) => v === key) + 1,
            FIELD_NOT_BELONGING_OFFER,
            `Campo ${this.FieldTranslator[key]} não pertence a oferta ${item.offerType}`,
          ));
        }
      });

      return numberValidationErrors;
  }

  private validateDuplicatedStores(
      stores: any[],
    ): ItemAdditionError[] {
    let errors: ItemAdditionError[] = [];

    const notDuplicates = [];
    const indexDuplicates = [];

    stores.forEach(((store, index) =>{
      if(notDuplicates.find((element) => element.cnpj === store.cnpj)) {
        indexDuplicates.push(index);
      } else {
        notDuplicates.push(store);
      }
    }));

    indexDuplicates.forEach(position => {
      errors.push(this.errorFactory(
        stores[position],
        position,
        9,
        DUPLICATED_STORE,
        'Estabelecimento duplicado.',
      ));
    });

    return errors;
  }

  private validateDuplicatedOffersFromCSV(offers: any[]): ItemAdditionError[] {
    let errors: ItemAdditionError[] = [];

    const notDuplicates = [];
    const indexDuplicates = [];

    offers.forEach(((offer, index) =>{
      if(notDuplicates.find((element) => element.barcode === offer.barcode && element.offerType === offer.offerType)) {
        indexDuplicates.push(index);
      } else {
        notDuplicates.push(offer);
      }
    }));

    indexDuplicates.forEach(position => {
      errors.push(this.errorFactory(
        offers[position],
        position,
        1,
        DUPLICATED_OFFER,
        'Oferta duplicada para o mesmo produto.',
      ));
    });

    return errors;
  }

  private validateNumber(
    item: OfferByCSV,
    fields: string[],
    line: number,
    requiredFields: string[] = null,
  ): ItemAdditionError[] | undefined {

    const numberValidationErrors: ItemAdditionError[] = [];

    fields.forEach((field) => {
      if (item[field] === '' || item[field] === undefined) return;
      if(field.includes('Quantity') || field.includes('X') || field.includes('Y')) {
        if(Number(item[field]) <= 1 || !Number.isInteger(Number(item[field]))) {
          numberValidationErrors.push(this.errorFactory(
            item,
            line,
            requiredFields.findIndex((v) => v === field) + 1,
            INVALID_QUANTITY,
            `Campo ${this.FieldTranslator[field]} com valor inválido.`,
          ));
        }
      }
      if(field.includes('Price')) {
        if(Number(item[field]) <= 0) {
          numberValidationErrors.push(this.errorFactory(
            item,
            line,
            requiredFields.findIndex((v) => v === field) + 1,
            INVALID_PRICE,
            `Campo ${this.FieldTranslator[field]} com valor inválido.`,
          ));
        }
      }
    });

    return numberValidationErrors;
  }

  private validateTakepayOffer(
    item: OfferByCSV,
    line: number,
  ): ItemAdditionError[] | undefined {
    {

      const takepayQuantityError = (item['takepayX'] <= item['takepayY']) ?
      [ this.errorFactory(
        item,
        line,
        this.offersField.findIndex((v) => v === 'takepayX') + 1,
        TAKEPAY_INVALID_QUANTITIES,
        'O campo Leve X deve possuir um valor maior que o campo Leve Y.',
        ) ] :
        undefined;

      return takepayQuantityError;
    }
  }

  private validateIfColumnIsNull(
    item: ItemByCSV,
    field: string,
    i: number,
    requiredFields: string[],
  ): ItemAdditionError | undefined {
    if (item[field] === '' || item[field] == undefined)
      return this.errorFactory(
        item,
        i,
        requiredFields.findIndex((v) => v === field) + 1,
        REQUIRED_FIELD_ERROR,
        `Campo ${this.FieldTranslator[field]} sem valor ou com valor incorreto.`,
      );
  }

  private validateSubCategoryCategoryRelation(
    prod: ProductByCSV,
    i: number,
  ): Observable<ItemAdditionError | undefined> {
    const listCategories = this.productsService.getCategoriesSubscription();

    if (listCategories instanceof Observable) {
      return listCategories.pipe(
        map((value) => {
          if (
            value
              .find((c) => c.id === prod.categoryId)
              .subCategories.find((s) => s.id === prod.subCategoryId)
          ) {
            return;
          }
          return this.errorFactory(
            prod,
            i,
            null,
            CATEGORY_SUBCATEGORY_NOT_RELETED_ERROR,
            `Categoria ${prod.category} e subcategoria ${prod.subCategory} não relacionadas.`,
          );
        }),
      );
    }

    if (
      listCategories
        .find((c) => c.id === prod.categoryId)
        .subCategories.find((s) => s.id === prod.subCategoryId)
    ) {
      return of(undefined);
    }

    return of(
      this.errorFactory(
        prod,
        i,
        null,
        CATEGORY_SUBCATEGORY_NOT_RELETED_ERROR,
        `Categoria ${prod.category} e subcategoria ${prod.subCategory} não relacionadas.`,
      ),
    );
  }

  private storeAsyncValidation(
    block: PartitionItemByCSV,
    blockIndex: number,
    blockSize: number,
  ): Observable<ItemAdditionError[] | undefined> {
    return this.storesService
      .validateStoreBatchSubscription({
        accessToken: this.ACCESS_TOKEN,
        validationData: block.items.map((store: StoreByCSV) => {
          return {
            cityName: store.city,
            cnpj: store.cnpj,
            name: store.name,
            stateName: store.state,
            storeTypeName:
              this.invertedStoreTypeTranslation[store.storeType] ??
              store.storeType,
          };
        }),
      })
      .pipe(
        map(
          ({
            data: {
              validateStoreBatch: { validatedStores, authorizationKey },
            },
          }) => {
            if (!!validatedStores) {
              this.asyncValidationProgress.progress.next(
                100 / this.asyncValidationProgress.totalSize,
              );
            }

            block.authorizationKey = authorizationKey;

            return block.items
              .map((store, i) => ({
                ...store,
                err: validatedStores[i].errors,
              }))
              .map((store, i) => {
                const storeIndex = blockIndex * blockSize + i;
                return store.err && store.err.length > 0
                  ? this.errorFactory(
                      store,
                      storeIndex,
                      null,
                      store.err[0].code,
                      store.err[0].message,
                    )
                  : undefined;
              });
          },
        ),
        retryWhen((error) => {
          return error.pipe(
            tap(() => {
              if (!this.abortUpload && this.validationAttempts > 0) {
                this.snackbarService.errorSnackbar(
                  `Erro ao enviar a partição ${blockIndex}. Tentando novamente. Tentativas: ${this.validationAttempts}`,
                );
                this.validationAttempts--;
                return;
              }
              this.asyncValidationProgress.progress.next(100);
              throw new Error('Erro na validação da partição.');
            }),
            delay(2000),
          );
        }),
        catchError((error) => {
          return throwError(new Error('Erro na validação da partição.'));
        }),
      );
  }

  private barcodeAsyncValidation(
    block: ProductByCSV[] | OfferByCSV[] | PartitionItemByCSV[],
    blockIndex: number,
    blockSize: number,
  ): Observable<ItemAdditionError[] | undefined> {
    // passado como zero pois ja havera o erro de barcode obrigatorio e nao precisa de mais esse erro
    const productBarcodes = block.map((prod) =>
      !prod.barcode ? '0' : prod.barcode,
    );

    return this.productsService
      .validateProductsSubscription({
        accessToken: this.ACCESS_TOKEN,
        productBarcodes,
      })
      .pipe(
        map(
          ({
            data: {
              validateProducts: { products, authorizationToken },
            },
          }) => {
            this.asyncValidationProgress.progress.next(
              100 / this.asyncValidationProgress.totalSize,
            );

            this.authorizationProductKey = authorizationToken;

            return ('category' in block[0]) ?
            block
              .map((prod, i) => {
                const exist = products?.find(({ productBarcode }) => productBarcode === prod.barcode);
                const prodIndex = blockIndex * blockSize + i;
                return exist
                  ? this.errorFactory(
                      prod,
                      prodIndex,
                      1,
                      BARCODE_IN_USE_ERROR,
                      'Código de barras já em uso.',
                    )
                  : undefined;
              }) :
              block
              .map((prod, i) => {
                const exist = products?.find(({ productBarcode }) => productBarcode === prod.barcode);
                const prodIndex = blockIndex * blockSize + i;
                return !exist
                  ? this.errorFactory(
                      prod,
                      prodIndex,
                      null,
                      NON_EXISTENT_PRODUCT,
                      'Produto não encontrado em nossa base de dados.',
                    )
                  : undefined;
              });
          },
        ),
        retryWhen((error) => {
          return error.pipe(
            tap(() => {
              if (!this.abortUpload && this.validationAttempts > 0) {
                this.snackbarService.errorSnackbar(
                  `Erro ao enviar a partição ${blockIndex}. Tentando novamente. Tentativas: ${this.validationAttempts}`,
                );
                this.validationAttempts--;
                return;
              }
              this.asyncValidationProgress.progress.next(100);
              throw new Error('Erro na validação da partição.');
            }),
            delay(1000),
          );
        }),
        catchError((error) => {
          return throwError(new Error('Erro na validação da partição.'));
        }),
      );
  }

  private duplicateOfferAsyncValidation(
    block: PartitionItemByCSV,
    blockIndex: number,
    blockSize: number,
  ): Observable<ItemAdditionError[] | undefined> {
    const validationErrors: ItemAdditionError[] = [];
    const storeId = this.route.snapshot.data.store?.id;

    let offersToSend = [];

    block.items.forEach((offer) => {
      offersToSend.push({
        ...offer,
        storeId,
      });
    });

    return this.offerService.validateOfferBatch(offersToSend).pipe(
      map(({ data: { validateOfferBatch: { authorizationKey, invalidatedOffers, validatedOffers } } }) => {

        if (!!invalidatedOffers || !!validatedOffers) {
          this.asyncValidationProgress.progress.next(
            100 / this.asyncValidationProgress.totalSize,
          );
        }

        block.authorizationKey = authorizationKey;

        block.items.forEach((offer, i) => {
          for(let j = 0; j < invalidatedOffers.length; j++) {
            if(offer['barcode'] === invalidatedOffers[j].barcode &&
            this.offerService.offerTypeTranslator(offer['offerType']) === invalidatedOffers[j].offerTypeValue) {
              validationErrors.push(this.errorFactory(
                offer,
                i,
                this.offersField.findIndex((v) => v === 'barcode') + 1,
                invalidatedOffers[j].error.code,
                invalidatedOffers[j].error.message,
              ));
              break;
            }
          }
        });

        return validationErrors.filter((err) => err);

      }),
      retryWhen((error) => {
        return error.pipe(
          tap(() => {
            if (!this.abortUpload && this.validationAttempts > 0) {
              this.snackbarService.errorSnackbar(
                `Erro ao enviar a partição ${blockIndex}. Tentando novamente. Tentativas: ${this.validationAttempts}`,
              );
              this.validationAttempts--;
              return;
            }
            this.asyncValidationProgress.progress.next(100);
            throw new Error('Erro na validação da partição.');
          }),
          delay(1000),
        );
      }),
      catchError((error) => {
        return throwError(new Error('Erro na validação da partição.'));
      }),
    );

  }

  private errorFactory(
    item: ItemByCSV,
    line: number,
    column: number = null,
    code: number,
    message: string,
  ): ItemAdditionError {
    switch (this.targetType) {
      case 'products':
        return {
          name: item.name,
          main: (item as ProductByCSV).barcode,
          position:
            column !== null ? `${numToSSColumn(column)}/${line+2}` : `*/${line+2}`,
          code: `Cód. ${code}`,
          message,
        };
      case 'stores':
        return {
          name: item.name,
          main: this.concatAddress(item as StoreByCSV),
          position:
            column !== null ? `${numToSSColumn(column)}/${line+2}` : `*/${line+2}`,
          code: `Cód. ${code}`,
          message,
        };
      case 'offers':
        return {
          name: (item as OfferByCSV).barcode || 'Barcode indefinido',
          main: (item as OfferByCSV).offerType || 'Tipo de oferta indefinido',
          position: column !== null ? `${numToSSColumn(column)}/${line+2}` : `*/${line+2}`,
          code: `Cód. ${code}`,
          message,
        };
      default:
        return assertNever(this.targetType);
    }
  }

  private swapRecordsKeyValues<T extends Record<string, S>, S extends string>(
    obj: T,
  ): { [K in keyof T as T[K]]: K } {
    const inverted = {} as any;
    Object.entries(obj).forEach(([ key, value ]) => {
      inverted[value] = key;
    });
    return inverted;
  }

  private flatten<T>(matrice: T[][]): T[] {
    return matrice.reduce((acc, errs) => acc.concat(errs), []);
  }

  private concatAddress(item: StoreByCSV): string {
    return `${item.street}, ${item.number} - ${item.district}, ${item.city}-${item.state}`;
  }
}
