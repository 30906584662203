<ng-template #appbarProducts>
  <div class="filters-section">
    <div #content [class.content]="content.children.length !== 0">
      <ng-content></ng-content>
    </div>
    <div class="search-bar">
      <app-search-bar-lg
        (triggerSearch)="onSearch($event)"
        (resetSearch)="resetSearch()"
        [searchInput]="inputSearch"
      ></app-search-bar-lg>
    </div>

    <div class="categories d-flex justify-content-between">
      <div style="display: inline-flex;">
        <app-dropdown-categories
          class="me-5"
          [selected]="selectedCategory"
          [options]="categories"
          style="width: 9.75rem"
        >
        </app-dropdown-categories>

        <app-dropdown
          [options]="subcategories"
          [selected]="selectedSubcategories"
          [hasSelections]="true"
          type="subcategories"
          [disabled]="!selectedCategory.value"
        >
        </app-dropdown>
      </div>
      <div>
        <p
          *ngIf="productsLength === 0 && componentTitle === 'ofertas'"
          class="show-all"
          (click)="onShowAllOffers()"
        >
          Mostrar todas
        </p>
      </div>

      <div
        class="head d-flex justify-content-end"
        *ngIf="!noProductsFound && productsLength > 0"
      >
        <p
          *ngIf="!isSelectOn"
          matRipple
          class="font-subtitle-medium cursor-pointer dark-btn itens-selection"
          tabindex="0"
          (click)="changeSelectOn()"
          data-cy="button-select-toggle"
        >
          Selecionar itens
        </p>
        <div class="d-flex" *ngIf="isSelectOn">
          <p
            matRipple
            *ngIf="!isSelectAll"
            class="font-subtitle-medium cursor-pointer dark-btn itens-selection"
            tabindex="0"
            (click)="onSelectAll()"
            data-cy="button-select-all"
          >
            Selecionar tudo
          </p>
          <p
            matRipple
            *ngIf="isSelectAll"
            class="font-subtitle-medium cursor-pointer dark-btn itens-selection"
            tabindex="0"
            (click)="onUnselectAll()"
            data-cy="button-select-all"
          >
            Desselecionar tudo
          </p>
          <hr class="vertical" />
          <p
            matRipple
            class="font-subtitle-medium cursor-pointer dark-btn itens-selection"
            tabindex="0"
            (click)="onCancelSelect()"
            data-cy="button-select-cancel"
          >
            Cancelar
          </p>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-between sort-by"
      *ngIf="!noProductsFound && productsLength > 0"
    >
      <p [style]="{ visibility: !!inputSearch ? null : 'hidden' }">
        {{ productsLength }} resultados encontrados para
        <span>"{{ inputSearch }}"</span>
      </p>
      <div class="d-flex" *ngIf="hasFilter">
        <p>Classificar por</p>
        <app-sort-by [(selected)]="sortType" (selectedChange)="onSort($event)">
        </app-sort-by>
      </div>
    </div>
  </div>
</ng-template>
