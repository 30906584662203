import { Regex } from '@shared/utils/regex';
import { FormValidator } from '@app/core/validators/formValidator';
import { handleFormError, handleErrors } from '@shared/utils/handleErrors';
import {
  RequestPasswordRecoveryInput,
  Exact,
} from '@shared/graphql/users/users-graphql';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserAccountService } from '@app/core/services/user-account.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [ './forgot-password.component.scss' ],
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('snackbar', { read: ViewContainerRef, static: true })
  snackbar: ViewContainerRef;

  resetPasswordForm: FormGroup;
  loading = false;

  handleFormError: any;

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private snackbarService: SnackbarService,
    private userAccountService: UserAccountService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.handleFormError = handleFormError;
    this.resetPasswordForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          FormValidator.patternValidator(Regex.emailRegex, {
            emailWithoutSpecialCharacters: true,
          }),
        ]),
      ],
    });
    this.snackbarService.setRootViewContainerRef(this.snackbar);
  }

  async handleClickRedefinedPassword(): Promise<void> {
    this.loading = true;

    const params: Exact<{ params: RequestPasswordRecoveryInput }> = {
      params: { emailObject: { email: this.resetPasswordForm.get('email').value, permissionLevel: 'BACKOFFICE_USER' } },
    };

    try {

      await this.userAccountService.sendEmail(params).toPromise();

      this.router.navigate([ 'conta/verificar-email' ], {
        queryParams: {
          email: this.resetPasswordForm.get('email').value,
        },
        queryParamsHandling: 'merge',
      });

    } catch (err) {
      const error = handleErrors(err);
      if (error.canRetry)
        this.snackbarService.errorSnackbar('Ocorreu um erro. Tente novamente.');
      else this.snackbarService.errorSnackbar(error.message);
    }

    this.loading = false;
  }

  handleDisabledButton(): boolean {
    return this.resetPasswordForm.status !== 'VALID';
  }
}
