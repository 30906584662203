import { Component, Input } from '@angular/core';
import { StoreThemeService } from '@services/storeTheme.service';

@Component({
  selector: 'app-infinite-loading-item',
  templateUrl: './infinite-loading-item.component.html',
  styleUrls: [ './infinite-loading-item.component.scss' ],
})
export class InfiniteLoadingItemComponent {

  @Input() show = false;

  storedTheme: string;

  constructor(
    private themeService: StoreThemeService,
  ) {
    this.themeService.themeValue.subscribe((nextValue) => {
      this.storedTheme = nextValue;
    });
  }

}
