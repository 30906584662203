import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { SnackbarAlertComponent } from '@shared/components/snackbar-alert/snackbar-alert.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private rootViewContainer: ViewContainerRef;
  private snackInstance: SnackbarAlertComponent;

  /**
   * @param viewContainerRef Referência do elemento DOM (container)
   */
  setRootViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.rootViewContainer = viewContainerRef;
  }

  /**
   * Icone e texto verde
   * @param message Mensagem que será exibida
   */
  successSnackbar = (message: string): void => {
    const component = this.rootViewContainer.createComponent(
      SnackbarAlertComponent
    );
    this.snackInstance = component.instance;
    this.snackInstance.message = message;
    this.snackInstance.type = 'success';
    this.rootViewContainer.insert(component.hostView);
    this.verifySameTipeInstances();
  };

  /**
   * Texto vermelho
   * @param message Mensagem que será exibida
   * @param closeTime tempo em que o snackbar irá ficar na tela (0 = infinito)
   */
  errorSnackbar = (message: string, closeTime: number = null): void => {
    const component = this.rootViewContainer.createComponent(
      SnackbarAlertComponent
    );
    this.snackInstance = component.instance;
    this.snackInstance.message = message;
    this.snackInstance.type = 'error';
    if (!!closeTime) this.snackInstance.closeTime = closeTime;
    this.rootViewContainer.insert(component.hostView);
    this.verifySameTipeInstances();
  };

  /**
   * Texto vermelho e ícone clicável executar a callback
   * @param message Mensagem que será exibida
   * @param cb Função que será executada
   */
  retry = (message: string, cb: () => void): void => {
    const component = this.rootViewContainer.createComponent(
      SnackbarAlertComponent
    );
    this.snackInstance = component.instance;
    this.snackInstance.message = message;
    this.snackInstance.type = 'retry';
    this.snackInstance.cb = cb;
    this.snackInstance.closeTime = 10000;
    this.rootViewContainer.insert(component.hostView);
    this.verifySameTipeInstances();
  };

  closeSnackBar(): void {
    if (this.snackInstance) this.snackInstance.handleClose();
  }

  verifySameTipeInstances(): void {
    if (this.rootViewContainer.length > 1) {
      this.rootViewContainer.remove(0);
    }
  }
}
