import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: [ './input-checkbox.component.scss' ],
})
export class InputCheckboxComponent {
  @Input() selected = false;

  @Input() checkedTrueImage = 'assets/dark-theme/icon-check-true.svg';
  @Input() checkedFalseImage = 'assets/dark-theme/radio_button_unchecked.svg';
  @Input() rectangle = false;

}
