<ng-container>
  <button
    matRipple
    class="btn dark-btn btn-secondary d-flex"
    type="button"
    id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    [ngClass]="{'selected': !!selected.value}"
    data-cy="button-dropdown-categories"
    [disabled]="disabled"
    (click)="selected.markAsTouched()"
    >

    <div *ngIf="!selected.value" class="category-empty">
      <img class="category-empty-image" src="assets/dark-theme/empty-category.svg" />
    </div>
    <p *ngIf="!selected.value">{{label}}</p>
    <app-category-item
      *ngIf="!!selected.value"
      class="category-item"
      [item]='getCategoryById(selected.value)'
      [selected]="true"></app-category-item>
    <mat-icon aria-hidden="false" aria-label="Expandir menu">expand_more</mat-icon>
  </button>

  <div class="dropdown-menu dropdown-menu-start" aria-labelledby="dropdownMenuButton1" *ngIf="options && options.length !== 0">
    <ul class="row">
      <li class="col-6" *ngFor="let option of options" (click)="selectCategory(option)"
        [ngClass]="{'selected': selected.value === option.id}" data-cy="dropdown-category-item">
        <app-category-item [item]="option"></app-category-item>
      </li>
    </ul>
  </div>
  <mat-error
    *ngIf="hasError"
    class="error-message font-subtitle-medium-14"
    >{{ error }}</mat-error
  >
</ng-container>
