import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StoreThemeService } from '@services/storeTheme.service';
import { SubCategoryWSelected } from '@shared/interfaces/backoffice/products/subcategory';


@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: [ './select-item.component.scss' ],
})
export class SelectItemComponent {
  @Input() item: SubCategoryWSelected;
  @Input() selected: boolean;
  @Output() selectedChange = new EventEmitter<boolean>();
  storedTheme: string;

  constructor(
    private themeService: StoreThemeService,
  ) {
    this.themeService.themeValue.subscribe((nextValue) => {
      this.storedTheme = nextValue;
    });
  }

}
