import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Renderer2, Inject, OnInit, Input, AfterContentInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appHighlight]',
})
export class HighlightDirective implements AfterContentInit, OnChanges  {
  @Input('appHighlight') highlightText = '';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {

  }

  ngAfterContentInit(): void {
    if(this.highlightText === '')
      return;

    let text = this.elementRef.nativeElement.innerText;
    let textToHighlight = this.highlightText.replace(/[.*+?^${}()|[\]\\]/g,'\\$&');
    let pattern = new RegExp(`${textToHighlight}`, 'gi');

    const newInnerHTML = (text as string).replace(pattern, match => `<span style="color: #f69116; padding: 0;">${match}</span>`);
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', newInnerHTML);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.highlightText?.previousValue === undefined)
      return;

    let text = this.elementRef.nativeElement.innerText;
    let textToHighlight = this.highlightText.replace(/[.*+?^${}()|[\]\\]/g,'\\$&');
    let pattern = new RegExp(`${textToHighlight}`, 'gi');


    if(changes.highlightText.currentValue === '') {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', text);
    } else {
      const newInnerHTML = (text as string).replace(pattern, match => `<span style="color: #f69116; padding: 0;">${match}</span>`);
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', newInnerHTML);
    }
  }

}
