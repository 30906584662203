import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidator {
  static equalsTo(field: string, matchingField: string) {
    return (formGroup: FormGroup): { equalsTo: false } => {
      const control = formGroup.controls[field];
      const matchingControl = formGroup.controls[matchingField];

      if (matchingControl.errors && !matchingControl.errors.equalsTo) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ equalsTo: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  static minCommaValidator(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control || !control.value || !min) {
            return null; // don't validate empty values to allow optional controls
        }
        const value =  typeof control.value == 'number' ?
          control.value :
          parseFloat((control.value as string).replace(/,/, '.'));

        // Controls with NaN values after parsing should be treated as not having a
        // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
        return !isNaN(value) && value < min ? { 'min': { 'min': min, 'actual': control.value } } : null;
    };
  }

  static getError(validatorName: string, validatorValue?: any): any {
    const config = {
      required: 'Esse campo não pode estar vazio.',
      name: 'Insira nome e sobrenome válidos!',
      email: 'Insira um email válido!',
      minlength: 'Use 8 ou mais caracteres para sua senha.',
      equalsTo: 'As senhas não são iguais. Tente novamente',
      pattern: 'Insira uma senha válida!',
      hasNumber: 'A senha deve conter pelo menos um número',
      hasCapitalCase: 'A senha deve conter pelo menos uma letra maiúscula',
      hasSmallCase: 'A senha deve conter pelo menos uma letra minúscula',
      hasSpecialCharacters: 'A senha deve conter pelo menos um caracter especial',
      emailWithoutSpecialCharacters: 'Insira um email válido!',
      min: `O valor deve ser maior ou igual a ${validatorValue?.min}.`,
      payBiggerThanTake: 'Informe um valor menor que o X.',
      offerTypeNotCompleted: 'Por favor, preencha este campo.',
      password: 'A senha não atende todos os requisitos.',
      price: 'Insira um preço válido.',
      hasSpace: 'A senha não deve conter espaço no começo ou final',
      startBiggerThanEnd: 'A data de término deve ser maior que a data de início.',
      invalidPassword: 'Senha inválida.',
    };

    return config[validatorName];
  }
}
