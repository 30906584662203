import { SnackbarService } from '@services/snackbar.service';
import { Subscription, Observable, BehaviorSubject, throwError } from 'rxjs';
import { tap, catchError, finalize, delay, filter } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { AppbarChanges } from '@app/core/services/appbarChanges.service';
import { AppbarButton } from '@app/shared/interfaces/backoffice/appbar';
import { CategoryWAsset } from '@app/shared/interfaces/backoffice/products/category';
import { SubCategoryWSelected } from '@app/shared/interfaces/backoffice/products/subcategory';
import { CreateAppbarButton } from '@app/shared/utils/createAppbarButton';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ProductsService } from '@app/core/services/products.service';
import { PopupService } from '@app/core/services/popup.service';

@UntilDestroy()
@Component({
  selector: 'app-appbar-products',
  templateUrl: './appbar-products.component.html',
  styleUrls: [ './appbar-products.component.scss' ],
})
export class AppbarProductsComponent implements OnInit, OnChanges {
  @ViewChild('appbarProducts', { static: true })
  appbarTemplate: TemplateRef<any>;

  @Output() triggerSearch = new EventEmitter<string>();
  @Output() triggerResetSearch = new EventEmitter<string>();
  @Output() triggerChangedCategory = new EventEmitter();

  @Output() triggerIsSelectOn = new EventEmitter<boolean>();
  @Output() triggerChangedSubcategories = new EventEmitter();
  @Output() triggerOnDeleteSelected = new EventEmitter();

  @Output() triggerSelectAll = new EventEmitter<boolean>();
  @Output() triggerUnselectAll = new EventEmitter<boolean>();

  @Output() triggerOnSort = new EventEmitter<string>();
  @Output() triggerOnShowAllOffers = new EventEmitter();

  @Input() inputSearch = '';
  @Input() clearCategoryAndSubcategory: Observable<void>;

  @Input() noProductsFound: boolean;
  @Input() productsLength: number;
  @Input() selectedListLength: number;
  @Input() hasFilter: boolean;
  @Input() componentTitle: 'produtos' | 'ofertas' = 'produtos';
  @Input() sortType: string;
  @Input() categories: CategoryWAsset[] = [];

  selectedCategory = new FormControl(null);
  selectedSubcategories = new FormControl([]);

  subcategories: SubCategoryWSelected[] = [];

  params$: Subscription;

  isSelectOn = false;

  isCategoryLoading = new BehaviorSubject(false);

  addButton: AppbarButton;
  deleteButton: AppbarButton;

  isSelectAll = false;

  constructor(
    private route: ActivatedRoute,
    private appbarChanges: AppbarChanges,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private productsService: ProductsService,
    private snackbarService: SnackbarService,
    private popupService: PopupService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedListLength']) {
      this.changeAppbar();
    }
  }

  changeAppbar(): void {
    if (this.isSelectOn) {
      this.appbarChanges.setAppbar(
        `${this.selectedListLength} selecionado${
          this.selectedListLength > 1 ? 's' : ''
        }`,
        [ this.deleteButton ],
        this.appbarTemplate,
      );
    } else {
      this.appbarChanges.setAppbar(
        `${
          this.componentTitle[0].toUpperCase() +
          this.componentTitle.slice(1).toLowerCase()
        }`,
        [ this.addButton ],
        this.appbarTemplate,
      );
    }
  }

  ngOnInit(): void {
    const { c, subc, search } = this.route.snapshot.queryParams;

    if(c) this.selectedCategory.setValue(c);
    if(subc) this.selectedSubcategories.setValue(subc);
    if(search) this.inputSearch = search;

    this.loadInitialButtons();
    setTimeout(()=> this.setCategory());
    this.setSubcategories();

    this.selectedCategory.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((category) => {
          if(category) this.selectCategory(category);
          else this.selectedSubcategories.reset();
          this.breadcrumbService.setData('category', category);
          this.triggerChangedCategory.emit({
            category,
            searchInput: this.inputSearch,
          });
        }),
      )
      .subscribe();

    this.selectedSubcategories.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((subcategories) => {
          this.triggerChangedSubcategories.emit({
            subcategories,
            searchInput: this.inputSearch,
          });
        }),
      )
    .subscribe();

    this.clearCategoryAndSubcategory
    .pipe(
      untilDestroyed(this),
      tap(() => {
        this.onResetSearch(true);
      }),
    )

    .subscribe();

  }

  onResetSearch(byBreadcrumb: boolean = false): void {
    this.inputSearch = '';
    this.selectedCategory.setValue('');
    this.selectedSubcategories.setValue([]);
    this.selectedSubcategories.updateValueAndValidity();
    this.subcategories = [];
    this.changeAppbar();
    if(!byBreadcrumb) this.triggerResetSearch.emit();

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: null,
        c: null,
        subc: null,
      },
      queryParamsHandling: 'merge',
    });

  }

  onSearch(newInput: any): void {

    const queryParams = {
      search: newInput,
      c: this.selectedCategory.value,
      subc: this.selectedSubcategories.value,
    };

    this.router.navigate([ '.' ], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });

    this.inputSearch = newInput;
    this.triggerUnselectAll.emit(true);
    this.isSelectAll = false;
    this.isSelectOn = false;
    this.changeAppbar();
    this.triggerIsSelectOn.emit(this.isSelectOn);
    this.triggerSearch.emit(newInput);
  }

  resetSearch(): void {
    this.inputSearch = '';

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: null,
        c: this.selectedCategory.value,
        subc: this.selectedSubcategories.value,
      },
      queryParamsHandling: 'merge',
    });

    this.triggerUnselectAll.emit(true);
    this.isSelectAll = false;
    this.isSelectOn = false;
    this.changeAppbar();
    this.triggerIsSelectOn.emit(this.isSelectOn);
  }

  changeSelectOn(): void {
    this.isSelectOn = !this.isSelectOn;
    this.triggerIsSelectOn.emit(this.isSelectOn);
    this.changeAppbar();
  }

  onSort(type: string): void {
    this.sortType = type;
    this.triggerOnSort.emit(this.sortType);
  }

  onSelectAll(): void {
    this.isSelectAll = true;
    this.triggerSelectAll.emit(true);
  }

  onUnselectAll(): void {
    this.isSelectAll = false;
    this.triggerUnselectAll.emit(true);
  }

  onShowAllOffers(): void{
    this.triggerOnShowAllOffers.emit();
  }

  /* TODO: Generalizar método para produtos e ofertas */
  navigateToListProducts = async (): Promise<void> => {
    if (this.route.snapshot.data.saveQuery) {
      this.breadcrumbService.setData(
        this.route.snapshot.data.saveQuery,
        this.route.snapshot.queryParams.search,
      );
    }
    this.router.navigate([ 'adicionar' ], { relativeTo: this.route });
  };

  onDeleteConfirmSelected(): any {
    this.popupService.needToConfirm(
      {
        title: this.componentTitle === 'produtos' ? 'Excluir produtos?' : 'Excluir ofertas?',
        description: `Você poderá adicionar mais ${this.componentTitle} a qualquer momento.`,
        buttonTitle: 'Excluir',
        buttonIcon: 'delete',
      }, this.onDeleteSelected.bind(this));
  }

  onDeleteSelected(): any {
    this.triggerOnDeleteSelected.emit(true);
    this.onCancelSelect();
  }

  onCancelSelect(): void {
    this.triggerUnselectAll.emit(true);
    this.isSelectAll = false;
    this.changeSelectOn();
  }

  loadInitialButtons(): void {
    let appbarButton =
      this.componentTitle == 'produtos' ? 'Novo Produto' : 'Nova Oferta';

    this.addButton = CreateAppbarButton.adicionarButton(
      appbarButton,
      this.navigateToListProducts,
    );

    this.deleteButton = CreateAppbarButton.deletarButton(
      'Apagar itens',
      this.onDeleteConfirmSelected.bind(this),
      '#f69116',
    );
    this.changeAppbar();
  }

  selectCategory(categoryId: string): void {
    this.categories.map((cat) => {
      if (cat.id === categoryId) {
        if (
          this.selectedCategory.value !== categoryId
        ) {
          this.selectedCategory.setValue(categoryId);
          this.selectedSubcategories.reset();
        }
        this.subcategories = cat.subCategories.map((option) => ({
          ...option,
          selected: false,
        }));
      }
    });
  }

  private async setSubcategories(): Promise<void> {
    try {
      const { subc } = this.route.snapshot.queryParams;
      if(this.selectedCategory.value && subc) this.selectedSubcategories.setValue(subc);
    } catch (err) {
      throw new Error(err);
    }
  }

  private async setCategory(): Promise<void> {
    try {
      const { c } = this.route.snapshot.queryParams;
      if(c) this.selectCategory(c);
      this.isCategoryLoading.next(false);

    } catch (err) {
      throw new Error(err);
    }
  }
}
