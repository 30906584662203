import { ValidateOfferBatchInput } from './../../shared/graphql/stores/stores-graphql';
import {
  ValidateOfferBatchGQL,
  ValidateOfferBatchQuery,
} from '@shared/graphql/stores/stores-graphql';
import { MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FetchResult, ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import {
  CreateOfferGQL,
  CreateOfferMutation,
  CreateOfferInput,
  CreateOfferBatchGQL,
  CreateOfferBatchMutation,
  CreateOfferBatchInput,
  UpdateOfferGQL,
  DeleteOfferBatchGQL,
  DeleteOfferBatchInput,
  UpdateOfferMutation,
  UpdateOfferInput,
  DeleteOfferBatchMutation,
  DeleteOfferInput,
  DeleteOfferMutation,
  DeleteOfferGQL,
} from '@shared/graphql/stores/stores-graphql';
import { OfferByCSV } from '@app/shared/interfaces/backoffice/offers/offers';
import { UserAccountService } from './user-account.service';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  private ACCESS_TOKEN: string;

  constructor(
    private userAccountService: UserAccountService,
    private createBatchOfferGQL: CreateOfferBatchGQL,
    private createOfferGQL: CreateOfferGQL,
    private deleteOfferBatchGQL: DeleteOfferBatchGQL,
    private updateOfferGQL: UpdateOfferGQL,
    private deleteSingleOfferGQL: DeleteOfferGQL,
    private validateOfferBatchGQL: ValidateOfferBatchGQL,
  ) {
    this.ACCESS_TOKEN = this.userAccountService.userAccessToken;
  }

  addSingleOffer(
    params: CreateOfferInput,
  ): Observable<
    FetchResult<CreateOfferMutation, Record<string, any>, Record<string, any>>
  > {
    return this.createOfferGQL.mutate({ params });
  }

  validateOfferBatch(
    params,
  ): Observable<ApolloQueryResult<ValidateOfferBatchQuery>> {
    const offerBatch: ValidateOfferBatchInput = {
      accessToken: this.ACCESS_TOKEN,
      offers: params.map((offer) => {
        const date = this.formatDate(offer);
        return {
          barcode: offer.barcode,
          offerTypeValue: this.offerTypeTranslator(offer.offerType),
          price:
            offer.unityPrice ||
            offer.fixedPrice ||
            offer.fromPrice ||
            offer.takepayPrice,
          pay: Number(offer.takepayY) || null,
          take: Number(
            offer.takepayX || offer.fixedQuantity || offer.fromQuantity || 1,
          ),
          saleTypeValue:
            offer.offerType === 'Unitária' ? 'RETAIL' : 'WHOLESALE',
          storeId: offer.storeId,
          initialTime: date[0],
          endingTime: date[1],
        };
      }),
    };


    return this.validateOfferBatchGQL.fetch({ params: offerBatch });
  }

  createOfferBatch(
    authorizationKey: string,
    params: OfferByCSV[],
  ): Observable<
    FetchResult<
      CreateOfferBatchMutation,
      Record<string, any>,
      Record<string, any>
    >
  > {
    const offerBatch: CreateOfferBatchInput = {
      authorizationKey: authorizationKey,
      accessToken: this.ACCESS_TOKEN,
      offers: params.map((offer) => {
        const date = this.formatDate(offer);
        return {
          offerTypeValue: this.offerTypeTranslator(offer.offerType),
          price:
            offer.unityPrice ||
            offer.fixedPrice ||
            offer.fromPrice ||
            offer.takepayPrice,
          pay: Number(offer.takepayY) || null,
          take: Number(
            offer.takepayX || offer.fixedQuantity || offer.fromQuantity || 1,
          ),
          productId: offer.productId,
          saleTypeValue:
            offer.offerType === 'Unitária' ? 'RETAIL' : 'WHOLESALE',
          storeId: offer.storeId,
          cityId: offer.cityId,
          initialTime: date[0],
          endingTime: date[1],
        };
      }),
    };

    return this.createBatchOfferGQL.mutate({ params: offerBatch });
  }

  updateOffer(
    params: UpdateOfferInput,
  ): Observable<
    FetchResult<UpdateOfferMutation, Record<string, any>, Record<string, any>>
  > {
    return this.updateOfferGQL.mutate({ params });
  }

  deleteOfferBatch(
    params: DeleteOfferBatchInput,
  ): Observable<MutationResult<DeleteOfferBatchMutation>> {
    return this.deleteOfferBatchGQL.mutate({ params });
  }

  deleteSingleOffer(
    params: DeleteOfferInput,
  ): Observable<MutationResult<DeleteOfferMutation>> {
    return this.deleteSingleOfferGQL.mutate({ params });
  }

  offerTypeTranslator(offerTypeFromCVS: string): string {
    switch (offerTypeFromCVS) {
      case 'Unitária':
        return 'UNIT';
      case 'Comprando X':
        return 'FIXED';
      case 'A partir de':
        return 'FROM';
      case 'Leve X Pague Y':
        return 'TAKEPAY';

      default:
        return null;
    }
  }

  private formatDate(offer: OfferByCSV): string[] {
    const initTime = offer.initialTime.split('/');
    const endTime = offer.endingTime.split('/');
    const initTimeFormatted = new Date(initTime[2] + '-' + initTime[1] + '-' + initTime[0] + 'T03:00:00.000Z');
    const endTimeFormatted = new Date(endTime[2] + '-' + endTime[1] + '-' + endTime[0] + 'T03:00:00.000Z');

    return [ initTimeFormatted.toISOString(), endTimeFormatted.toISOString() ];
  }
}
