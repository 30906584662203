import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';


@Component({
  selector: 'app-form-select-input',
  templateUrl: './form-select-input.component.html',
  styleUrls: [ './form-select-input.component.scss' ],
})
export class FormSelectInputComponent implements OnInit {
  @Input() errorMsg: string;
  @Input() title: string;
  @Input() requirement = true;
  @Input() items: {
    id: string;
    name: string;
  }[];
  @Input() controlName: string;
  @Output() selectionChange = new EventEmitter<string>();
  currentControl: FormControl;

  constructor(private rootFromGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.currentControl = this.rootFromGroup.control.controls[
      this.controlName
    ] as FormControl;
  }

  onChange(): void {
    this.selectionChange.emit(this.currentControl.value);
  }
}
