import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-go-back',
  templateUrl: './to-go-back.component.html',
  styleUrls: [ './to-go-back.component.scss' ],
})
export class ToGoBackComponent {
  @Input() path: string;
}
