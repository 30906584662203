import { PartitionItemByCSV } from './../interfaces/backoffice/addBatch';
import { queueSlicer } from '@shared/utils/queueUtils';
import { Inject, Injectable } from '@angular/core';
import listStateCities, { StateCitiesLocal } from '@assets/statesCities';
import storeTypes, { StoreTypesLocal } from '@assets/storeTypes';
import { ProductsService } from '@services/products.service';
import { MeasureUnit } from '@shared/graphql/products/products-graphql';
import { ItemByCSV, TargetType } from '@shared/interfaces/backoffice/addBatch';
import { Category } from '@shared/interfaces/backoffice/products';
import { ProductByCSV } from '@shared/interfaces/backoffice/products/product';
import { StoreByCSV } from '@shared/interfaces/backoffice/stores/store';
import { assertNever } from '@shared/utils/assert';

import { OfferByCSV } from '../interfaces/backoffice/offers/offers';

const productsHeader = [
  'name',
  'barcode',
  'category',
  'subCategory',
  'measurementValue',
  'measurement',
  'description',
  'model',
  'brand',
];

const storesHeader = [
  'name',
  'storeType',
  'state',
  'city',
  'district',
  'street',
  'number',
  'complement',
  'cnpj',
];

// TODO: Definir depois que finalizar a planilha
const offersHeader = [
  'barcode',
  'offerType',
  'unityPrice',
  'fromQuantity',
  'fromPrice',
  'fixedQuantity',
  'fixedPrice',
  'takepayX',
  'takepayY',
  'takepayPrice',
  'initialTime',
  'endingTime',
];

@Injectable({
  providedIn: 'root',
})
export class ProcessCsvFileService {
  private headers: string[];
  itemQueueSliceSize = 100;
  numberOfItemsCSV: number;

  constructor(
    @Inject('targetType') private targetType: TargetType,
    private productsService: ProductsService,
  ) { }

  setHeaders(): ProcessCsvFileService {
    switch (this.targetType) {
      case 'products':
        this.headers = productsHeader;
        break;
      case 'stores':
        this.headers = storesHeader;
        break;
      case 'offers':
        this.headers = offersHeader;
        break;
      default:
        return assertNever(this.targetType);
    }

    return this;
  }

  getNumberOfItemsCSV(): number {
    return this.numberOfItemsCSV;
  }

  async processFile(file: File): Promise<PartitionItemByCSV[]> {
    try {
      if (!this.headers)
        throw new Error('Headers need to be setted with setHeaders!');
    } catch (err) {
      throw new Error(err.message);
    }
    const contentBuffer = await this.readFileAsync(file);
    const items = await this.addIndexableColumnsId(
      this.handleFileReaded(contentBuffer) as ItemByCSV[],
    );
    return this.createQueue(items);
  }

  private createQueue(items: ItemByCSV[]): PartitionItemByCSV[] {
    const itemsQueue = queueSlicer(items, this.itemQueueSliceSize);
    const partitions: PartitionItemByCSV[] = [];
    itemsQueue.forEach((queue: ItemByCSV[]) => {
      partitions.push({ items: queue });
    });

    return partitions;
  }

  private readFileAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (): void => {
        resolve(reader.result as string);
      };

      reader.onerror = reject;

      reader.readAsText(file);
    });
  }

  private handleFileReaded(file: string): ItemByCSV[] {
    const rows = file.split(/\r?\n|\r/);
    rows.shift();
    if (!rows[rows.length - 1]) rows.pop();

    const data = rows.map((row: string) => {
      return row.replace('\\"', '').replace('"', '').split(',');
    });
    const diffColumnsLength = data[0].length !== this.headers.length;

    if (diffColumnsLength) {
      throw 'InvalidTemplate';
    }

    this.numberOfItemsCSV = data.length;

    return data.map((cell) => {
      const obj = {} as ItemByCSV;
      cell.forEach((v, i) => {
        obj[this.headers[i]] = v;
      });
      return obj;
    });
  }

  private async addIndexableColumnsId(
    items: ItemByCSV[],
  ): Promise<ItemByCSV[]> {
    switch (this.targetType) {
      case 'products':
        const listCategories = await this.productsService.getCategories();
        const listMeasureUnits = await this.productsService.getMeasureUnits();

        const measurementData = (
          dataset: MeasureUnit[],
          measurementData: string,
        ): string => {
          return dataset.find((v) => v.name === measurementData)?.id ?? '';
        };

        const relatedCategorySubcategory = (
          dataset: Category[],
          primaryData: string,
          secundaryData: string,
        ): { categoryId: string; subCategoryId: string } => {
          const cat = dataset?.find((data) => data.name === primaryData);
          const subCat = cat?.subCategories?.find(
            (data) => data?.name === secundaryData,
          );
          return { categoryId: cat?.id ?? '', subCategoryId: subCat?.id ?? '' };
        };

        return (items as ProductByCSV[]).map((product) => {
          return {
            ...product,
            ...relatedCategorySubcategory(
              listCategories,
              product.category,
              product.subCategory,
            ),
            measurementId: measurementData(
              listMeasureUnits,
              product.measurement,
            ),
          };
        });
      case 'stores':
        const listStates = listStateCities;
        const listStoreTypes = storeTypes;

        // TODO: Validação de estados e cidades não vai ser feito atraves de um arquivo local
        // (ainda está para ser decidido se localmente ou através de uma requisição)
        const relatedStateCity = (
          dataset: StateCitiesLocal[],
          stateCSV: string,
          cityCSV: string,
        ): { stateId: string; cityId: string } => {
          const state = dataset?.find((state) => state.name === stateCSV);
          const city = state?.cities?.find((city) => city.name === cityCSV);
          return { stateId: state?.id ?? '', cityId: city?.id ?? '' };
        };

        const checkStoreType = (
          dataset: StoreTypesLocal[],
          storeTypeCSV: string,
        ): string => {
          return dataset.find((type) => type.name === storeTypeCSV)?.id ?? '';
        };

        return (items as StoreByCSV[]).map((store) => {
          return {
            ...store,
            ...relatedStateCity(listStates, store.state, store.city),
            storeTypeId: checkStoreType(listStoreTypes, store.storeType),
          };
        });
      case 'offers':
        return (items as OfferByCSV[]).map((offer) => {
          return {
            ...offer,
            name: 'name',
          };
        });
      default:
        return assertNever(this.targetType);
    }
  }
}
