import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StoreThemeService {
 themeValue = new BehaviorSubject(this.theme);

 set theme(value) {
   this.themeValue.next(value); // this will make sure to tell every subscriber about the change.
   localStorage.setItem('theme-color', value);
 }

 get theme(): 'dark-theme' | 'light-theme' {
   const value = localStorage.getItem('theme-color');
   if (!value) {
     localStorage.setItem('theme-color', 'dark-theme');
     return 'dark-theme';
   }
   return value === 'dark-theme' ? 'dark-theme' : 'light-theme';
 }
}
