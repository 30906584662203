// This file is generated, Do not edit manually.
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
}

export interface AddListItemInput {
  accessToken: Scalars['String'];
  item: Item;
  listId: Scalars['ID'];
}

export interface Address {
  cityId: Scalars['String'];
  complement?: InputMaybe<Scalars['String']>;
  district: Scalars['String'];
  number: Scalars['String'];
  postalCode: Scalars['String'];
  street: Scalars['String'];
}

export interface AssociateUserCityInput {
  accessToken: Scalars['String'];
  cityId: Scalars['String'];
}

export interface AssociateUserInput {
  accessToken: Scalars['String'];
  isEditor: Scalars['Boolean'];
  listId: Scalars['String'];
}

export interface AssociateUserStoreInput {
  accessToken: Scalars['String'];
  storeId: Scalars['String'];
  userId: Scalars['String'];
}

export interface BatchAddress {
  cityId: Scalars['String'];
  complement?: InputMaybe<Scalars['String']>;
  district: Scalars['String'];
  number: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
}

export interface BestOffers {
  __typename?: 'BestOffers';
  offer: Offer;
  product: ProductBestOffer;
  store: StoreBestOffer;
}

export interface BetterOffer {
  __typename?: 'BetterOffer';
  offer: Offer;
  productId: Scalars['ID'];
  store: Store;
}

export interface CancelStoreRequestInput {
  accessToken: Scalars['String'];
  id: Scalars['ID'];
}

export interface Category {
  __typename?: 'Category';
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  name: Scalars['String'];
  status: Scalars['String'];
}

export interface CategoryProduct {
  __typename?: 'CategoryProduct';
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subCategory: SubCategory;
}

export interface City {
  __typename?: 'City';
  id: Scalars['String'];
  name: Scalars['String'];
  state: State;
  stateId: Scalars['ID'];
  stores: Array<Store>;
}

export interface Comment {
  __typename?: 'Comment';
  commentParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  offerId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
}

export interface CommentOfferResponse {
  __typename?: 'CommentOfferResponse';
  commentId: Scalars['String'];
  createdAt: Scalars['String'];
  message: Scalars['String'];
  reply?: Maybe<Array<Maybe<CommentOfferResponse>>>;
  userName: Scalars['String'];
}

export interface CommentWithLikeOfferResponse {
  __typename?: 'CommentWithLikeOfferResponse';
  commentId: Scalars['String'];
  countLike: Scalars['Int'];
  createdAt: Scalars['String'];
  isLiked: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  message: Scalars['String'];
  reply?: Maybe<Array<Maybe<CommentWithLikeOfferResponse>>>;
  userName: Scalars['String'];
}

export interface Country {
  __typename?: 'Country';
  id: Scalars['String'];
  isoCode: Scalars['String'];
  name: Scalars['String'];
}

export interface CreateCommentInput {
  accessToken: Scalars['String'];
  commentParentId?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  offerId: Scalars['String'];
}

export interface CreateListInput {
  accessToken: Scalars['String'];
  iconName: Scalars['String'];
  name: Scalars['String'];
}

export interface CreateListResponse {
  __typename?: 'CreateListResponse';
  iconName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
}

export interface CreateOfferBatchInput {
  accessToken: Scalars['String'];
  authorizationKey: Scalars['String'];
  offers: Array<OfferInput>;
}

export interface CreateOfferBatchResponse {
  __typename?: 'CreateOfferBatchResponse';
  errors: Array<CreateOfferFail>;
  offers: Array<Offer>;
}

export interface CreateOfferFail {
  __typename?: 'CreateOfferFail';
  error: Error;
  offer: OfferFail;
}

export interface CreateOfferInput {
  accessToken: Scalars['String'];
  cityId: Scalars['String'];
  endingTime?: InputMaybe<Scalars['String']>;
  initialTime?: InputMaybe<Scalars['String']>;
  offerTypeValue: Scalars['String'];
  pay?: InputMaybe<Scalars['Int']>;
  previousPrice?: InputMaybe<Scalars['String']>;
  price: Scalars['String'];
  productId: Scalars['String'];
  saleTypeValue: Scalars['String'];
  storeId: Scalars['String'];
  take: Scalars['Int'];
}

export interface CreateOfferResponse {
  __typename?: 'CreateOfferResponse';
  error?: Maybe<Error>;
  offer?: Maybe<Offer>;
}

export interface CreateStoreBatchInput {
  accessToken: Scalars['String'];
  authorizationKey: Scalars['String'];
  stores: Array<StoreBatchInput>;
}

export interface CreateStoreBatchResponse {
  __typename?: 'CreateStoreBatchResponse';
  stores: Array<Store>;
}

export interface CreateStoreInput {
  accessToken: Scalars['String'];
  addressDetail: Address;
  cnpj: Scalars['String'];
  hasImage?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  searchCode?: InputMaybe<Scalars['String']>;
  storeTypeId: Scalars['String'];
}

export interface CreateStoreRequestBatchInput {
  accessToken: Scalars['String'];
  storeRequests: Array<StoreRequestInput>;
}

export interface CreateStoreRequestBatchResponse {
  __typename?: 'CreateStoreRequestBatchResponse';
  storeRequests: Array<Maybe<StoreRequest>>;
}

export interface DeleteCommentInput {
  accessToken: Scalars['String'];
  id: Scalars['String'];
}

export interface DeleteListInput {
  accessToken: Scalars['String'];
  listId: Scalars['ID'];
}

export interface DeleteOfferBatchInput {
  accessToken: Scalars['String'];
  offerIds: Array<Scalars['ID']>;
}

export interface DeleteOfferInput {
  accessToken: Scalars['String'];
  offerId: Scalars['ID'];
}

export interface DeleteStoreBatchInput {
  accessToken: Scalars['String'];
  ids: Array<Scalars['String']>;
}

export interface DeleteStoreInput {
  accessToken: Scalars['String'];
  id: Scalars['String'];
}

export interface DisassociateAllUsersInput {
  accessToken: Scalars['String'];
  listId: Scalars['String'];
}

export interface DisassociateUserCityInput {
  accessToken: Scalars['String'];
  cityId: Scalars['String'];
}

export interface DisassociateUserInput {
  accessToken: Scalars['String'];
  listId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
}

export interface Error {
  __typename?: 'Error';
  code: Scalars['Int'];
  message: Scalars['String'];
  name: Scalars['String'];
}

export interface FavoritePaginationInput {
  accessToken: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
}

export interface FavoriteProduct {
  __typename?: 'FavoriteProduct';
  bestOffer?: Maybe<Offer>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  product: Product;
  updatedAt?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
}

export interface FavoriteProductInput {
  accessToken: Scalars['String'];
  cityId?: InputMaybe<Scalars['ID']>;
  productId: Scalars['ID'];
}

export interface FavoriteStore {
  __typename?: 'FavoriteStore';
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  store: Store;
  updatedAt?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
}

export interface FavoriteStoreInput {
  accessToken: Scalars['String'];
  storeId: Scalars['ID'];
}

export interface GetBestOffersInput {
  cityID?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<Scalars['Int']>;
}

export interface GetBestOffersResponse {
  __typename?: 'GetBestOffersResponse';
  bestOffers?: Maybe<Array<BestOffers>>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface GetBetterOffersByListInput {
  latitude: Scalars['String'];
  listId: Scalars['ID'];
  longitude: Scalars['String'];
  range?: InputMaybe<Scalars['Float']>;
}

export interface GetBetterOffersByListResponse {
  __typename?: 'GetBetterOffersByListResponse';
  betterOffers?: Maybe<Array<BetterOffer>>;
}

export interface GetCitiesByStateIdCity {
  __typename?: 'GetCitiesByStateIdCity';
  id: Scalars['String'];
  name: Scalars['String'];
  stateId: Scalars['ID'];
}

export interface GetCitiesByStateIdInput {
  accessToken: Scalars['String'];
  stateId: Scalars['String'];
}

export interface GetCitiesByStateIdResponse {
  __typename?: 'GetCitiesByStateIdResponse';
  cities?: Maybe<Array<Maybe<GetCitiesByStateIdCity>>>;
}

export interface GetCommentsByOfferInput {
  accessToken?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  offerId: Scalars['String'];
  orderBy?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
}

export interface GetCommentsByOfferResponse {
  __typename?: 'GetCommentsByOfferResponse';
  comments?: Maybe<Array<Maybe<CommentWithLikeOfferResponse>>>;
  countComment?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  offerId?: Maybe<Scalars['String']>;
}

export interface GetDownloadUrlInput {
  accessToken: Scalars['String'];
  storeRequestId: Scalars['ID'];
}

export interface GetFavoriteProductsInput {
  accessToken: Scalars['String'];
  cityId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
}

export interface GetFavoriteProductsResponse {
  __typename?: 'GetFavoriteProductsResponse';
  favoriteProducts?: Maybe<Array<FavoriteProduct>>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface GetFavoriteStoresResponse {
  __typename?: 'GetFavoriteStoresResponse';
  favoriteStores?: Maybe<Array<FavoriteStore>>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface GetLikeOfferReviewInput {
  offerId: Scalars['String'];
}

export interface GetLikeOfferReviewResponse {
  __typename?: 'GetLikeOfferReviewResponse';
  review: Array<Maybe<LikeOfferReview>>;
}

export interface GetListInput {
  accessToken: Scalars['String'];
  listId: Scalars['String'];
}

export interface GetListResponse {
  __typename?: 'GetListResponse';
  canEdit: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  list: List;
}

export interface GetListsByUserInput {
  accessToken: Scalars['String'];
}

export interface GetListsByUserResponse {
  __typename?: 'GetListsByUserResponse';
  lists?: Maybe<Array<GetListsByUserResponseItem>>;
}

export interface GetListsByUserResponseItem {
  __typename?: 'GetListsByUserResponseItem';
  canEdit: Scalars['Boolean'];
  iconName: Scalars['String'];
  id: Scalars['String'];
  isOwner?: Maybe<Scalars['Boolean']>;
  isShared: Scalars['Boolean'];
  name: Scalars['String'];
  owner?: Maybe<User>;
  size: Scalars['Int'];
  updatedAt: Scalars['String'];
}

export interface GetOfferByIdInput {
  accessToken?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['String']>;
}

export interface GetOfferByIdResponse {
  __typename?: 'GetOfferByIdResponse';
  countComment?: Maybe<Scalars['Int']>;
  countDislike?: Maybe<Scalars['Int']>;
  countLike?: Maybe<Scalars['Int']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  offer?: Maybe<Offer>;
  product?: Maybe<Product>;
  store?: Maybe<Store>;
  userApp?: Maybe<User>;
  userBackoffice?: Maybe<User>;
}

export interface GetOffersByStoreInput {
  nextToken?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
  storeId: Scalars['String'];
}

export interface GetOffersByStoreResponse {
  __typename?: 'GetOffersByStoreResponse';
  nextToken?: Maybe<Scalars['String']>;
  storeOffers: Array<Maybe<OfferWithProduct>>;
}

export interface GetSimilarOffersInput {
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  paginationSize?: InputMaybe<Scalars['Int']>;
  productId: Scalars['String'];
  range: Scalars['Float'];
  subCategoryId: Scalars['String'];
}

export interface GetSimilarOffersResponse {
  __typename?: 'GetSimilarOffersResponse';
  similarOffers?: Maybe<Array<Maybe<SimilarOffers>>>;
}

export interface GetStateCityByLatLongInput {
  accessToken: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
}

export interface GetStateCityByLatLongResponse {
  __typename?: 'GetStateCityByLatLongResponse';
  city: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
}

export interface GetStatesInput {
  accessToken: Scalars['String'];
}

export interface GetStatesResponse {
  __typename?: 'GetStatesResponse';
  states?: Maybe<Array<Maybe<GetStatesState>>>;
}

export interface GetStatesState {
  __typename?: 'GetStatesState';
  federativeUnit: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
}

export interface GetStoreInput {
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  searchCode?: InputMaybe<Scalars['String']>;
}

export interface GetStoreTypesResponse {
  __typename?: 'GetStoreTypesResponse';
  types?: Maybe<Array<Maybe<StoreType>>>;
}

export interface GetUploadUrlInput {
  accessToken: Scalars['String'];
  bucket?: InputMaybe<UploadBucketEnum>;
  filename: Scalars['String'];
}

export interface GetUserPublishedOffersInput {
  accessToken: Scalars['String'];
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
}

export interface GetUserPublishedOffersResponse {
  __typename?: 'GetUserPublishedOffersResponse';
  nextToken?: Maybe<Scalars['String']>;
  publishedOffers: Array<Maybe<UserPublishedOffers>>;
}

export interface Item {
  productId: Scalars['String'];
  quantity: Scalars['Int'];
}

export interface LikeComment {
  __typename?: 'LikeComment';
  commentId?: Maybe<Scalars['String']>;
  countLike?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

export interface LikeCommentInput {
  accessToken: Scalars['String'];
  commentId: Scalars['ID'];
}

export interface LikeOffer {
  __typename?: 'LikeOffer';
  countLike?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  offerId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

export interface LikeOfferInput {
  accessToken: Scalars['String'];
  isLiked?: InputMaybe<Scalars['Boolean']>;
  offerId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
}

export interface LikeOfferResponse {
  __typename?: 'LikeOfferResponse';
  countDislike?: Maybe<Scalars['Int']>;
  countLike?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  offerId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

export interface LikeOfferReview {
  __typename?: 'LikeOfferReview';
  count?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
}

export interface List {
  __typename?: 'List';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  iconName: Scalars['String'];
  id: Scalars['ID'];
  items: Array<ListItem>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  shares?: Maybe<Array<ListShare>>;
  status: Scalars['String'];
  updatedAt: Scalars['String'];
}

export interface ListItem {
  __typename?: 'ListItem';
  id: Scalars['ID'];
  listId: Scalars['String'];
  product: ListItemProduct;
  quantity: Scalars['Int'];
}

export interface ListItemProduct {
  __typename?: 'ListItemProduct';
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  measurement: Measurement;
  measurementValue: Scalars['String'];
  name: Scalars['String'];
  offers?: Maybe<Array<Maybe<Offer>>>;
  subCategory: SubCategory;
}

export interface ListShare {
  __typename?: 'ListShare';
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEditor: Scalars['Boolean'];
  listId: Scalars['String'];
  userId: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
}

export interface ListUserCityAssociationsInput {
  accessToken: Scalars['String'];
}

export interface ListUserCityAssociationsResponse {
  __typename?: 'ListUserCityAssociationsResponse';
  cities: Array<Maybe<GetCitiesByStateIdCity>>;
}

export interface Location {
  __typename?: 'Location';
  latitude: Scalars['String'];
  longitude: Scalars['String'];
}

export interface Measurement {
  __typename?: 'Measurement';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  products?: Maybe<Array<Maybe<Product>>>;
  symbol: Scalars['String'];
}

export interface MeasurementNoRelations {
  __typename?: 'MeasurementNoRelations';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  symbol: Scalars['String'];
}

export interface Mutation {
  __typename?: 'Mutation';
  addListItem?: Maybe<GetListResponse>;
  associateUser?: Maybe<ListShare>;
  associateUserCity?: Maybe<Scalars['Boolean']>;
  associateUserStore?: Maybe<Scalars['Boolean']>;
  cancelStoreRequest?: Maybe<Scalars['Boolean']>;
  createComment?: Maybe<Comment>;
  createList?: Maybe<CreateListResponse>;
  createOffer?: Maybe<CreateOfferResponse>;
  createOfferBatch?: Maybe<CreateOfferBatchResponse>;
  createStore?: Maybe<Store>;
  createStoreBatch?: Maybe<CreateStoreBatchResponse>;
  createStoreRequestBatch?: Maybe<CreateStoreRequestBatchResponse>;
  deleteComment?: Maybe<Scalars['Boolean']>;
  deleteList?: Maybe<Scalars['Boolean']>;
  deleteOffer?: Maybe<Scalars['Boolean']>;
  deleteOfferBatch?: Maybe<Scalars['Boolean']>;
  deleteStore?: Maybe<Scalars['Boolean']>;
  deleteStoreBatch?: Maybe<Scalars['Boolean']>;
  disassociateAllUsers?: Maybe<Scalars['Boolean']>;
  disassociateUser?: Maybe<Scalars['Boolean']>;
  disassociateUserCity?: Maybe<Scalars['Boolean']>;
  favoriteProduct?: Maybe<Scalars['Boolean']>;
  favoriteStore?: Maybe<Scalars['Boolean']>;
  likeComment?: Maybe<LikeComment>;
  likeOffer?: Maybe<LikeOfferResponse>;
  reportComment?: Maybe<ReportComment>;
  storeCrawler?: Maybe<Scalars['Boolean']>;
  updateComment?: Maybe<Comment>;
  updateListDetails?: Maybe<Scalars['Boolean']>;
  updateListItems?: Maybe<GetListResponse>;
  updateOffer?: Maybe<Offer>;
  updateStore?: Maybe<Store>;
  updateStoreRequest?: Maybe<StoreRequest>;
}


export interface MutationAddListItemArgs {
  params: AddListItemInput;
}


export interface MutationAssociateUserArgs {
  params: AssociateUserInput;
}


export interface MutationAssociateUserCityArgs {
  params: AssociateUserCityInput;
}


export interface MutationAssociateUserStoreArgs {
  params: AssociateUserStoreInput;
}


export interface MutationCancelStoreRequestArgs {
  params: CancelStoreRequestInput;
}


export interface MutationCreateCommentArgs {
  params: CreateCommentInput;
}


export interface MutationCreateListArgs {
  params: CreateListInput;
}


export interface MutationCreateOfferArgs {
  params: CreateOfferInput;
}


export interface MutationCreateOfferBatchArgs {
  params: CreateOfferBatchInput;
}


export interface MutationCreateStoreArgs {
  params: CreateStoreInput;
}


export interface MutationCreateStoreBatchArgs {
  params: CreateStoreBatchInput;
}


export interface MutationCreateStoreRequestBatchArgs {
  params: CreateStoreRequestBatchInput;
}


export interface MutationDeleteCommentArgs {
  params: DeleteCommentInput;
}


export interface MutationDeleteListArgs {
  params: DeleteListInput;
}


export interface MutationDeleteOfferArgs {
  params: DeleteOfferInput;
}


export interface MutationDeleteOfferBatchArgs {
  params: DeleteOfferBatchInput;
}


export interface MutationDeleteStoreArgs {
  params: DeleteStoreInput;
}


export interface MutationDeleteStoreBatchArgs {
  params: DeleteStoreBatchInput;
}


export interface MutationDisassociateAllUsersArgs {
  params: DisassociateAllUsersInput;
}


export interface MutationDisassociateUserArgs {
  params: DisassociateUserInput;
}


export interface MutationDisassociateUserCityArgs {
  params: DisassociateUserCityInput;
}


export interface MutationFavoriteProductArgs {
  params: FavoriteProductInput;
}


export interface MutationFavoriteStoreArgs {
  params: FavoriteStoreInput;
}


export interface MutationLikeCommentArgs {
  params: LikeCommentInput;
}


export interface MutationLikeOfferArgs {
  params: LikeOfferInput;
}


export interface MutationReportCommentArgs {
  params: ReportCommentInput;
}


export interface MutationStoreCrawlerArgs {
  params: StoreCrawlerInput;
}


export interface MutationUpdateCommentArgs {
  params: UpdateCommentInput;
}


export interface MutationUpdateListDetailsArgs {
  params: UpdateListDetailsInput;
}


export interface MutationUpdateListItemsArgs {
  params: UpdateListItemsInput;
}


export interface MutationUpdateOfferArgs {
  params: UpdateOfferInput;
}


export interface MutationUpdateStoreArgs {
  params: UpdateStoreInput;
}


export interface MutationUpdateStoreRequestArgs {
  params: UpdateStoreRequestInput;
}

export interface Offer {
  __typename?: 'Offer';
  cityId?: Maybe<Scalars['String']>;
  countLike?: Maybe<Scalars['Int']>;
  endingTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  initialTime?: Maybe<Scalars['String']>;
  offerTypeValue?: Maybe<Scalars['String']>;
  pay?: Maybe<Scalars['Int']>;
  previousPrice?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  saleTypeValue?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  userAppId?: Maybe<Scalars['String']>;
}

export interface OfferBatchValidationInput {
  barcode: Scalars['String'];
  endingTime?: InputMaybe<Scalars['String']>;
  initialTime?: InputMaybe<Scalars['String']>;
  offerTypeValue: Scalars['String'];
  pay?: InputMaybe<Scalars['Int']>;
  price: Scalars['String'];
  saleTypeValue: Scalars['String'];
  storeId: Scalars['String'];
  take: Scalars['Int'];
}

export interface OfferFail {
  __typename?: 'OfferFail';
  offerTypeValue: Scalars['String'];
  pay?: Maybe<Scalars['Int']>;
  price: Scalars['String'];
  productId: Scalars['String'];
  saleTypeValue: Scalars['String'];
  storeId: Scalars['String'];
  take: Scalars['Int'];
}

export interface OfferInput {
  cityId: Scalars['String'];
  endingTime?: InputMaybe<Scalars['String']>;
  initialTime?: InputMaybe<Scalars['String']>;
  offerTypeValue: Scalars['String'];
  pay?: InputMaybe<Scalars['Int']>;
  price: Scalars['String'];
  productId: Scalars['String'];
  saleTypeValue: Scalars['String'];
  storeId: Scalars['String'];
  take: Scalars['Int'];
}

export enum OfferType {
  Fixed = 'FIXED',
  From = 'FROM',
  Takepay = 'TAKEPAY',
  Unit = 'UNIT'
}

export interface OfferWithCounts {
  __typename?: 'OfferWithCounts';
  countComment: Scalars['Int'];
  countLike?: Maybe<Scalars['Int']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  offer?: Maybe<Offer>;
}

export interface OfferWithProduct {
  __typename?: 'OfferWithProduct';
  offer: Offer;
  product: Product;
}

export interface Product {
  __typename?: 'Product';
  barcode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<S3ImageRequest>;
  measurement?: Maybe<Measurement>;
  measurementValue?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  offers?: Maybe<Array<Maybe<Offer>>>;
  subCategory?: Maybe<SubCategory>;
}

export interface ProductBestOffer {
  __typename?: 'ProductBestOffer';
  barcode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  measurement: Measurement;
  measurementValue?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subCategory: SubCategory;
}

export interface ProductNoRelations {
  __typename?: 'ProductNoRelations';
  barcode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<S3ImageRequest>;
  measurement?: Maybe<MeasurementNoRelations>;
  measurementValue?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subCategory?: Maybe<SubCategory>;
}

export interface Query {
  __typename?: 'Query';
  getBestOffers?: Maybe<GetBestOffersResponse>;
  getBetterOffersByList?: Maybe<GetBetterOffersByListResponse>;
  getCitiesByStateId?: Maybe<GetCitiesByStateIdResponse>;
  getCommentsByOffer?: Maybe<GetCommentsByOfferResponse>;
  getDownloadUrl?: Maybe<Url>;
  getFavoriteProducts?: Maybe<GetFavoriteProductsResponse>;
  getFavoriteStores?: Maybe<GetFavoriteStoresResponse>;
  getLikeOfferReview?: Maybe<GetLikeOfferReviewResponse>;
  getList?: Maybe<GetListResponse>;
  getListsByUser?: Maybe<GetListsByUserResponse>;
  getOfferById?: Maybe<GetOfferByIdResponse>;
  getOffersByStore?: Maybe<GetOffersByStoreResponse>;
  getSimilarOffers?: Maybe<GetSimilarOffersResponse>;
  getStateCityByLatLong?: Maybe<GetStateCityByLatLongResponse>;
  getStates?: Maybe<GetStatesResponse>;
  getStore?: Maybe<Store>;
  getStoreTypes?: Maybe<GetStoreTypesResponse>;
  getUploadUrl?: Maybe<Url>;
  getUserPublishedOffers?: Maybe<GetUserPublishedOffersResponse>;
  listUserCityAssociations?: Maybe<ListUserCityAssociationsResponse>;
  searchOffers?: Maybe<SearchOffersResponse>;
  searchOffersByProduct?: Maybe<SearchOffersByProductResponse>;
  searchProducts?: Maybe<SearchProductsResponse>;
  searchStoreRequests?: Maybe<SearchStoreRequestsResponse>;
  searchStores?: Maybe<SearchStoresResponse>;
  validateOfferBatch?: Maybe<ValidateOfferBatchResponse>;
  validateStoreBatch?: Maybe<ValidateStoreBatchResponse>;
}


export interface QueryGetBestOffersArgs {
  params: GetBestOffersInput;
}


export interface QueryGetBetterOffersByListArgs {
  params: GetBetterOffersByListInput;
}


export interface QueryGetCitiesByStateIdArgs {
  params: GetCitiesByStateIdInput;
}


export interface QueryGetCommentsByOfferArgs {
  params: GetCommentsByOfferInput;
}


export interface QueryGetDownloadUrlArgs {
  params: GetDownloadUrlInput;
}


export interface QueryGetFavoriteProductsArgs {
  params: GetFavoriteProductsInput;
}


export interface QueryGetFavoriteStoresArgs {
  params: FavoritePaginationInput;
}


export interface QueryGetLikeOfferReviewArgs {
  params: GetLikeOfferReviewInput;
}


export interface QueryGetListArgs {
  params: GetListInput;
}


export interface QueryGetListsByUserArgs {
  params: GetListsByUserInput;
}


export interface QueryGetOfferByIdArgs {
  params: GetOfferByIdInput;
}


export interface QueryGetOffersByStoreArgs {
  params: GetOffersByStoreInput;
}


export interface QueryGetSimilarOffersArgs {
  params: GetSimilarOffersInput;
}


export interface QueryGetStateCityByLatLongArgs {
  params: GetStateCityByLatLongInput;
}


export interface QueryGetStatesArgs {
  params: GetStatesInput;
}


export interface QueryGetStoreArgs {
  params: GetStoreInput;
}


export interface QueryGetUploadUrlArgs {
  params: GetUploadUrlInput;
}


export interface QueryGetUserPublishedOffersArgs {
  params: GetUserPublishedOffersInput;
}


export interface QueryListUserCityAssociationsArgs {
  params: ListUserCityAssociationsInput;
}


export interface QuerySearchOffersArgs {
  params: SearchOffersInput;
}


export interface QuerySearchOffersByProductArgs {
  params: SearchOffersByProductInput;
}


export interface QuerySearchProductsArgs {
  params: SearchProductsInput;
}


export interface QuerySearchStoreRequestsArgs {
  params: SearchStoreRequestsInput;
}


export interface QuerySearchStoresArgs {
  params: SearchStoresInput;
}


export interface QueryValidateOfferBatchArgs {
  params: ValidateOfferBatchInput;
}


export interface QueryValidateStoreBatchArgs {
  params: ValidateStoreBatchInput;
}

export interface ReportComment {
  __typename?: 'ReportComment';
  commentId: Scalars['String'];
  id: Scalars['String'];
  userId: Scalars['String'];
}

export interface ReportCommentInput {
  accessToken: Scalars['String'];
  commentId: Scalars['String'];
}

export interface S3ImageHeader {
  __typename?: 'S3ImageHeader';
  Authorization?: Maybe<Scalars['String']>;
  Host?: Maybe<Scalars['String']>;
  X_Amz_Content_Sha256?: Maybe<Scalars['String']>;
  X_Amz_Date?: Maybe<Scalars['String']>;
}

export interface S3ImageRequest {
  __typename?: 'S3ImageRequest';
  headers?: Maybe<S3ImageHeader>;
  url?: Maybe<Scalars['String']>;
}

export enum SaleType {
  Retail = 'RETAIL',
  Wholesale = 'WHOLESALE'
}

export interface SearchOffersByProduct {
  __typename?: 'SearchOffersByProduct';
  distance: Scalars['Float'];
  id: Scalars['ID'];
  offerTypeValue: Scalars['String'];
  pay?: Maybe<Scalars['Int']>;
  price: Scalars['String'];
  product?: Maybe<ProductNoRelations>;
  saleTypeValue: Scalars['String'];
  store?: Maybe<StoreNoRelations>;
  storeId?: Maybe<Scalars['String']>;
  take: Scalars['Int'];
  updatedAt: Scalars['String'];
}

export interface SearchOffersByProductInput {
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
  productId: Scalars['ID'];
  range?: InputMaybe<Scalars['Float']>;
}

export interface SearchOffersByProductResponse {
  __typename?: 'SearchOffersByProductResponse';
  minPrice?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  offers?: Maybe<Array<SearchOffersByProduct>>;
}

export interface SearchOffersInput {
  accessToken?: InputMaybe<Scalars['String']>;
  barcode?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['ID']>;
  storeId: Scalars['ID'];
}

export interface SearchOffersResponse {
  __typename?: 'SearchOffersResponse';
  offers?: Maybe<Array<Maybe<OfferWithCounts>>>;
}

export interface SearchProductsInput {
  barcode?: InputMaybe<Scalars['String']>;
  categoriesIds?: InputMaybe<Array<Scalars['ID']>>;
  cityId?: InputMaybe<Scalars['String']>;
  includeProductsWithoutOffer?: InputMaybe<Scalars['Boolean']>;
  maxResults?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nextTokenText?: InputMaybe<Scalars['String']>;
  sortType?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['String']>;
  subCategoriesIds?: InputMaybe<Array<Scalars['ID']>>;
}

export interface SearchProductsResponse {
  __typename?: 'SearchProductsResponse';
  nextToken?: Maybe<Scalars['String']>;
  products: Array<Product>;
}

export interface SearchStoreRequestsInput {
  accessToken: Scalars['String'];
  analysisStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nextToken?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderByType?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  storeRequestType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface SearchStoreRequestsResponse {
  __typename?: 'SearchStoreRequestsResponse';
  nextToken?: Maybe<Scalars['String']>;
  storeRequests?: Maybe<Array<Maybe<StoreRequest>>>;
}

export interface SearchStoresInput {
  accessToken?: InputMaybe<Scalars['String']>;
  citiesID?: InputMaybe<Array<Scalars['ID']>>;
  cnpj?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<Scalars['Float']>;
  statesID?: InputMaybe<Array<Scalars['ID']>>;
  storeTypeIds?: InputMaybe<Array<Scalars['ID']>>;
}

export interface SearchStoresResponse {
  __typename?: 'SearchStoresResponse';
  nextToken?: Maybe<Scalars['String']>;
  stores: Array<Maybe<Store>>;
}

export interface SimilarOffers {
  __typename?: 'SimilarOffers';
  offer: Offer;
  product: Product;
}

export interface State {
  __typename?: 'State';
  cities: Array<City>;
  country: Country;
  countryId: Scalars['String'];
  federativeUnit: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
}

export interface Store {
  __typename?: 'Store';
  address: Scalars['String'];
  city?: Maybe<City>;
  cityId: Scalars['ID'];
  cnpj?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  geolocation?: Maybe<Location>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  name: Scalars['String'];
  offerCount?: Maybe<Scalars['Int']>;
  offers?: Maybe<Array<Maybe<Offer>>>;
  openingHours?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  searchCode?: Maybe<Scalars['String']>;
  storeType?: Maybe<StoreType>;
}

export interface StoreBatchInput {
  addressDetail: BatchAddress;
  cnpj: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  searchCode?: InputMaybe<Scalars['String']>;
  storeTypeId?: InputMaybe<Scalars['String']>;
}

export interface StoreBestOffer {
  __typename?: 'StoreBestOffer';
  address: Scalars['String'];
  cityId: Scalars['ID'];
  complement?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  geolocation?: Maybe<Location>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  name: Scalars['String'];
  searchCode?: Maybe<Scalars['String']>;
  storeType?: Maybe<StoreType>;
}

export interface StoreCrawlerInput {
  city?: InputMaybe<Scalars['String']>;
  googleApiKey: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  state: Scalars['String'];
}

export interface StoreNoRelations {
  __typename?: 'StoreNoRelations';
  address: Scalars['String'];
  cityId: Scalars['ID'];
  complement?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  geolocation?: Maybe<Location>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  name: Scalars['String'];
  searchCode?: Maybe<Scalars['String']>;
  storeType?: Maybe<StoreType>;
}

export interface StoreRequest {
  __typename?: 'StoreRequest';
  adminComment?: Maybe<Scalars['String']>;
  analysisStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  documentUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  rejectionReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestType?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  updatedAt?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
}

export interface StoreRequestInput {
  comment?: InputMaybe<Scalars['String']>;
  deletionReasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  storeId: Scalars['String'];
  storeRequestType: Scalars['String'];
}

export interface StoreType {
  __typename?: 'StoreType';
  id: Scalars['ID'];
  name: Scalars['String'];
  statusDeleted?: Maybe<Scalars['String']>;
}

export interface StoreValidation {
  cityName: Scalars['String'];
  cnpj: Scalars['String'];
  name: Scalars['String'];
  stateName: Scalars['String'];
  storeTypeName?: InputMaybe<Scalars['String']>;
}

export interface SubCategory {
  __typename?: 'SubCategory';
  category: Category;
  categoryId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  name: Scalars['String'];
}

export interface UpdateCommentInput {
  accessToken: Scalars['String'];
  id: Scalars['String'];
  message: Scalars['String'];
  offerId: Scalars['String'];
}

export interface UpdateListDetailsInput {
  accessToken: Scalars['String'];
  listId: Scalars['ID'];
  newIconName: Scalars['String'];
  newName: Scalars['String'];
}

export interface UpdateListItemsInput {
  accessToken: Scalars['String'];
  forceUpdate: Scalars['Boolean'];
  items: Array<InputMaybe<Item>>;
  lastUpdate: Scalars['String'];
  listId: Scalars['ID'];
}

export interface UpdateOfferInput {
  accessToken: Scalars['String'];
  endingTime?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  initialTime?: InputMaybe<Scalars['String']>;
  pay?: InputMaybe<Scalars['Int']>;
  price: Scalars['String'];
  take: Scalars['Int'];
}

export interface UpdateStoreInput {
  accessToken: Scalars['String'];
  addressDetail?: InputMaybe<Address>;
  cnpj?: InputMaybe<Scalars['String']>;
  hasImage?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  storeTypeId?: InputMaybe<Scalars['String']>;
}

export interface UpdateStoreRequestInput {
  accessToken: Scalars['String'];
  adminComment?: InputMaybe<Scalars['String']>;
  analysisStatus: Scalars['String'];
  id: Scalars['ID'];
  rejectionReasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export enum UploadBucketEnum {
  Attachment = 'ATTACHMENT',
  Photo = 'PHOTO'
}

export interface Url {
  __typename?: 'Url';
  url?: Maybe<Scalars['String']>;
}

export interface User {
  __typename?: 'User';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  offers?: Maybe<Array<Maybe<Offer>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  updatedAt?: Maybe<Scalars['String']>;
}

export interface UserPublishedOffers {
  __typename?: 'UserPublishedOffers';
  offer?: Maybe<Offer>;
  product?: Maybe<Product>;
  store?: Maybe<Store>;
}

export interface ValidateOfferBatchInput {
  accessToken: Scalars['String'];
  offers: Array<OfferBatchValidationInput>;
}

export interface ValidateOfferBatchResponse {
  __typename?: 'ValidateOfferBatchResponse';
  authorizationKey?: Maybe<Scalars['String']>;
  invalidatedOffers?: Maybe<Array<ValidatedOffer>>;
  validatedOffers?: Maybe<Array<ValidatedOffer>>;
}

export interface ValidateStoreBatchInput {
  accessToken: Scalars['String'];
  validationData: Array<StoreValidation>;
}

export interface ValidateStoreBatchResponse {
  __typename?: 'ValidateStoreBatchResponse';
  authorizationKey?: Maybe<Scalars['String']>;
  validatedStores: Array<ValidatedStore>;
}

export interface ValidatedOffer {
  __typename?: 'ValidatedOffer';
  barcode: Scalars['String'];
  endingTime?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  initialTime?: Maybe<Scalars['String']>;
  offerId?: Maybe<Scalars['String']>;
  offerTypeValue: Scalars['String'];
  pay?: Maybe<Scalars['Int']>;
  price: Scalars['String'];
  productId: Scalars['String'];
  storeId: Scalars['String'];
  take: Scalars['Int'];
}

export interface ValidatedStore {
  __typename?: 'ValidatedStore';
  cityId?: Maybe<Scalars['String']>;
  cityName: Scalars['String'];
  cnpj?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  name: Scalars['String'];
  stateId?: Maybe<Scalars['String']>;
  stateName: Scalars['String'];
  storeTypeId?: Maybe<Scalars['String']>;
  storeTypeName?: Maybe<Scalars['String']>;
}

export type StoreFragFragment = { __typename?: 'Store', id: string, name: string, cnpj?: string | null, address: string, complement?: string | null, storeType?: { __typename?: 'StoreType', id: string, name: string } | null, geolocation?: { __typename?: 'Location', latitude: string, longitude: string } | null, image?: { __typename?: 'S3ImageRequest', url?: string | null, headers?: { __typename?: 'S3ImageHeader', X_Amz_Content_Sha256?: string | null, X_Amz_Date?: string | null, Authorization?: string | null, Host?: string | null } | null } | null, city?: { __typename?: 'City', id: string, name: string, state: { __typename?: 'State', id: string, name: string, federativeUnit: string } } | null };

export type CreateOfferBatchMutationVariables = Exact<{
  params: CreateOfferBatchInput;
}>;


export type CreateOfferBatchMutation = { __typename?: 'Mutation', createOfferBatch?: { __typename?: 'CreateOfferBatchResponse', offers: Array<{ __typename?: 'Offer', id?: string | null }>, errors: Array<{ __typename?: 'CreateOfferFail', error: { __typename?: 'Error', code: number, message: string, name: string } }> } | null };

export type CreateOfferMutationVariables = Exact<{
  params: CreateOfferInput;
}>;


export type CreateOfferMutation = { __typename?: 'Mutation', createOffer?: { __typename?: 'CreateOfferResponse', error?: { __typename?: 'Error', code: number, message: string } | null, offer?: { __typename?: 'Offer', id?: string | null } | null } | null };

export type CreateStoreBatchMutationVariables = Exact<{
  params: CreateStoreBatchInput;
}>;


export type CreateStoreBatchMutation = { __typename?: 'Mutation', createStoreBatch?: { __typename?: 'CreateStoreBatchResponse', stores: Array<{ __typename?: 'Store', id: string }> } | null };

export type CreateStoreRequestBatchMutationVariables = Exact<{
  params: CreateStoreRequestBatchInput;
}>;


export type CreateStoreRequestBatchMutation = { __typename?: 'Mutation', createStoreRequestBatch?: { __typename?: 'CreateStoreRequestBatchResponse', storeRequests: Array<{ __typename?: 'StoreRequest', adminComment?: string | null, analysisStatus?: string | null, createdAt?: string | null, documentUrl?: string | null, id?: string | null, rejectionReasons?: Array<string | null> | null, requestType?: string | null, updatedAt?: string | null, store?: { __typename?: 'Store', id: string, name: string, cnpj?: string | null, address: string, complement?: string | null, storeType?: { __typename?: 'StoreType', id: string, name: string } | null, geolocation?: { __typename?: 'Location', latitude: string, longitude: string } | null, image?: { __typename?: 'S3ImageRequest', url?: string | null, headers?: { __typename?: 'S3ImageHeader', X_Amz_Content_Sha256?: string | null, X_Amz_Date?: string | null, Authorization?: string | null, Host?: string | null } | null } | null, city?: { __typename?: 'City', id: string, name: string, state: { __typename?: 'State', id: string, name: string, federativeUnit: string } } | null } | null } | null> } | null };

export type CreateStoreMutationVariables = Exact<{
  params: CreateStoreInput;
}>;


export type CreateStoreMutation = { __typename?: 'Mutation', createStore?: { __typename?: 'Store', id: string } | null };

export type DeleteOfferBatchMutationVariables = Exact<{
  params: DeleteOfferBatchInput;
}>;


export type DeleteOfferBatchMutation = { __typename?: 'Mutation', deleteOfferBatch?: boolean | null };

export type DeleteOfferMutationVariables = Exact<{
  params: DeleteOfferInput;
}>;


export type DeleteOfferMutation = { __typename?: 'Mutation', deleteOffer?: boolean | null };

export type DeleteStoreBatchMutationVariables = Exact<{
  params: DeleteStoreBatchInput;
}>;


export type DeleteStoreBatchMutation = { __typename?: 'Mutation', deleteStoreBatch?: boolean | null };

export type DeleteStoreMutationVariables = Exact<{
  params: DeleteStoreInput;
}>;


export type DeleteStoreMutation = { __typename?: 'Mutation', deleteStore?: boolean | null };

export type UpdateOfferMutationVariables = Exact<{
  params: UpdateOfferInput;
}>;


export type UpdateOfferMutation = { __typename?: 'Mutation', updateOffer?: { __typename?: 'Offer', id?: string | null, price?: string | null, take?: number | null, pay?: number | null, saleTypeValue?: string | null, offerTypeValue?: string | null } | null };

export type UpdateStoreRequestMutationVariables = Exact<{
  params: UpdateStoreRequestInput;
}>;


export type UpdateStoreRequestMutation = { __typename?: 'Mutation', updateStoreRequest?: { __typename?: 'StoreRequest', id?: string | null, adminComment?: string | null, analysisStatus?: string | null, documentUrl?: string | null, requestType?: string | null, store?: { __typename?: 'Store', id: string, address: string, cnpj?: string | null, phone?: string | null, name: string } | null, user?: { __typename?: 'User', name: string, email: string } | null } | null };

export type UpdateStoreMutationVariables = Exact<{
  params: UpdateStoreInput;
}>;


export type UpdateStoreMutation = { __typename?: 'Mutation', updateStore?: { __typename?: 'Store', id: string, name: string, address: string, complement?: string | null, image?: { __typename?: 'S3ImageRequest', url?: string | null } | null } | null };

export type GetCitiesByStateIdQueryVariables = Exact<{
  params: GetCitiesByStateIdInput;
}>;


export type GetCitiesByStateIdQuery = { __typename?: 'Query', getCitiesByStateId?: { __typename?: 'GetCitiesByStateIdResponse', cities?: Array<{ __typename?: 'GetCitiesByStateIdCity', id: string, name: string } | null> | null } | null };

export type GetStateCityByLatLongQueryVariables = Exact<{
  params: GetStateCityByLatLongInput;
}>;


export type GetStateCityByLatLongQuery = { __typename?: 'Query', getStateCityByLatLong?: { __typename?: 'GetStateCityByLatLongResponse', country: string, state: string, city: string } | null };

export type GetStatesQueryVariables = Exact<{
  params: GetStatesInput;
}>;


export type GetStatesQuery = { __typename?: 'Query', getStates?: { __typename?: 'GetStatesResponse', states?: Array<{ __typename?: 'GetStatesState', federativeUnit: string, id: string, name: string } | null> | null } | null };

export type GetStoreTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreTypesQuery = { __typename?: 'Query', getStoreTypes?: { __typename?: 'GetStoreTypesResponse', types?: Array<{ __typename?: 'StoreType', id: string, name: string } | null> | null } | null };

export type GetStoreQueryVariables = Exact<{
  params: GetStoreInput;
}>;


export type GetStoreQuery = { __typename?: 'Query', getStore?: { __typename?: 'Store', id: string, name: string, address: string, cityId: string, storeType?: { __typename?: 'StoreType', id: string } | null } | null };

export type GetUploadUrlQueryVariables = Exact<{
  params: GetUploadUrlInput;
}>;


export type GetUploadUrlQuery = { __typename?: 'Query', getUploadUrl?: { __typename?: 'Url', url?: string | null } | null };

export type SearchProductsWOffersQueryVariables = Exact<{
  params: SearchProductsInput;
}>;


export type SearchProductsWOffersQuery = { __typename?: 'Query', searchProducts?: { __typename?: 'SearchProductsResponse', nextToken?: string | null, products: Array<{ __typename?: 'Product', id?: string | null, name?: string | null, measurementValue?: string | null, barcode?: string | null, image?: { __typename?: 'S3ImageRequest', url?: string | null, headers?: { __typename?: 'S3ImageHeader', X_Amz_Content_Sha256?: string | null, X_Amz_Date?: string | null, Authorization?: string | null, Host?: string | null } | null } | null, measurement?: { __typename?: 'Measurement', symbol: string } | null, offers?: Array<{ __typename?: 'Offer', id?: string | null, offerTypeValue?: string | null, pay?: number | null, price?: string | null, take?: number | null, initialTime?: string | null, endingTime?: string | null } | null> | null }> } | null };

export type SearchProductsQueryVariables = Exact<{
  params: SearchProductsInput;
}>;


export type SearchProductsQuery = { __typename?: 'Query', searchProducts?: { __typename?: 'SearchProductsResponse', nextToken?: string | null, products: Array<{ __typename?: 'Product', id?: string | null, name?: string | null, barcode?: string | null, measurementValue?: string | null, brand?: string | null, model?: string | null, image?: { __typename?: 'S3ImageRequest', url?: string | null, headers?: { __typename?: 'S3ImageHeader', X_Amz_Content_Sha256?: string | null, X_Amz_Date?: string | null, Authorization?: string | null, Host?: string | null } | null } | null, measurement?: { __typename?: 'Measurement', id: string, name: string, symbol: string, description?: string | null } | null, subCategory?: { __typename?: 'SubCategory', id: string, category: { __typename?: 'Category', id: string } } | null }> } | null };

export type SearchStoreRequestsQueryVariables = Exact<{
  params: SearchStoreRequestsInput;
}>;


export type SearchStoreRequestsQuery = { __typename?: 'Query', searchStoreRequests?: { __typename?: 'SearchStoreRequestsResponse', nextToken?: string | null, storeRequests?: Array<{ __typename?: 'StoreRequest', id?: string | null, adminComment?: string | null, analysisStatus?: string | null, createdAt?: string | null, updatedAt?: string | null, documentUrl?: string | null, requestType?: string | null, rejectionReasons?: Array<string | null> | null, user?: { __typename?: 'User', name: string, email: string } | null, store?: { __typename?: 'Store', id: string, name: string, cnpj?: string | null, address: string, complement?: string | null, storeType?: { __typename?: 'StoreType', id: string, name: string } | null, geolocation?: { __typename?: 'Location', latitude: string, longitude: string } | null, image?: { __typename?: 'S3ImageRequest', url?: string | null, headers?: { __typename?: 'S3ImageHeader', X_Amz_Content_Sha256?: string | null, X_Amz_Date?: string | null, Authorization?: string | null, Host?: string | null } | null } | null, city?: { __typename?: 'City', id: string, name: string, state: { __typename?: 'State', id: string, name: string, federativeUnit: string } } | null } | null } | null> | null } | null };

export type SearchStoresQueryVariables = Exact<{
  params: SearchStoresInput;
}>;


export type SearchStoresQuery = { __typename?: 'Query', searchStores?: { __typename?: 'SearchStoresResponse', nextToken?: string | null, stores: Array<{ __typename?: 'Store', id: string, name: string, cnpj?: string | null, address: string, complement?: string | null, storeType?: { __typename?: 'StoreType', id: string, name: string } | null, geolocation?: { __typename?: 'Location', latitude: string, longitude: string } | null, image?: { __typename?: 'S3ImageRequest', url?: string | null, headers?: { __typename?: 'S3ImageHeader', X_Amz_Content_Sha256?: string | null, X_Amz_Date?: string | null, Authorization?: string | null, Host?: string | null } | null } | null, city?: { __typename?: 'City', id: string, name: string, state: { __typename?: 'State', id: string, name: string, federativeUnit: string } } | null } | null> } | null };

export type ValidateOfferBatchQueryVariables = Exact<{
  params: ValidateOfferBatchInput;
}>;


export type ValidateOfferBatchQuery = { __typename?: 'Query', validateOfferBatch?: { __typename?: 'ValidateOfferBatchResponse', authorizationKey?: string | null, invalidatedOffers?: Array<{ __typename?: 'ValidatedOffer', offerId?: string | null, barcode: string, offerTypeValue: string, error?: { __typename?: 'Error', message: string, code: number, name: string } | null }> | null, validatedOffers?: Array<{ __typename?: 'ValidatedOffer', offerId?: string | null, barcode: string, offerTypeValue: string }> | null } | null };

export type ValidateStoreBatchQueryVariables = Exact<{
  params: ValidateStoreBatchInput;
}>;


export type ValidateStoreBatchQuery = { __typename?: 'Query', validateStoreBatch?: { __typename?: 'ValidateStoreBatchResponse', authorizationKey?: string | null, validatedStores: Array<{ __typename?: 'ValidatedStore', errors?: Array<{ __typename?: 'Error', code: number, message: string }> | null }> } | null };

export const StoreFragFragmentDoc = gql`
    fragment storeFrag on Store {
  id
  name
  cnpj
  address
  complement
  storeType {
    id
    name
  }
  geolocation {
    latitude
    longitude
  }
  image {
    url
    headers {
      X_Amz_Content_Sha256
      X_Amz_Date
      Authorization
      Host
    }
  }
  city {
    id
    name
    state {
      id
      name
      federativeUnit
    }
  }
}
    `;
export const CreateOfferBatchDocument = gql`
    mutation CreateOfferBatch($params: CreateOfferBatchInput!) {
  createOfferBatch(params: $params) {
    offers {
      id
    }
    errors {
      error {
        code
        message
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfferBatchGQL extends Apollo.Mutation<CreateOfferBatchMutation, CreateOfferBatchMutationVariables> {
    document = CreateOfferBatchDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOfferDocument = gql`
    mutation createOffer($params: CreateOfferInput!) {
  createOffer(params: $params) {
    error {
      code
      message
    }
    offer {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfferGQL extends Apollo.Mutation<CreateOfferMutation, CreateOfferMutationVariables> {
    document = CreateOfferDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStoreBatchDocument = gql`
    mutation CreateStoreBatch($params: CreateStoreBatchInput!) {
  createStoreBatch(params: $params) {
    stores {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStoreBatchGQL extends Apollo.Mutation<CreateStoreBatchMutation, CreateStoreBatchMutationVariables> {
    document = CreateStoreBatchDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStoreRequestBatchDocument = gql`
    mutation createStoreRequestBatch($params: CreateStoreRequestBatchInput!) {
  createStoreRequestBatch(params: $params) {
    storeRequests {
      adminComment
      analysisStatus
      createdAt
      documentUrl
      id
      rejectionReasons
      requestType
      updatedAt
      store {
        ...storeFrag
      }
    }
  }
}
    ${StoreFragFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStoreRequestBatchGQL extends Apollo.Mutation<CreateStoreRequestBatchMutation, CreateStoreRequestBatchMutationVariables> {
    document = CreateStoreRequestBatchDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStoreDocument = gql`
    mutation createStore($params: CreateStoreInput!) {
  createStore(params: $params) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStoreGQL extends Apollo.Mutation<CreateStoreMutation, CreateStoreMutationVariables> {
    document = CreateStoreDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOfferBatchDocument = gql`
    mutation deleteOfferBatch($params: DeleteOfferBatchInput!) {
  deleteOfferBatch(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOfferBatchGQL extends Apollo.Mutation<DeleteOfferBatchMutation, DeleteOfferBatchMutationVariables> {
    document = DeleteOfferBatchDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOfferDocument = gql`
    mutation deleteOffer($params: DeleteOfferInput!) {
  deleteOffer(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOfferGQL extends Apollo.Mutation<DeleteOfferMutation, DeleteOfferMutationVariables> {
    document = DeleteOfferDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteStoreBatchDocument = gql`
    mutation deleteStoreBatch($params: DeleteStoreBatchInput!) {
  deleteStoreBatch(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteStoreBatchGQL extends Apollo.Mutation<DeleteStoreBatchMutation, DeleteStoreBatchMutationVariables> {
    document = DeleteStoreBatchDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteStoreDocument = gql`
    mutation deleteStore($params: DeleteStoreInput!) {
  deleteStore(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteStoreGQL extends Apollo.Mutation<DeleteStoreMutation, DeleteStoreMutationVariables> {
    document = DeleteStoreDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOfferDocument = gql`
    mutation updateOffer($params: UpdateOfferInput!) {
  updateOffer(params: $params) {
    id
    price
    take
    pay
    saleTypeValue
    offerTypeValue
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOfferGQL extends Apollo.Mutation<UpdateOfferMutation, UpdateOfferMutationVariables> {
    document = UpdateOfferDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStoreRequestDocument = gql`
    mutation updateStoreRequest($params: UpdateStoreRequestInput!) {
  updateStoreRequest(params: $params) {
    id
    adminComment
    analysisStatus
    documentUrl
    requestType
    store {
      id
      address
      cnpj
      phone
      name
    }
    user {
      name
      email
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStoreRequestGQL extends Apollo.Mutation<UpdateStoreRequestMutation, UpdateStoreRequestMutationVariables> {
    document = UpdateStoreRequestDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStoreDocument = gql`
    mutation updateStore($params: UpdateStoreInput!) {
  updateStore(params: $params) {
    id
    name
    address
    complement
    image {
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStoreGQL extends Apollo.Mutation<UpdateStoreMutation, UpdateStoreMutationVariables> {
    document = UpdateStoreDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCitiesByStateIdDocument = gql`
    query getCitiesByStateId($params: GetCitiesByStateIdInput!) {
  getCitiesByStateId(params: $params) {
    cities {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCitiesByStateIdGQL extends Apollo.Query<GetCitiesByStateIdQuery, GetCitiesByStateIdQueryVariables> {
    document = GetCitiesByStateIdDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStateCityByLatLongDocument = gql`
    query GetStateCityByLatLong($params: GetStateCityByLatLongInput!) {
  getStateCityByLatLong(params: $params) {
    country
    state
    city
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStateCityByLatLongGQL extends Apollo.Query<GetStateCityByLatLongQuery, GetStateCityByLatLongQueryVariables> {
    document = GetStateCityByLatLongDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStatesDocument = gql`
    query getStates($params: GetStatesInput!) {
  getStates(params: $params) {
    states {
      federativeUnit
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStatesGQL extends Apollo.Query<GetStatesQuery, GetStatesQueryVariables> {
    document = GetStatesDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStoreTypesDocument = gql`
    query getStoreTypes {
  getStoreTypes {
    types {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStoreTypesGQL extends Apollo.Query<GetStoreTypesQuery, GetStoreTypesQueryVariables> {
    document = GetStoreTypesDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStoreDocument = gql`
    query getStore($params: GetStoreInput!) {
  getStore(params: $params) {
    id
    name
    address
    storeType {
      id
    }
    cityId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStoreGQL extends Apollo.Query<GetStoreQuery, GetStoreQueryVariables> {
    document = GetStoreDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUploadUrlDocument = gql`
    query getUploadUrl($params: GetUploadUrlInput!) {
  getUploadUrl(params: $params) {
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUploadUrlGQL extends Apollo.Query<GetUploadUrlQuery, GetUploadUrlQueryVariables> {
    document = GetUploadUrlDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductsWOffersDocument = gql`
    query searchProductsWOffers($params: SearchProductsInput!) {
  searchProducts(params: $params) {
    products {
      id
      name
      image {
        url
        headers {
          X_Amz_Content_Sha256
          X_Amz_Date
          Authorization
          Host
        }
      }
      measurementValue
      measurement {
        symbol
      }
      barcode
      offers {
        id
        offerTypeValue
        pay
        price
        take
        initialTime
        endingTime
      }
    }
    nextToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsWOffersGQL extends Apollo.Query<SearchProductsWOffersQuery, SearchProductsWOffersQueryVariables> {
    document = SearchProductsWOffersDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductsDocument = gql`
    query searchProducts($params: SearchProductsInput!) {
  searchProducts(params: $params) {
    products {
      id
      name
      image {
        url
        headers {
          X_Amz_Content_Sha256
          X_Amz_Date
          Authorization
          Host
        }
      }
      barcode
      measurementValue
      measurement {
        id
        name
        symbol
        description
      }
      subCategory {
        id
        category {
          id
        }
      }
      brand
      model
    }
    nextToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsGQL extends Apollo.Query<SearchProductsQuery, SearchProductsQueryVariables> {
    document = SearchProductsDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchStoreRequestsDocument = gql`
    query searchStoreRequests($params: SearchStoreRequestsInput!) {
  searchStoreRequests(params: $params) {
    nextToken
    storeRequests {
      id
      adminComment
      analysisStatus
      createdAt
      updatedAt
      documentUrl
      requestType
      rejectionReasons
      user {
        name
        email
      }
      store {
        ...storeFrag
      }
      user {
        name
        email
      }
    }
  }
}
    ${StoreFragFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchStoreRequestsGQL extends Apollo.Query<SearchStoreRequestsQuery, SearchStoreRequestsQueryVariables> {
    document = SearchStoreRequestsDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchStoresDocument = gql`
    query searchStores($params: SearchStoresInput!) {
  searchStores(params: $params) {
    stores {
      ...storeFrag
    }
    nextToken
  }
}
    ${StoreFragFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchStoresGQL extends Apollo.Query<SearchStoresQuery, SearchStoresQueryVariables> {
    document = SearchStoresDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateOfferBatchDocument = gql`
    query validateOfferBatch($params: ValidateOfferBatchInput!) {
  validateOfferBatch(params: $params) {
    authorizationKey
    invalidatedOffers {
      offerId
      barcode
      offerTypeValue
      error {
        message
        code
        name
      }
    }
    validatedOffers {
      offerId
      barcode
      offerTypeValue
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateOfferBatchGQL extends Apollo.Query<ValidateOfferBatchQuery, ValidateOfferBatchQueryVariables> {
    document = ValidateOfferBatchDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateStoreBatchDocument = gql`
    query validateStoreBatch($params: ValidateStoreBatchInput!) {
  validateStoreBatch(params: $params) {
    validatedStores {
      errors {
        code
        message
      }
    }
    authorizationKey
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateStoreBatchGQL extends Apollo.Query<ValidateStoreBatchQuery, ValidateStoreBatchQueryVariables> {
    document = ValidateStoreBatchDocument;
    client = 'stores';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    