<app-brand-area>
  <div
    class="col order-first order-xl-last text-center d-flex justify-content-center"
    id="login_area"
  >
    <div class="card">
      <img
        class="dark-img"
        src="assets/dark-theme/account/backoffice_logo_login_dark.svg"
        alt="my promo logo dark"
      />
      <img
        class="light-img"
        src="assets/light-theme/logo_light.svg"
        alt="my promo logo white"
      />

      <div class="title text-center">
        <h3 [ngClass]="{ error: responseError }">{{ title }}</h3>
        <p [ngClass]="{ error: responseError }">
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</app-brand-area>
