<app-to-go-back path="/conta/esqueci-senha"></app-to-go-back>
<div class="content_area d-grid justify-content-center text-center">
  <div class="check-email">
    <img
      src="assets/dark-theme/account/backoffice_logo_login_dark.svg"
      alt="Logo My Promo"
      id="logo"
    />
    <h1>Confira seu e-mail</h1>
    <p class="code-sent">
      Enviamos as instruções para recuperação da conta para
      <br />
      <span>{{ encryptedEmail }}</span>
    </p>

    <p class="click-to-send">
      Não recebeu o e-mail? <span><a (click)="handleClickResendEmail()" [ngClass]="desactiveButton">Clique para reenviar</a></span>
    </p>
    <p class="time" [ngClass]="timerDisplay">{{ time }}s</p>
  </div>
  <ng-template #snackbar class="alert"></ng-template>
</div>
