import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountModule } from '@app/features/account/account.module';
import { BackofficeModule } from '@app/features/backoffice/backoffice.module';
import { LoginAuthGuard } from '@core/guards/login-auth.guard';
import { NewPasswordComponent } from './features/account/reset-password/subpages/new-password/new-password.component';
import { ConfirmationEmailGuard } from './core/guards/confirmation-email.guard';
import { HomeComponent } from './shared/components/home/home.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'conta',
    loadChildren: (): Promise<AccountModule> =>
      import('./features/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'backoffice',
    loadChildren: (): Promise<BackofficeModule> =>
      import('./features/backoffice/backoffice.module').then(
        (m) => m.BackofficeModule,
      ),
    canActivate: [ LoginAuthGuard ],
  },
  {
    path: 'resetPassword',
    component: NewPasswordComponent,
    canActivate: [ ConfirmationEmailGuard ],
    canLoad: [ ConfirmationEmailGuard ],
  },
  {
    path: '**',
    redirectTo: 'backoffice',
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
