import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  Route,
  CanLoad,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationEmailGuard implements CanActivate, CanLoad {
  isAuth = false;

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const email = route.queryParams['email'];

    if (email) {
      return (this.isAuth = true);
    }

    this.router.navigate([ '/conta/login' ]);
  }

  canLoad(route: Route): boolean {
    return this.isAuth;
  }
}
