<app-brand-area>
  <div class="col order-first order-xl-last text-center d-flex justify-content-center" id="login_area">
    <div class="card">
      <img src="assets/dark-theme/account/backoffice_logo_login_dark.svg" alt="my promo logo" />

      <div class="title text-start">
        <h3 class="font-subtitle-regular">Criar conta</h3>
        <p class="font-subtitle-regular">Já possui uma conta? <span (click)="navigateToLogin()">Fazer login</span></p>
      </div>

      <form (ngSubmit)="submitForm()" [formGroup]="createUserForm" name="createUserForm$" data-cy="form-add">
        <app-form-text-input controlName="name" label="Nome e sobrenome"
          [inputError]="handleFormError(createUserForm.get('name'))" dataCy="input-name">
        </app-form-text-input>

        <app-form-text-input controlName="email" label="E-mail"
          [inputError]="handleFormError(createUserForm.get('email'))" dataCy="input-email">
        </app-form-text-input>

        <app-form-text-input controlName="password" [inputType]="hide ? 'password' : 'text'" label="Senha"
          [inputError]="handleFormError(createUserForm.get('password'))"
          [iconName]="hide ? 'visibility_off' : 'visibility'" (iconClick)="hide = !hide" dataCy="input-password"
          (focusInput)="openTooltip = $event">
        </app-form-text-input>

        <app-form-text-input controlName="confirmPassword" [inputType]="hide ? 'password' : 'text'"
          label="Confirmar senha" [inputError]="handleFormError(createUserForm.get('confirmPassword'))"
          [iconName]="hide ? 'visibility_off' : 'visibility'" (iconClick)="hide = !hide"
          dataCy="input-confirm-password"></app-form-text-input>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="checkbox">
          <label class="form-check-label" for="flexCheckDefault">
            <p class="terms-text font-subtitle-regular">
              Li e concordo com os <span>Termos e Condições de Uso</span> e <span>Políticas de privacidade.</span>
            </p>
          </label>
        </div>

        <app-form-submit-button title="Criar conta" [loading]="loading" [disabled]="createUserForm.invalid || loading">
        </app-form-submit-button>
      </form>
      <div class="pass-tooltip">
        <app-password-tooltip [controlPassword]="createUserForm.get('password')" *ngIf="openTooltip"></app-password-tooltip>
      </div>
    </div>
    <div class="snackbar">
      <ng-template #snackbar></ng-template>
    </div>
  </div>
</app-brand-area>
