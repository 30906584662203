import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { ProductsService } from '@services/products.service';


@Injectable({
  providedIn: 'root',
})
export class FormBarcodeService {
  barcode = new BehaviorSubject<string>('');

  barCodeNotSetted = new BehaviorSubject<boolean>(true);
  showInputBarcode = new BehaviorSubject<boolean>(false);
  barcodeLoading = new BehaviorSubject<boolean>(false);
  barcodeValidated = new BehaviorSubject<boolean>(false);
  inputIsReadOnlySub = new BehaviorSubject<boolean>(false);

  constructor(
    private productsService: ProductsService,
    private snackbarService: SnackbarService,
  ) {}

  resetValues(): void {
    this.barcode.next('');
    this.barCodeNotSetted.next(true);
    this.showInputBarcode.next(false);
    this.barcodeLoading.next(false);
    this.barcodeValidated.next(false);
    this.inputIsReadOnlySub.next(false);
  }

  setWrittenBarcode(): void {
    const barcodeNotAllowed =
      this.barcode.getValue().length < 6 ||
      this.barcode.getValue().length > 43;
    if (barcodeNotAllowed) return;

    this.barcodeLoading.next(true);
    this.productsService.validateBarcode(this.barcode.getValue()).subscribe(
      ({
        data: {
          getProductByBarcode: {
            product: { id },
          },
        },
      }) => {
        this.barcodeLoading.next(false);
        if (id) this.snackbarService.errorSnackbar('Este produto já está cadastrado em nosso sistema.');
      },
      (error) => {
        const handledError = this.fetchAndHandleErrors(error);
        this.barcodeLoading.next(false);
        if (
          handledError.message ===
          `Product com barcode ${this.barcode.getValue()} não encontrado(a)`
        ) {
          this.barcodeValidated.next(true);
          this.barCodeNotSetted.next(false);
        } else {
          this.snackbarService.errorSnackbar('Ocorreu um erro. Por favor, tente novamente.');
        }

        this.barcodeLoading.next(false);
      },
    );
  }

  fetchAndHandleErrors(err: any): any {
    const error = Object.getOwnPropertyNames(err).reduce((acc, key) => {
      acc[key] = err[key];
      return acc;
    }, {});
    if (error['networkError']) return null;
    const errorInfo = error['graphQLErrors'][0]['errorInfo'];
    return {
      code: errorInfo?.code,
      message: errorInfo?.message,
      name: errorInfo?.name,
    };
  }

  showInput(): void {
    this.showInputBarcode.next(true);
  }

  inputIsReadOnly(): BehaviorSubject<boolean> {
    this.inputIsReadOnlySub.next(
      this.barcodeValidated.getValue() || this.barcodeLoading.getValue(),
    );
    return this.inputIsReadOnlySub;
  }
}
