import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopupService } from '@app/core/services/popup.service';
import { AppbarChanges } from '@services/appbarChanges.service';


interface NavButton {
  title: string;
  value: string;
}

@Component({
  selector: 'app-add-object',
  templateUrl: './add-object.component.html',
  styleUrls: [ './add-object.component.scss' ],
})
export class AddObjectComponent implements OnInit {
  @Input() title: string;
  @Input() defaultOption = 'single';
  @Output() optionSelected = new EventEmitter<string>();
  @Input() canChange: boolean;
  additionType: string;

  additionButtons: NavButton[] = [
    {
      title: 'Adicionar manualmente',
      value: 'single',
    },
    {
      title: 'Adicionar via planilha',
      value: 'group',
    },
  ];

  constructor(
    private appbarChanges: AppbarChanges,
    private popupService: PopupService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.additionType = this.defaultOption;
    this.optionSelected.emit(this.additionType);
    this.appbarChanges.setAppbar(this.title);
  }

  async changeAdditionType(type: string): Promise<void> {
    const changeType = (): void => {
      this.additionType = type;
      this.optionSelected.emit(type);
    };

    if (!this.canChange) {
      changeType();
    } else {
      this.popupService.confirmFormChanged(changeType);
    }
  }
}
