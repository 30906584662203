<!-- <mat-checkbox class="">Acessórios</mat-checkbox> -->

<label matRipple class="form-check d-flex align-items-center" [ngClass]="{ 'selected': selected }">
  <div matRipple class="square" data-cy="select-item">
    <input class="form-check-input"
      type="checkbox"
      [(ngModel)]="selected"
      (ngModelChange)="selectedChange.emit(selected)"
      data-cy="input-check-item"
    >
  </div>
    {{ item.name }}
</label>
