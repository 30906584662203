<div class="container">
  <div class="align-items-center">
    <h2 class="font-title-medium dialog_title" mat-dialog-title>{{ title }}</h2>
    <p [innerHTML]="description"></p>
    <div class="d-flex justify-content-around">
      <button mat-dialog-close>{{ cancelText }}</button>
      <button
        (click)="onConfirmButton()"
        data-cy="button-confirm"
        *ngIf="buttonTitle"
      >
        <img
          *ngIf="buttonIcon"
          [src]="buttonIcon"
          alt="ícone botão de confirmação"
        />{{ buttonTitle }}
      </button>
    </div>
  </div>
</div>
