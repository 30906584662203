<mat-form-field
  appearance="outline"
  class="mat_bottom_form"
  (click)="picker.open()"
  [ngClass]="{
    error: currentControl.invalid && currentControl.touched,
    empty: !!!currentControl.value,
    disabled: currentControl.disabled
  }"
>
  <mat-label>{{ label }}</mat-label>
  <input
    class="font-subtitle-regular"
    (focusout)="onFocusOut()"
    (focusin)="onFocusIn()"
    [formControl]="currentControl"
    [matDatepicker]="picker"
    [disabled]="true"
    [min]="min"
    matInput
  />
  <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="false">
    <mat-icon class="icon" matDatepickerToggleIcon
      >keyboard_arrow_down</mat-icon
    >
  </mat-datepicker-toggle>
  <mat-datepicker #picker [disabled]="false"></mat-datepicker>

  <mat-error
    *ngIf="!currentControl.valid && inputError"
    class="input_error_message font-subtitle-medium-14"
    >{{ handleInputError(inputError) }}</mat-error
  >
</mat-form-field>
