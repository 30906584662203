import { Location } from '@angular/common';
import { handleErrors } from '@app/shared/utils/handleErrors';
import { SnackbarService } from '@core/services/snackbar.service';
import { UserAccountService } from '@services/user-account.service';
import { RequestPasswordRecoveryInput } from '@shared/graphql/users/users-graphql';
import { Exact } from '@shared/graphql/products/products-graphql';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { interval, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-check-email',
  templateUrl: './check-email.component.html',
  styleUrls: [ './check-email.component.scss' ],
})
export class CheckEmailComponent implements OnInit {
  @ViewChild('snackbar', { read: ViewContainerRef, static: true })
  snackbar: ViewContainerRef;

  encryptedEmail: string;
  email: string;
  time = 60;
  activeResendEmail = true;
  timerDisplay = 'd-none';
  contentHeight = 'contentHeight368';
  desactiveButton = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private userAccountService: UserAccountService,
    private snackbarService: SnackbarService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.email = params.email;
      this.handleEncryptedEmail(params.email);
    });
    this.snackbarService.setRootViewContainerRef(this.snackbar);
    this.location.replaceState('/conta/verificar-email');
  }

  handleEncryptedEmail(email: string): void {
    let splitEmail = email.split('@');
    let caracter = splitEmail[0].slice(0, 1);
    this.encryptedEmail = `${caracter}********@${splitEmail[1]}`;
  }

  handleClickResendEmail(): void {
    if (this.time === 60) {
      this.resendEmail();
      this.desactiveButton = 'desactiveButton';
      this.timerDisplay = 'd-block';
      this.activeResendEmail = false;
      interval(1000)
        .pipe(untilDestroyed(this), takeUntil(timer(62000)))
        .subscribe((val) => {
          this.time = 60 - val;
          if (val === 60) {
            this.desactiveButton = '';
            this.timerDisplay = 'd-none';
            this.activeResendEmail = true;
            this.time = 60;
          }
        });
    } else {
      this.snackbarService.errorSnackbar(
        `Reenvio bloqueado por ${this.time}s`,
      );
    }
  }

  resendEmail(): void {
    const params: Exact<{ params: RequestPasswordRecoveryInput }> = {
      params: { emailObject: { email: this.email, permissionLevel: 'BACKOFFICE_USER' } },
    };
    this.userAccountService.sendEmail(params).subscribe(
      () =>
        this.snackbarService.successSnackbar('E-mail reenviado com sucesso!'),
      (err) => {
        const error = handleErrors(err);
        if (error.canRetry)
          this.snackbarService.errorSnackbar(
            'Erro ao reenviar e-mail. Tente novamente.');
        else this.snackbarService.errorSnackbar(error.message);
      },
    );
  }
}
