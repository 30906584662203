import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: [ './sort-by.component.scss' ],
})
export class SortByComponent {
  @Input() options = [
    {
      name: 'Ordem A-Z',
      value: 'ASC',
    },
    {
      name: 'Ordem Z-A',
      value: 'DESC',
    },
  ];

  @Input() selected = 'ASC';
  @Output() selectedChange = new EventEmitter<string>();
  @Output() triggerSort = new EventEmitter<any>();

  get selectedOption(): string {
    return this.options.find(item => item.value === this.selected)?.name;
  }

  onSort(optionValue: string): void {
    this.selected = optionValue;
    this.selectedChange.emit(optionValue);
    this.triggerSort.emit();
  }

}
