<div class="title_container d-flex justify-content-start align-items-center">
  <div [class]="'dot d-flex align-items-center justify-content-center dot-' + state">
    <span *ngIf="!sectionValidation else okTemplate" data-cy="text-index">
      {{sectionIndex}}
    </span>
  </div>
  <div [class]="'form-title' + ' title-' + state" data-cy="text-title">
    {{title}}
  </div>
</div>

<ng-template #okTemplate>
  <img alt="INPUT CHECKED AND VALIDATED" class="checked_icon"
    src="assets/dark-theme/products/add-products/single/checked-icon.svg">
</ng-template>
