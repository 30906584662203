<div class="addition-container">
  <div class="header">
    <div class="addition-type-container">
      <button *ngFor="let button of additionButtons"
        class="font-title-medium"
        [ngClass]="{'active': additionType === button.value}"
        (click)="changeAdditionType(button.value)"
      >
        {{button.title}}
      </button>
    </div>
  </div>
  <ng-content></ng-content>
</div>
