<div
  [ngClass]="{
    'dark-theme': storedTheme === 'dark-theme',
    selected: item?.selected,
    'store-hover': page === 'offers'
  }"
  class="store d-flex w-100 justify-content-start align-items-center"
>
  <div class="card-image-container" [ngSwitch]="page">
    <img
      *ngSwitchCase="'requests'"
      class="store-image"
      [src]="image || 'assets/dark-theme/empty_state_empty_request.svg'"
      [alt]="'Foto ' + item.name"
    />
    <img
      *ngSwitchCase="'products'"
      class="product-image"
      [src]="
        image || 'assets/' + storedTheme + '/empty_state_empty_produtos.svg'
      "
      [alt]="'Foto ' + item.name"
    />
    <img
      *ngSwitchDefault
      class="store-image"
      [src]="
        image ||
        'assets/dark-theme/stores/empty-state-empty-local-permiss-o.svg'
      "
      [alt]="'Foto ' + item.name"
    />
  </div>
  <div class="card-info">
    <div class="card-details">
      <span
        class="card-name row"
        data-cy="text-card-name"
        [appHighlight]="isBarcode() ? '' : searchText"
        >{{ page === "requests" ? "Solicitação de " + item.requestType + " de " : ""
        }}{{ item.user?.name || item.name }}</span
      >
      <ng-container *ngTemplateOutlet="tooltip"></ng-container>
      <span *ngIf="page === 'requests'" class="row card-address">{{
        item.store?.name
      }}</span>
      <div *ngIf="page !== 'requests'">
        <span
          [class]="
            item.address !== undefined
              ? 'row card-address'
              : 'row card-measurement'
          "
          data-cy="text-card-address"
          >{{ item.address || item.measurementValue }}
          {{ item.measurement?.symbol }}</span
        >
        <ng-container *ngTemplateOutlet="tooltipAddress"></ng-container>
      </div>
      <div [ngSwitch]="page">
        <div *ngSwitchCase="'requests'">
          <span class="row card-address"> {{ item.store?.address }} </span>
          <ng-container *ngTemplateOutlet="tooltipAddress"></ng-container>
        </div>
        <span
          class="card-barcode row"
          *ngSwitchCase="'products'"
          data-cy="text-card-city"
        >
          Cód.
          <span [appHighlight]="isBarcode() ? searchText : ''">{{
            item.barcode
          }}</span>
        </span>
        <span
          class="card-location row"
          *ngSwitchDefault
          data-cy="text-card-city"
        >
          {{ item.city?.name }} - {{ item.city?.state.name }}
        </span>
      </div>
    </div>
  </div>
  <div class="card-buttons" *ngIf="!selectable; else selectableBox">
    <ng-container *ngIf="page === 'offers'; then storeInOffers"></ng-container>
    <ng-container *ngIf="page === 'stores'; then storeInStores"></ng-container>
    <ng-container *ngIf="page === 'products'; then products"></ng-container>
    <ng-container *ngIf="page === 'requests'; then requests"></ng-container>
  </div>
</div>

<ng-template #selectableBox>
  <span class="square">
    <input
      [checked]="item?.selected"
      class="form-check-input"
      data-cy="input-card-selected"
      type="checkbox"
    />
  </span>
</ng-template>

<ng-template #storeInOffers>
  <button
    matRipple
    class="btn dark-btn open-offer-button"
    data-cy="button-open-offers"
    (click)="openOffers(item)"
  >
    <img
      src="assets/dark-theme/stores/arrow_right.svg"
      alt="Ícone ver ofertas"
    />
  </button>
</ng-template>

<ng-template #storeInStores>
  <button
    matRipple
    class="btn dark-btn edit-button"
    data-cy="button-edit-store"
    (click)="openModal('edit')"
  >
    <img
      src="assets/dark-theme/stores/icon-edit.svg"
      alt="Ícone editar estabelecimento"
    />
  </button>
  <button
    matRipple
    class="btn dark-btn location-button me-4"
    data-cy="button-locate-store"
    (click)="openModal('map')"
  >
    <img
      alt="Ícone localização estabelecimento"
      src="assets/dark-theme/stores/icon-location-on.svg"
    />
  </button>
</ng-template>

<ng-template #products>
  <button
    matRipple
    class="btn dark-btn edit-button me-4"
    data-cy="button-edit-product"
    (click)="openModal('edit')"
  >
    <img
      src="assets/dark-theme/stores/icon-edit.svg"
      alt="Ícone editar estabelecimento"
    />
  </button>
</ng-template>

<ng-template #requests>
  <button
    matRipple
    class="btn dark-btn edit-button me-4"
    data-cy="button-edit-product"
    (click)="openModal('info')"
  >
    <img
      src="assets/dark-theme/exclamation-circle.svg"
      alt="Ícone informações solicitação"
    />
  </button>
</ng-template>

<ng-template #tooltip>
  <div class="tooltip">
    <div class="arrow"></div>
    <p class="font-subtitle-medium card-full-name" data-cy="card-name">
      {{ item.name || item.store?.name }}
    </p>
  </div>
</ng-template>

<ng-template #tooltipAddress>
  <div class="tooltip">
    <div class="arrow"></div>
    <p class="font-subtitle-medium card-full-name" data-cy="card-name">
      {{ item.address || item.store?.address }}
    </p>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div
    class="spinner-container d-flex align-items-center justify-content-center"
  >
    <app-loading-spinner class="spinner"></app-loading-spinner>
  </div>
</ng-template>
