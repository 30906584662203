import { SnackbarService } from '@app/core/services/snackbar.service';
import { UserAccountService } from '@services/user-account.service';
import { DeleteAnotherUserInput, DeleteUserInput } from '@shared/graphql/users/users-graphql';
import { FormValidator } from '@app/core/validators/formValidator';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  Inject,
  OnInit,
  ViewContainerRef,
  ViewChild,
} from '@angular/core';
import { handleFormError } from '@app/shared/utils/handleErrors';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: [ './delete-user-modal.component.scss' ],
})
export class DeleteUserModalComponent implements OnInit {
  @ViewChild('snackbar', { read: ViewContainerRef, static: true })
  snackbar: ViewContainerRef;

  accessToken: string;
  userId: string;
  userPermissionLevel: 'ADMIN' | 'STORE_OWNER';
  handleFormError: any;
  hide = true;
  form: FormGroup;
  loadingSubmit = false;
  deleted = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteUserModalComponent>,
    private userAccountService: UserAccountService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public dataInjected: Record<string, any>,
  ) {}

  ngOnInit(): void {
    this.userId = this.dataInjected[0];
    this.userPermissionLevel = this.dataInjected[1];
    this.accessToken = this.dataInjected[2];
    this.handleFormError = handleFormError;
    this.createForm();
    // Snackbar load
    this.snackbarService.setRootViewContainerRef(this.snackbar);
  }

  createForm(): void {
    this.form = new FormGroup({
      password: new FormControl('', [
        Validators.compose([
          Validators.required,
          // Verifica se a senha tem número
          FormValidator.patternValidator(/\d/, {
            hasNumber: true,
          }),
          // Verifica se a senha tem letra maíuscula
          FormValidator.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          // Verifica se a senha tem letra minúscula
          FormValidator.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          // Verifica se a senha tem caractere especial
          FormValidator.patternValidator(
            /[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\':;|_~`=+\\ ]/,
            {
              hasSpecialCharacters: true,
            },
          ),
          // Verifica se a senha tem espaço no começo
          FormValidator.patternValidator(/^[\S]+.*[\S]+$/, {
            hasSpace: true,
          }),
          Validators.minLength(8),
          Validators.maxLength(70),
        ]),
      ]),
    });
  }

  handleCloseModal(): void {
    this.dialogRef.close({ isDeleted: this.deleted });
  }

  deleteUser(): void {
    this.loadingSubmit = true;
    const password = this.form.get('password').value;
    const params: DeleteAnotherUserInput = {
      adminPassword: password,
      accessToken: this.accessToken,
      userId: this.userId,
      userPermissionLevel: this.userPermissionLevel,
    };

    this.userAccountService
      .deleteAnotherUser(params)
      .toPromise()
      .then(() => {
        this.snackbarService.successSnackbar('Usuário deletado com sucesso!');
        this.deleted = true;
        this.loadingSubmit = false;
        setTimeout(() => { this.handleCloseModal(); }, 2000);
      })
      .catch(() => {
        this.snackbarService.errorSnackbar('Erro ao deletar usuário!');
        this.loadingSubmit = false;
      });
  }

  getTypeText(): string {
    return this.userPermissionLevel === 'ADMIN' ? 'administrador' : 'lojista';
  }
}
