import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { FetchResult } from '@apollo/client/core';
import { RefreshSessionMutation } from '@app/shared/graphql/users/users-graphql';
import { GlobalConstants } from '@assets/globalConstants';
import { UserAccountService } from '@services/user-account.service';

const ONLY_LOGGED_OUT = [ 'login', 'cadastro', 'esqueci-senha' ];

@Injectable({
  providedIn: 'root',
})
export class LoginAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountLoginService: UserAccountService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const currentPath = route.routeConfig.path;
    const isLoggedOutPage = ONLY_LOGGED_OUT.includes(currentPath);

    return new Promise((resolve) => {
      this.validateSession()
        .then((isSessionValid) => {
          const { permissionsLevel } = GlobalConstants.userAttributes;
          if (permissionsLevel.includes('APP_USER')) {
            if (!isLoggedOutPage) {
              this.router.navigate([ '/conta/login' ]);
              return resolve(false);
            }
            return resolve(true);
          }

          if (isSessionValid) {
            if (isLoggedOutPage) {
              this.router.navigate([ '/backoffice/estabelecimentos' ]);
              return resolve(false);
            }
            return resolve(true);
          }

          this.router.navigate([ '/conta/login' ]);
          return resolve(false);
        })
        .catch((error) => {
          if (!isLoggedOutPage) {
            this.router.navigate([ '/conta/login' ]);
            return resolve(false);
          }
          resolve(true);
        });
    });
  }

  private async validateSession(): Promise<boolean> {
    try {
      const {
        data: {
          getUserInfo: { userAttributes },
        },
      } = await this.accountLoginService.getUserInfo().toPromise();

      GlobalConstants.userAttributes = { ...userAttributes };
      return true;
    } catch (_) {
      if (!this.accountLoginService.userAccessToken) return false;

      try {
        const refreshData = await this.refreshSession();

        if (!!refreshData.errors) {
          this.accountLoginService.userData = null;
          return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    }
  }

  private async refreshSession(): Promise<FetchResult<RefreshSessionMutation>> {
    const refreshRes = {} as FetchResult<RefreshSessionMutation>;
    try {
      const {
        data: {
          refreshSession: { accessToken },
        },
      } = await this.accountLoginService.refreshSession().toPromise();
      this.replaceAccessToken(accessToken);
    } catch (err) {
      refreshRes.errors = err.message;
    }
    return refreshRes;
  }

  private replaceAccessToken(accessToken: string): void {
    const oldAccessToken = this.accountLoginService.userData;
    this.accountLoginService.userData = { ...oldAccessToken, accessToken };
  }
}
