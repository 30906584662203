export default [
  {
    id: '9678529a-adf6-408f-a951-5f005feeb3df',
    name: 'Açougue e Peixaria',
    color: '0xFFe65c5c',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_9678529a-adf6-408f-a951-5f005feeb3df.svg',
    subCategories: [
      {
        id: '5817f2d5-4d87-4b26-8ac2-bc549aa86324',
        name: 'Carne bovina',
      },
      {
        id: 'd9007a6a-2f04-4eaa-b708-a40e3af0b84c',
        name: 'Carne de porco',
      },
      {
        id: '578bc7e0-884a-4c42-b88e-f7bfa3f66044',
        name: 'Frango',
      },
      {
        id: 'b1a7c88d-2fae-439e-8f8b-be9ac19e2490',
        name: 'Frutos do mar',
      },
      {
        id: '2ef325a4-bc5a-4a35-8be5-5f60e368c9f1',
        name: 'Outros',
      },
      {
        id: 'abc3455d-db40-4131-9065-ba0cbeb3dd45',
        name: 'Peixes',
      },
    ],
  },
  {
    id: '9a8ecc95-713d-4471-9538-4839cd41cb19',
    name: 'Bazar e Utilidades',
    color: '0xFF9fcc5e',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_9a8ecc95-713d-4471-9538-4839cd41cb19.svg',
    subCategories: [
      {
        id: 'b0f8792e-27ac-4bb9-9d6f-c2f67e8ac5e3',
        name: 'Automotivo',
      },
      {
        id: '0c5f8ec5-7123-4c10-bc22-1ae9779071f8',
        name: 'Brinquedos',
      },
      {
        id: 'f5249423-7a8d-42cb-8a41-7633cb590ad2',
        name: 'Cama, Mesa e Banho',
      },
      {
        id: '51c24e6d-b46a-4c54-b14b-8ffe53b29499',
        name: 'Churrasco',
      },
      {
        id: 'ba451128-3edc-4b70-93d4-a879da34a459',
        name: 'Construção',
      },
      {
        id: 'af247ed3-6264-4041-ad09-4f11e843a7f4',
        name: 'Decoração da Casa',
      },
      {
        id: '3cd90c81-139a-4054-a9c1-177638080536',
        name: 'Descartáveis e artigos de festa',
      },
      {
        id: 'dd35c16b-ba5a-4799-a1b5-1ef8162fb3ef',
        name: 'Ferramentas',
      },
      {
        id: 'a05182ba-e272-46a3-9b75-7b0567f36b37',
        name: 'Jardinagem',
      },
      {
        id: '22965be8-b5e7-4444-a988-9bf0a437773b',
        name: 'Lâmpadas e Velas',
      },
      {
        id: '5c37b52f-c8b4-4a73-9ea3-53dca9a3512a',
        name: 'Material Escolar',
      },
      {
        id: '38c8c3e5-13b7-4043-b4f8-ee982694368d',
        name: 'Organizadores',
      },
      {
        id: 'c3c66443-ed21-4173-b57d-8844f05071cd',
        name: 'Outros',
      },
      {
        id: '7fadce37-5751-4cff-8eec-5438f1506b42',
        name: 'Para banheiro',
      },
      {
        id: 'dcb8e9ef-fd86-4606-941d-8e61df3bb091',
        name: 'Para cozinha',
      },
      {
        id: 'ec27ab4c-e27a-47a6-97f5-0938741a2d6c',
        name: 'Para lavanderia',
      },
      {
        id: '460e9a92-d715-45bc-b812-651a3e2c0c05',
        name: 'Reparos Domésticos',
      },
    ],
  },
  {
    id: 'c2bea7e3-93bb-4fc1-a7ea-838d76d8886f',
    name: 'Bebidas',
    color: '0xFF9a6658',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_c2bea7e3-93bb-4fc1-a7ea-838d76d8886f.svg',
    subCategories: [
      {
        id: '8f8dac44-9d7a-4635-ab0c-d9314a6d0646',
        name: 'Águas',
      },
      {
        id: '62f0341b-1c37-49be-b554-1903371e2da9',
        name: 'Cervejas',
      },
      {
        id: 'f1da44ee-3c56-4de0-bd1c-e92d60af9f9e',
        name: 'Destilados',
      },
      {
        id: '0ccc9423-623d-409e-bcc4-0756c4a8ac53',
        name: 'Energéticos e Isotônicos',
      },
      {
        id: 'd72935a2-2788-482d-8d6b-8a86c58d7c24',
        name: 'Outras',
      },
      {
        id: 'a11baf87-b23d-430b-8eb4-9a37244180ca',
        name: 'Refrigerantes',
      },
      {
        id: '0cb7c485-86f1-43ab-afee-d4cc6bbbe424',
        name: 'Sucos e Refrescos em pó',
      },
      {
        id: 'b30a7068-4832-49cb-a59d-e09e97aaada0',
        name: 'Vinhos e Espumantes',
      },
    ],
  },
  {
    id: '983abc27-7e5a-4bec-8798-07f6475b68b3',
    name: 'Combustível',
    color: '0xFF78909C',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_983abc27-7e5a-4bec-8798-07f6475b68b3.svg',
    subCategories: [
      {
        id: '1307f5f0-7719-4bc0-b967-3492371c472b',
        name: 'Diesel',
      },
      {
        id: '9c354c1d-8480-4d9f-9772-1325993ada54',
        name: 'Etanol',
      },
      {
        id: '74f8e992-1a46-48e0-9cdb-956ee5a4d205',
        name: 'Gasolina',
      },
      {
        id: '5054aafb-0fc3-4a33-b9c8-7df8e09bdff5',
        name: 'GNV',
      },
    ],
  },
  {
    id: 'f4bfcf5a-d500-45b1-b774-c03a2f5a501d',
    name: 'Frios e Congelados',
    color: '0xFF68a6f0',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_f4bfcf5a-d500-45b1-b774-c03a2f5a501d.svg',
    subCategories: [
      {
        id: '249b7e06-75e7-466a-a683-9bf7b7721ee0',
        name: 'Carnes congeladas e Empanados',
      },
      {
        id: 'b06a109a-536a-42d5-81cc-94bc3e9fe06e',
        name: 'Embutidos',
      },
      {
        id: '5e09c81d-53ae-4f38-8cef-bf86eb0e9a98',
        name: 'Frios',
      },
      {
        id: 'a532a497-5dbf-4a04-a38c-da8c830cf808',
        name: 'Frutas congeladas',
      },
      {
        id: '110d21ba-cc51-469f-a4d2-3184a62a3b7e',
        name: 'Legumes congelados e Conservas',
      },
      {
        id: 'b04615e0-3934-4424-98d4-3653cdfa5216',
        name: 'Prontos e Semiprontos',
      },
      {
        id: '1f5e47b4-0b8f-46c2-962b-a8ad67a60498',
        name: 'Sorvetes',
      },
    ],
  },
  {
    id: '0908e214-4038-47e1-81ad-c4d0b348bbcc',
    name: 'Hortifruti',
    color: '0xFF47ac75',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_0908e214-4038-47e1-81ad-c4d0b348bbcc.svg',
    subCategories: [
      {
        id: '0c78360b-a6f0-483a-a88a-8057b05d888f',
        name: 'Castanhas e Frutas secas',
      },
      {
        id: '733923e0-3c98-4829-bf02-7e8bd7353f81',
        name: 'Cogumelos, Soja e Derivados',
      },
      {
        id: '27b73ffd-0e02-43dd-afb7-86c30ad0bb73',
        name: 'Ervas frescas e Temperos',
      },
      {
        id: '1b64472a-2799-4318-aa4d-7e89b3777722',
        name: 'Frutas',
      },
      {
        id: '00760769-5a1c-43c0-a713-cbd98db90179',
        name: 'Legumes',
      },
      {
        id: '9a40cffc-cd84-4aff-aa29-7aa69a28c63c',
        name: 'Outros',
      },
      {
        id: '4e74a348-c38d-4f13-a4d0-b8cab8212dea',
        name: 'Ovos',
      },
      {
        id: 'f8d45433-3855-4d88-8cd1-4458309523b1',
        name: 'Verduras',
      },
    ],
  },
  {
    id: 'a7993131-2b49-416c-a178-2c7d95ca1594',
    name: 'Infantil',
    color: '0xFFed698b',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_a7993131-2b49-416c-a178-2c7d95ca1594.svg',
    subCategories: [
      {
        id: '768849a7-bac5-422e-8361-c897bc5eee23',
        name: 'Acessórios',
      },
      {
        id: '746c0b1c-1bfa-41e7-be88-8d6bfe8a375a',
        name: 'Alimentação',
      },
      {
        id: '5b49a2bd-c492-423c-a67e-85bfb857a91f',
        name: 'Fraldas',
      },
      {
        id: 'e9173e2b-316d-43fb-827a-848f8f45021e',
        name: 'Higiene e Cuidados',
      },
    ],
  },
  {
    id: '02f09cc1-265e-468b-aaa3-152bfd59260f',
    name: 'Laticínios',
    color: '0xFFf0c763',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_02f09cc1-265e-468b-aaa3-152bfd59260f.svg',
    subCategories: [
      {
        id: '1648bee9-c3a4-4d12-9134-6a0d5c8f80cb',
        name: 'Bebidas Lácteas',
      },
      {
        id: '80af2cc2-796e-4eef-9387-e3c5a1d01103',
        name: 'Derivados',
      },
      {
        id: 'a8a1660e-47ac-4bd1-a421-ccd0ae3cd4bb',
        name: 'Iogurtes',
      },
      {
        id: '56f6a29e-4c32-4dfc-baac-ffb9f3fb374c',
        name: 'Leites',
      },
      {
        id: 'b4735b5a-dd14-495b-869a-566576ba0f7a',
        name: 'Manteigas e Margarinas',
      },
      {
        id: '73eb6dfc-c305-401f-9fad-e67d5319d597',
        name: 'Outros',
      },
      {
        id: '9dded0c6-fdc8-4dc0-bcfc-aed1db9eb57e',
        name: 'Queijos e Requeijões',
      },
    ],
  },
  {
    id: '1d17df5b-6847-4c15-bb72-a9022e0b8619',
    name: 'Limpeza',
    color: '0xFF62c0dd',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_1d17df5b-6847-4c15-bb72-a9022e0b8619.svg',
    subCategories: [
      {
        id: '7018aa15-cd75-4368-9f20-981f942617ca',
        name: 'Acessórios',
      },
      {
        id: 'f6f187a6-684a-4868-9354-5d1a96b28f8b',
        name: 'Casa',
      },
      {
        id: 'edda6219-0be5-489d-9d54-efd71ab50fc2',
        name: 'Descartáveis',
      },
      {
        id: 'b69b8df2-a749-4b12-b6a6-f6ff0e4e2ca9',
        name: 'Inseticidas e Repelentes',
      },
      {
        id: '3a2256cc-f2d5-4db5-b693-e408d28bc22d',
        name: 'Lavanderia',
      },
      {
        id: 'e6460cb9-7963-477b-a216-d0c719211529',
        name: 'Produtos Automotivos',
      },
      {
        id: 'b64cbf7c-8647-483b-b54c-b8ae57f45282',
        name: 'Sapatos',
      },
    ],
  },
  {
    id: '0c425838-f003-40e2-8b9a-71543255e4d3',
    name: 'Mercearia',
    color: '0xFFf1885a',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_0c425838-f003-40e2-8b9a-71543255e4d3.svg',
    subCategories: [
      {
        id: 'f8d69482-d421-4e83-b282-36c93ce3f792',
        name: 'Acompanhamentos para lanches',
      },
      {
        id: 'ccb40bc2-e6f3-48b3-90f3-c1b23bf72898',
        name: 'Açúcares e Adoçantes',
      },
      {
        id: '874d2be9-b807-4aab-ad1d-719e34b88f03',
        name: 'Arroz',
      },
      {
        id: 'ee088b77-1e77-4f7f-829e-f6a11708bbee',
        name: 'Azeites, Óleos e Vinagres',
      },
      {
        id: 'dd842de2-3218-4821-b9a7-ab375a369f4a',
        name: 'Balas e Doces',
      },
      {
        id: 'cf88da96-298f-4dd8-8170-d366d13caf0e',
        name: 'Biscoitos e Snacks',
      },
      {
        id: '83fcaad1-f72f-4c06-b13b-97d434d170ee',
        name: 'Café, chás e achocolatados',
      },
      {
        id: '3eb1b4a6-e650-4426-b07c-dab6412d71a7',
        name: 'Chocolates',
      },
      {
        id: '7ad76066-a77b-490a-b2d9-604035ab46ae',
        name: 'Conservas e Enlatados',
      },
      {
        id: '64272555-83ea-4709-b68e-cbf763e400be',
        name: 'Farinhas',
      },
      {
        id: '6f9feca1-b743-4462-a46c-3f1b90b36c07',
        name: 'Feijão',
      },
      {
        id: '50815418-2612-42c4-92e4-d484ae2d2c82',
        name: 'Geleias e Mel',
      },
      {
        id: 'c2910cb3-c291-4574-9628-a695e009e9ee',
        name: 'Grãos, Cereais e Sementes',
      },
      {
        id: '8087ea3e-e537-45fe-abba-065608b03db3',
        name: 'Massas e Molhos',
      },
      {
        id: '820df8f8-eb7e-4ae3-924f-1987df4352e8',
        name: 'Pães, Torradas e Bolos',
      },
      {
        id: '403dac5d-dca7-4bca-88be-390b77ab154b',
        name: 'Sobremesas e Confeitaria',
      },
      {
        id: 'b71475ef-25fc-481f-bf5b-7cda47c4e063',
        name: 'Sopas e Cremes',
      },
      {
        id: '339689b2-d6fc-4620-8e1a-221a95d3a028',
        name: 'Suplementos alimentares',
      },
      {
        id: '8b871941-02b2-4661-8575-7ea3768230a5',
        name: 'Temperos e Condimentos',
      },
    ],
  },
  {
    id: '0c34fb54-8e91-40d8-b8b6-6b6478874079',
    name: 'Perfumaria e Beleza',
    color: '0xFF5ec8b0',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_0c34fb54-8e91-40d8-b8b6-6b6478874079.svg',
    subCategories: [
      {
        id: '186bde89-d835-42a7-a0c8-c04f0b55c2af',
        name: 'Acessórios para banho',
      },
      {
        id: 'd40f4bf2-4d1d-470a-af28-9fac03fea3ad',
        name: 'Acessórios para Cabelo',
      },
      {
        id: 'a79f34ed-f59c-4b16-a735-64820a76bfb7',
        name: 'Barba e Depilação',
      },
      {
        id: '6fed5f7b-691a-405c-b43e-8b2e9e3c6589',
        name: 'Cosméticos',
      },
      {
        id: '633a235b-4689-42db-a3b1-5a620e970b40',
        name: 'Cuidados com a barba',
      },
      {
        id: 'ca9fe42c-371c-4c53-bb61-11ee0673e31e',
        name: 'Desodorantes',
      },
      {
        id: '978a0291-5dc7-481f-ab2d-1f7ad5c16623',
        name: 'Geriátricos',
      },
      {
        id: '3b01a928-c1a5-4cf5-adc7-07e2854966c4',
        name: 'Higiene Bucal',
      },
      {
        id: 'e55d6854-b61b-416d-8106-268bc2abd21e',
        name: 'Higiene Íntima',
      },
      {
        id: '0eb5a796-d666-438b-a4c1-f2e7ef85ece5',
        name: 'Mãos e Pés',
      },
      {
        id: '6cec1d62-a811-45fa-9e43-82d61feba1bd',
        name: 'Outros',
      },
      {
        id: 'f472c2cf-d038-4940-bd85-aa4f7a863550',
        name: 'Papel Higiênico',
      },
      {
        id: 'f1a29286-6336-4d98-9c79-f9a959dc3aa5',
        name: 'Preservativos e Lubrificantes',
      },
      {
        id: 'eace6408-ec46-436e-bc20-406fdaa5269c',
        name: 'Protetores e Repelentes',
      },
      {
        id: 'a2d989c3-6f22-447f-9cb9-668997ea6a54',
        name: 'Sabonetes',
      },
      {
        id: 'f646a000-b394-4b39-8398-f1f127328639',
        name: 'Shampoos e Condicionadores',
      },
      {
        id: '16f1155c-46ef-4fbc-b81b-f60b59ba1cbb',
        name: 'Tinturas e Tratamentos',
      },
    ],
  },
  {
    id: '95613be4-b870-4982-b8cb-deceffd8a8b2',
    name: 'Pets',
    color: '0xFFb377e3',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_95613be4-b870-4982-b8cb-deceffd8a8b2.svg',
    subCategories: [
      {
        id: '89d1806b-bcc4-43f2-9baa-0cf50e066b32',
        name: 'Acessórios',
      },
      {
        id: 'ffc256f6-e481-4c64-95c2-6b5af6ad6b4a',
        name: 'Alimentos',
      },
      {
        id: '8a0c491d-83db-45fc-b5a7-8e39ce4f4a1a',
        name: 'Higiene ',
      },
      {
        id: '9a6978a3-5ae3-4c8d-a59c-a3a1c95cbc67',
        name: 'Medicamentos',
      },
      {
        id: '50ede1d7-e52e-409e-9c89-2a7d42272083',
        name: 'Nutrição',
      },
    ],
  },
  {
    id: '9c7da47c-ccd0-4dd5-a670-d31131634bbb',
    name: 'Remédios',
    color: '0xFF7278e7',
    __typename: 'Category',
    image: 'assets/categoryIcons/ic_9c7da47c-ccd0-4dd5-a670-d31131634bbb.svg',
    subCategories: [
      {
        id: '46459838-adbe-4605-9ec4-bc93e3d42078',
        name: 'Alergias e Infecções',
      },
      {
        id: '783cfe72-f190-4898-af62-838e1778a781',
        name: 'Anticoncepcionais',
      },
      {
        id: '93f35391-dca8-4a20-9c21-9ac0fd39f139',
        name: 'Anti-inflamatórios',
      },
      {
        id: 'de31ca0d-b213-48fa-8a9b-b750c374fe74',
        name: 'Aparelhos e Testes',
      },
      {
        id: '3680ac05-8aad-4373-be36-9e9d1dcecac5',
        name: 'Boca e Garganta',
      },
      {
        id: 'dcbd9fa3-f0be-415e-a824-bee8db92a565',
        name: 'Diabetes',
      },
      {
        id: 'f02376b3-f763-4050-95a4-59535c5a46f3',
        name: 'Dor e Febre',
      },
      {
        id: '04781224-93d4-4ef8-a251-6c5ab55e80f7',
        name: 'Estômago e Intestino',
      },
      {
        id: 'e8b2545b-beb4-4c38-ace4-244d9538d995',
        name: 'Gripe e Resfriado',
      },
      {
        id: '79b42055-d09f-4cca-bbce-aa0b9ced2580',
        name: 'Hipertensão',
      },
      {
        id: 'c710b1d8-6502-4733-ae41-f9b5c936e0b5',
        name: 'Nariz e Pulmão',
      },
      {
        id: 'feda138e-591b-4d73-ac71-cfb9f288697b',
        name: 'Olhos',
      },
      {
        id: '01c22cb8-ec53-4ea3-a262-14566bac978e',
        name: 'Ortopedia e Acessórios',
      },
      {
        id: 'a7c9df31-a3d5-46e2-9ce3-412c2d608d20',
        name: 'Outros',
      },
      {
        id: '92b102ae-a718-4a57-8c5e-7defa89fc331',
        name: 'Primeiros Socorros',
      },
      {
        id: '1ea22e64-e0ef-48c2-b189-392e155dafea',
        name: 'Vitaminas',
      },
    ],
  },
];
