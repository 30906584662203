import { StoreRequestAnalysisStatus } from '@app/shared/interfaces/backoffice/stores/requests';

//ENG:PTBR
export const StoreTypesTranslator: Record<string, string> = {
  art_gallery: 'galeria de arte',
  bakery: 'padaria',
  bar: 'bar',
  bicycle_store: 'bicicletaria',
  book_store: 'livraria',
  cafe: 'cafeteria',
  car_dealer: 'concessionária de carros',
  clothing_store: 'loja de roupas',
  convenience_store: 'loja de conveniência',
  department_store: 'loja de departamento',
  electronics_store: 'loja de eletrônicos',
  florist: 'floricultura',
  furniture_store: 'loja de móveis',
  gas_station: 'posto de gasolina',
  hardware_store: 'loja de ferramentas',
  home_goods_store: 'loja de bens para o lar',
  jewelry_store: 'joalheria',
  liquor_store: 'adega',
  meal_delivery: 'entrega de refeição',
  drugstore: 'drogaria',
  pharmacy: 'farmácia',
  restaurant: 'restaurante',
  shoe_store: 'sapataria',
  shopping_mall: 'shopping',
  storage: 'armazém',
  store: 'loja',
  supermarket: 'supermercado',
  grocery_or_supermarket: 'mercado ou supermercado',
  meal_takeaway: 'retirada de refeição',
};

export const StoreRequestsStatusTranslator: Record<
  StoreRequestAnalysisStatus,
  string
> = {
  NOT_STARTED: 'Não iniciado',
  PENDING: 'Pendente',
  ACCEPTED: 'Aprovado',
  REJECTED: 'Reprovado',
};
