import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';

import searchProducts from '@assets/mocks/searchProducts.json';
import searchStores from '@assets/mocks/searchStores.json';
import searchProductsWOffers from '@assets/mocks/searchProductsWOffers.json';
import getStatesbyCityId from '@assets/mocks/getCitiesByStateId.json';
import searchStoreRequests from '@assets/mocks/searchStoreRequests.json';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  operationName: any;

  constructor() {
    this.operationName = {
      searchProducts: searchProducts,
      searchProductsWOffers: searchProductsWOffers,
      searchStores: searchStores,
      getStatesbyCityId: getStatesbyCityId,
      searchStoreRequests: searchStoreRequests,
    };
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (environment.MOCK) {
      const operationName = request.body['operationName'];

      if (this.operationName[operationName]) {
        return of(
          new HttpResponse({
            body: this.operationName[operationName],
            status: 200,
          }),
        );
      }
    }

    return next.handle(request);
  }
}
