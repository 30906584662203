export type StoreTypesLocal = {
  id: string;
  name: string;
};

export default [
  {
      'id': 'f20006ae-c0be-4acf-9d6f-4a534c865a09',
      'name': 'contabilidade',
  },
  {
      'id': '1e23c3e3-8f74-4306-aa1c-9e7d81b41c43',
      'name': 'aeroporto',
  },
  {
      'id': 'f5570856-f4c4-4220-b89a-deda7c008f12',
      'name': 'parque de diversões',
  },
  {
      'id': 'b888deaa-43eb-489b-9cd2-b5aa62293378',
      'name': 'aquário',
  },
  {
      'id': '1d2e2025-707f-4599-a382-4ad6fda8f713',
      'name': 'galeria de arte',
  },
  {
      'id': '54b980b2-5a15-4f74-9156-85ec674ed3ae',
      'name': 'caixa eletrônico',
  },
  {
      'id': 'b153558a-80bc-490d-9532-6eef73ca2592',
      'name': 'padaria',
  },
  {
      'id': 'fa5c8a44-956e-4e7e-aef0-28d10c78ae64',
      'name': 'banco',
  },
  {
      'id': 'e8996cf3-8dc1-43aa-9f9f-72b409aa9c61',
      'name': 'bar',
  },
  {
      'id': '7a6b8d40-c0c8-4909-93d6-117415143a25',
      'name': 'salão de beleza',
  },
  {
      'id': '55feb44d-26f4-4f75-a1fa-18609b10b2b4',
      'name': 'loja de bicicletas',
  },
  {
      'id': 'bed75b3f-0f4c-4bc4-98b3-065b2c1d6016',
      'name': 'livraria',
  },
  {
      'id': '734da1e6-5478-4e34-a8ba-973113fad329',
      'name': 'pista de boliche',
  },
  {
      'id': '540dc9c3-1635-44f8-b21d-a86364837a0b',
      'name': 'estação de onibus',
  },
  {
      'id': '13270eba-26a4-4829-8d08-d0598a747811',
      'name': 'cafeteria',
  },
  {
      'id': '58db63ed-b517-4d98-9a75-5b8a963fb431',
      'name': 'área de camping',
  },
  {
      'id': '3d834f17-27b5-48d8-8fee-eca296d0e613',
      'name': 'vendedor de carros',
  },
  {
      'id': 'ee4c349c-cc87-4a06-8b2b-55e0033d3c0d',
      'name': 'aluguel de carros',
  },
  {
      'id': '68b70a8e-32b8-4816-a866-ccb538cbbc74',
      'name': 'mecânico',
  },
  {
      'id': 'b7a7a466-10d5-4e91-bc21-00351265a31b',
      'name': 'lava-jato',
  },
  {
      'id': '95680c2b-91dc-4af4-b3af-598b08e3a621',
      'name': 'cassino',
  },
  {
      'id': 'b19b8f07-d0fe-4a9f-8122-0088624d3ddf',
      'name': 'cemitério',
  },
  {
      'id': 'f8876d31-413f-43f5-bce1-7f86bf559f14',
      'name': 'igreja',
  },
  {
      'id': 'bda31d53-243d-4c48-bf1a-b6bbbdc057c1',
      'name': 'câmara municipal',
  },
  {
      'id': 'bdfe499b-8917-4d01-be67-7ae40f58daf2',
      'name': 'loja de roupas',
  },
  {
      'id': 'd0f27575-1762-4d6d-a940-849684bdc021',
      'name': 'loja de conveniência',
  },
  {
      'id': 'f5a37a1b-5c5b-4571-8510-0ae1929e45c2',
      'name': 'tribunal',
  },
  {
      'id': 'b324705c-7673-4202-a626-f56ef0824246',
      'name': 'dentista',
  },
  {
      'id': '820681aa-3af0-4309-b207-58dc19395905',
      'name': 'loja de departamento',
  },
  {
      'id': '54576f48-4127-4f7f-ae74-69742fbc09eb',
      'name': 'médico',
  },
  {
      'id': '22571606-9908-454d-b50b-e26d9e655164',
      'name': 'farmácia',
  },
  {
      'id': '94e664e7-b20c-495d-b301-684ca2ebb20c',
      'name': 'eletricista',
  },
  {
      'id': '1770e314-ae05-4008-a026-0b024540bccd',
      'name': 'loja de eletrônicos',
  },
  {
      'id': '09faf68e-f61a-462a-81db-c20b918985ef',
      'name': 'embaixada',
  },
  {
      'id': '8c8624e3-ab7b-4541-9c2d-2a218238b3f5',
      'name': 'corpo de bombeiros',
  },
  {
      'id': 'ceb2ddbc-0da8-4e1f-a0f8-3d67832d192c',
      'name': 'florista',
  },
  {
      'id': 'eadbec0d-f90c-4771-acfc-c401032a504c',
      'name': 'casa funerária',
  },
  {
      'id': 'ed15fa20-84d9-4637-bc07-4a0262c3e5c2',
      'name': 'loja de móveis',
  },
  {
      'id': 'b3755786-9c62-436d-a6ca-f9dc0acb0ae9',
      'name': 'posto de gasolina',
  },
  {
      'id': '02a778c5-c8b2-4647-b507-7028b667c5da',
      'name': 'academia',
  },
  {
      'id': '654ed5f3-9f53-4793-8478-5a5cc310b7b6',
      'name': 'salão de beleza',
  },
  {
      'id': '199d6d8e-cd80-4827-ab1e-ca03c2e6a768',
      'name': 'loja de hardware.',
  },
  {
      'id': '4e280059-5ebd-4c24-9c36-05ea66244104',
      'name': 'templo hindu',
  },
  {
      'id': '77859855-f26e-469d-a625-1c8b966c80f8',
      'name': 'loja de mercadorias em casa.',
  },
  {
      'id': 'b90c3cdb-9606-414b-bea3-3959a8960947',
      'name': 'hospital',
  },
  {
      'id': 'a7d76f1c-552e-4bfb-b346-53d76ec8cf0e',
      'name': 'agência de seguros',
  },
  {
      'id': 'd0ffbf8c-e912-42a7-b7d2-2f5a156b1d53',
      'name': 'joalheria',
  },
  {
      'id': '2bf3ddc2-6868-44a8-b3cc-99a63ed9b455',
      'name': 'lavanderia de roupa',
  },
  {
      'id': 'f783f285-03c6-4667-a58f-a61adccdbe86',
      'name': 'biblioteca',
  },
  {
      'id': 'cd4361f3-141b-433f-b24b-ebcb84196516',
      'name': 'estação ferroviária elétrica',
  },
  {
      'id': '8d5a7e27-e2c8-492f-b7de-15969e3b83e0',
      'name': 'loja de bebidas',
  },
  {
      'id': 'ce94aa20-2c6c-4610-8430-62cd9c5e2027',
      'name': 'escritório do governo local.',
  },
  {
      'id': 'ce2c774d-8bfb-4da6-99a1-933529f1c1be',
      'name': 'chaveiro',
  },
  {
      'id': '15d1d112-1fc5-4cab-b9d2-3e3c1cc59d8d',
      'name': 'alojamento',
  },
  {
      'id': '6fb405db-eaa1-423b-a7c7-c830ff75bff3',
      'name': 'entrega de comida',
  },
  {
      'id': 'f5c92936-3016-4abb-98e9-95454905472f',
      'name': 'refeições pague e leve',
  },
  {
      'id': '3281a311-9191-436d-a877-a574af865abd',
      'name': 'mesquita',
  },
  {
      'id': '1129d844-5ef9-422b-931b-ece408a57daf',
      'name': 'aluguer de filmes.',
  },
  {
      'id': 'f2ea7a71-c754-4a18-8f6d-7137bc980b11',
      'name': 'cinema',
  },
  {
      'id': '4ee3af07-453c-4f78-a088-09991fda7002',
      'name': 'empresa em movimento',
  },
  {
      'id': '563fee2f-98d6-43e1-86f8-db719ee28b23',
      'name': 'museu',
  },
  {
      'id': '41678650-9ac8-41a5-900c-523481c324c7',
      'name': 'boate',
  },
  {
      'id': '9025cfd2-bdb2-4d69-b57a-53acfc3c3ffc',
      'name': 'pintor',
  },
  {
      'id': '949213dc-0c52-40b2-9c37-26ca2d8e5a83',
      'name': 'parque',
  },
  {
      'id': 'd49b7ae3-630b-427a-9bb8-9023a9b61db3',
      'name': 'estacionamento',
  },
  {
      'id': '50f203e0-3725-41d0-896f-73da6adda6a2',
      'name': 'loja de animais',
  },
  {
      'id': 'ed58acc0-8eb4-4eae-8595-17cedea8bb25',
      'name': 'farmacia',
  },
  {
      'id': '8da558da-30a8-4f30-9c9c-2443f5bcaf07',
      'name': 'fisioterapeuta',
  },
  {
      'id': 'c71da6a3-df62-4b6c-9923-55c8ad74f048',
      'name': 'encanador',
  },
  {
      'id': 'ec568958-b8db-4107-8a64-7d9411b71154',
      'name': 'polícia',
  },
  {
      'id': '313609b3-3520-49dd-9a87-d673c702ca9e',
      'name': 'correios',
  },
  {
      'id': 'cf691e8b-387f-4b82-acfb-84d71ea025ad',
      'name': 'escola primária',
  },
  {
      'id': 'fbfe40f9-b896-460f-ad43-b52f567b93b0',
      'name': 'agência imobiliária',
  },
  {
      'id': '75110071-1bc6-4e83-9863-33cbad1d0c09',
      'name': 'restaurante',
  },
  {
      'id': 'f2db420b-2005-44d7-ba6e-61fccfa4f303',
      'name': 'empreiteiro de telhado',
  },
  {
      'id': '73a82b2e-c281-49b7-aa70-b54ba29170cd',
      'name': 'parque de trailers',
  },
  {
      'id': 'c1c70f4f-e24a-4ea2-9756-65415fa699eb',
      'name': 'escola',
  },
  {
      'id': '8eb84cf6-d157-492f-a776-8e3b2cbf09ab',
      'name': 'ensino médio',
  },
  {
      'id': '2833825a-293d-4da0-848e-fb5fdc7ad3dc',
      'name': 'loja de sapatos',
  },
  {
      'id': '50bd64b5-c9bf-425f-a7ec-67b14dfebc70',
      'name': 'shopping',
  },
  {
      'id': 'aa011279-8976-435a-9ed5-11eec2a9b70b',
      'name': 'spa',
  },
  {
      'id': 'cba60c28-8a46-4a9e-950a-a7c14885b379',
      'name': 'estádio',
  },
  {
      'id': '401f4267-cf9a-4ae8-96bc-75e0ad4ce9da',
      'name': 'armazem',
  },
  {
      'id': 'f23e9b43-697a-4344-8a90-1afc9f58dece',
      'name': 'loja',
  },
  {
      'id': '19fd37a7-8b22-4703-a2cd-5879cacc3c07',
      'name': 'estação de metrô',
  },
  {
      'id': 'f8c9bf4f-102b-422b-947a-3b047bf4d335',
      'name': 'supermercado',
  },
  {
      'id': '8bd91b2a-5170-4e3c-a43c-93dc38b0e45f',
      'name': 'sinagoga',
  },
  {
      'id': '04994d77-0d53-478d-9c4f-5dbcb1e87295',
      'name': 'ponto de taxi',
  },
  {
      'id': 'c1caab58-c9ae-4e2b-a725-c7c040df5e5d',
      'name': 'atração turística',
  },
  {
      'id': 'a04b4a01-1e7e-457e-989a-5f19b7003e80',
      'name': 'estação de trem',
  },
  {
      'id': '62443785-26a9-4608-b177-c895cb4e6f52',
      'name': 'estação de trânsito',
  },
  {
      'id': '99158c6f-b66e-4651-a986-7854425ddb2b',
      'name': 'agência de viagens',
  },
  {
      'id': '525dc115-c8d5-4987-ac5d-b794133c2623',
      'name': 'universidade',
  },
  {
      'id': '9ff0448b-75f8-47af-a10a-9b11ba1a8943',
      'name': 'veterinário',
  },
  {
      'id': 'a2b6d4a6-0301-4e41-8b43-18e386c4167b',
      'name': 'zoológico',
  },
  {
      'id': '41ad4d47-a4ad-442a-9f16-1eaa35b7d316',
      'name': 'mercado ou supermercado',
  },
] as StoreTypesLocal[];
