import { Location } from '@angular/common';
import { UserAccountService } from '@services/user-account.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { handleErrors } from '@app/shared/utils/handleErrors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-confirmation-email',
  templateUrl: './confirmation-email.component.html',
  styleUrls: ['./confirmation-email.component.scss'],
})
export class ConfirmationEmailComponent implements OnInit {
  message = 'Sua conta está sendo verificada por favor aguarde.';
  title = 'Aguarde um momento';
  responseError = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private account: UserAccountService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.confirmAccount();
    this.location.replaceState('/conta/confirmar-email');
  }

  confirmAccount(): void {
    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        tap(({ code, email, permissionLevel }) => {
          this.account
            .confirmAccount({
              params: {
                code: code || '',
                emailObject: {
                  email: email || '',
                  permissionLevel: permissionLevel,
                },
              },
            })
            .subscribe(
              () => {
                this.title = 'Conta confirmada';
                this.message = 'Seu e-mail foi confirmado com sucesso!';
              },
              (err) => {
                this.title = 'Erro na confirmação';
                this.message = handleErrors(err).message;
                this.responseError = true;
              }
            );
        })
      )
      .subscribe();
  }
}
