import { AppbarButton } from '@app/shared/interfaces/backoffice/appbar';


export class CreateAppbarButton {
  static adicionarButton = (title: string = 'Novo Item', cb: (args?: any) => void = null, color: string = null) : AppbarButton => {
    return {
      iconPath: 'icon_adicionar.svg',
      label: title,
      alt: title,
      color: color,
      handleClick: cb,
    };
  };

  // descontinuado no protótipo (?)
  static buscarButton = (title: string = 'Buscar', cb: (args?: any) => void, color: string = null) : AppbarButton => {
    return {
      iconPath: 'icon_busca.svg',
      label: title,
      alt: title,
      color: color,
      handleClick: cb,
    };
  };

  static deletarButton = (title: string = 'Apagar itens', cb: (args?: any) => void, color: string = null) : AppbarButton => {
    return {
      iconPath: 'icon_deletar.svg',
      label: title,
      alt: title,
      color: color,
      handleClick: cb,
    };
  };

  // descontinuado no protótipo (?)
  static cancelarButton = (title: string = null, cb: (args?: any) => void, color: string = null) : AppbarButton => {
    return {
      iconPath: 'icon_cancelar.svg',
      label: title,
      alt: title,
      color: color,
      handleClick: cb,
    };
  };

  static historicoButton = (title: string, cb: (args?: any) => void = null, color: string = null) : AppbarButton => {
    return {
      iconPath: 'icon_history.svg',
      label: title,
      alt: title,
      color: color,
      handleClick: cb,
    };
  };
}
