<div class="dropdown" *ngIf="options">
  <button matRipple class="btn btn-secondary dark-btn"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false">
    <p class="dropdown-toggle ">{{ selectedOption }}</p>
  </button>

  <div class="dropdown-menu dropdown-menu-start" aria-labelledby="dropdownMenuButton1" *ngIf="options">
    <ul>
      <li matRipple class="col-6"
          *ngFor="let option of options; index as index"
          [ngClass]="{'selected': option.value === selected}"
          (click)="onSort(option.value)"
          [attr.data-cy]="'order-by-option-' + index"
        >
          <p>{{option.name}}</p>
      </li>
    </ul>
  </div>
</div>
