/**
 * A função divide um array em diversas partições e
 * insere essas partições em um array, criando uma matriz
 * @param objs array que sofrerá slice
 * @param size tamanho de cada partição
 * @returns uma matriz com os elementos do array original
 * @example (size = 2) [1,2,3,4] => [[1,2], [3,4]]
 */
export function queueSlicer<T>(objs: T[], size: number): T[][] {
  return objs.reduce((acc, _, i) => {
    if (!(i % size)) {
      acc.push(objs.slice(i, i + size).filter(Boolean));
    }
    return acc;
  }, []);
}
