<div *ngIf="!loading;then app else loadingPage"></div>
<ng-template #loadingPage>
  <div
    class="d-flex h-100 justify-content-center align-items-center dark-theme"
  >
    <div class="dropdowns-loading">
      <div class="spinner-border text-secondary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #app>
  <router-outlet
  [ngClass]="{ 'dark-theme': storedTheme === 'dark-theme' }"
></router-outlet>
</ng-template>

