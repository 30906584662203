<div class="header">
  <div class="snackbar">
    <ng-template #snackbar></ng-template>
  </div>
  <div class="template-download-container">
    <a
      class="temp-link dark-btn font-button-medium"
      matRipple
      [href]="templateLink"
      target="_blank"
      data-cy="button-download-template"
    >
      <img
        src="assets/dark-theme/products/add-products/batch/icon_download.svg"
        alt="icone download do template"
      />
      Modelo de planilha
    </a>
  </div>
</div>

<div class="dropzone-container">
  <div tabindex="0">
    <input
      tabindex="0"
      #fileInput
      type="file"
      accept=".csv"
      name="csv-input"
      id="csv-input"
      (change)="uploadFileToValidate($event.target)"
      data-cy="input-csv-file"
    />
    <p>Faça o upload de suas planilhas</p>
    <p>Apenas arquivos .csv são permitidos</p>
    <img
      src="assets/dark-theme/products/add-products/batch/empty_state_upload.svg"
      alt="icone upload de aquivos"
    />
    <p>
      Arraste um arquivo até aqui ou <span>procure-o</span> em seu computador
    </p>
  </div>
</div>

<div class="file-upload-process-container">
  <div
    class="file-loader-container"
    *ngIf="!!(batchUpload.currentFile | async)"
  >
    <img
      src="assets/dark-theme/products/add-products/batch/file_csv.svg"
      alt=""
    />
    <div>
      <div>
        <h4 data-cy="text-file-name">
          {{ (batchUpload.currentFile | async).name }}
        </h4>
        <span
          >{{ batchUpload.uploadProgress | async | number: "1.0-2" }} %</span
        >
      </div>

      <div class="progress-bar">
        <div
          class="progress"
          [ngStyle]="{ width: (batchUpload.uploadProgress | async) + '%' }"
        ></div>
      </div>

      <span data-cy="text-file-size">{{
        (batchUpload.currentFile | async).size | filesize
      }}</span>
    </div>
    <div>
      <button
        data-cy="button-file-cancel-upload"
        alt="Botão cancelar upload de arquivo"
        *ngIf="(batchUpload.uploadSuccess | async) === false"
        (click)="cancelFileUpload()"
      >
        <img
          src="assets/dark-theme/products/add-products/batch/times.svg"
          alt="Imagem cancelamento de envio"
        />
      </button>
      <img
        class="failImg"
        *ngIf="
          (batchUpload.uploadSuccess | async) && batchUpload.uploadFailure
            | async
        "
        src="assets/dark-theme/products/add-products/batch/exclamation_circle.svg"
        alt="Erro no upload"
      />
      <img
        class="successImg"
        *ngIf="
          (batchUpload.uploadSuccess | async) &&
          (batchUpload.uploadFailure | async) === false
        "
        src="assets/dark-theme/products/add-products/batch/check.svg"
        alt="Upload feito com sucesso"
      />
    </div>
  </div>

  <div
    class="file-errors-container"
    *ngIf="
      (batchUpload.currentFile | async) &&
      (batchUpload.itemAdditionErrors | async).length
    "
  >
    <h5>
      <span data-cy="error-products-length">{{
        (batchUpload.itemAdditionErrors | async).length
      }}</span
      >{{
        (batchUpload.itemAdditionErrors | async).length > 1
          ? " erros encontrados"
          : " erro encontrado"
      }}
    </h5>
    <p>
      {{ errorTableParams.title === "ofertas" ? "Suas" : "Seus" }}
      {{ errorTableParams.title }} não foram cadastrados devido aos erros
      abaixo. Conserte-os e suba o arquivo novamente.
    </p>

    <div class="table-head">
      <table>
        <thead>
          <tr>
            <th>{{ errorTableParams.firstColName }}</th>
            <th>{{ errorTableParams.secondColName }}</th>
            <th>Coluna/Linha</th>
            <th>Erro</th>
          </tr>
        </thead>
      </table>
    </div>

    <div class="table-body">
      <table>
        <tbody>
          <tr *ngFor="let error of batchUpload.itemAdditionErrors | async">
            <td data-cy="error-product-name">{{ error.name }}</td>
            <td data-cy="error-product-codebar">{{ error.main }}</td>
            <td data-cy="error-position">{{ error.position }}</td>
            <td>
              <span
                mat-raised-button
                [matTooltip]="error.message"
                matTooltipClass="file-info-error-message"
                matTooltipPosition="right"
                class="error"
                data-cy="error-code"
                >{{ error.code }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    class="float-end d-flex"
    *ngIf="
      !!(batchUpload.currentFile | async) &&
      !(batchUpload.itemAdditionErrors | async).length
    "
  >
    <button
      class="btn btn-discart-file"
      (click)="discardFile()"
      *ngIf="batchUpload.uploadSuccess | async"
      [disabled]="isSubmiting"
    >
      Descartar planilha
    </button>
    <button
      class="progress-bar"
      id="submit-progress"
      (click)="handleMainButton()"
      [disabled]="getMainButtonIsDisabled()"
      [ngClass]="{ 'btn-send': !isSubmiting }"
    >
      <p>{{ getMainButtonTitle() }}</p>
      <span
        class="progress"
        [ngStyle]="{
          width: (batchUpload.itemsQueueAdditionProgress.progress | async) + '%'
        }"
      ></span>
    </button>
  </div>
</div>
