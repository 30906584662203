import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private labelsData = new Map<string, string>();

  getData(key: string): string {
    return this.labelsData.get(key);
  }

  setData(key: string, data: string): void {
    this.labelsData.set(key, data);
  }

  resetData(): void {
    this.labelsData.set(null, null);
  }
}
