import { FormValidator } from '@app/core/validators/formValidator';
import { mergeMap } from 'rxjs/operators';
import { Subscription, throwError, of } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignInResponse } from '@app/shared/graphql/users/users-graphql';
import { UserAccountService } from '@services/user-account.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { UserData } from '@shared/interfaces/backoffice/users/usersData';
import { handleErrors, handleFormError } from '@shared/utils/handleErrors';
import { Regex } from '@shared/utils/regex';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: [ './login-page.component.scss' ],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  @ViewChild('snackbar', { read: ViewContainerRef, static: true })
  snackbar: ViewContainerRef;

  loading = false;
  loginForm: FormGroup;
  hide = true;

  accessToken: string;
  refreshToken: string;

  errorCode: number;
  handleFormError: any;

  submitSubscription$: Subscription;

  constructor(
    private accountLoginService: UserAccountService,
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  submitLogin(): void {
    if (this.loading) return;
    this.loading = true;
    const { email, password } = this.loginForm.value;
    const params = { emailObject: { email, permissionLevel: 'BACKOFFICE_USER' }, password };

    this.submitSubscription$ = this.accountLoginService
      .doLogin(params)
      .subscribe(
        ({ data: { signIn } }) => {
          this.setUserData(signIn);
          this.loading = false;
          this.router.navigateByUrl('/backoffice/inicio');
        },
        (err) => {
          const error = handleErrors(err);
          if (error.code === 15) {
            setTimeout(() => {
              this.snackbarService.errorSnackbar(
                'Seu e-mail ou senha estão incorretos. Confira seus dados e tente novamente.',
              );
            }, 2000);
          } else {
            setTimeout(() => {
              this.snackbarService.errorSnackbar(
                error.message.includes('Esta conta') ? error.message : 'Ocorreu um erro ao tentar fazer login. Tente novamente mais tarde.',
              );
            }, 2000);
          }
          setTimeout(() => {
            this.loading = false;
          }, 3000);
        },
      );
  }

  ngOnInit(): void {
    this.handleFormError = handleFormError;
    this.startFormGroup();
    this.snackbarService.setRootViewContainerRef(this.snackbar);
  }

  navigateToCreateUser(): void {
    this.router.navigateByUrl('/conta/cadastro');
  }

  ngOnDestroy(): void {
    if (this.submitSubscription$) this.submitSubscription$.unsubscribe();
  }

  handleInputErrors(field: string): any {
    if (
      this.loginForm.get(field).value.length === 0 ||
      this.loginForm.get(field).value.length >= 8
    ) {
      return this.handleFormError(this.loginForm.get(field));
    }
    return;
  }

  private startFormGroup(): void {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          FormValidator.patternValidator(Regex.emailRegex, {
            emailWithoutSpecialCharacters: true,
          }),
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          FormValidator.patternValidator(Regex.passwordRegex, {
            password: true,
          }),
        ]),
      ],
    });
  }

  private setUserData(signIn: SignInResponse): void {
    const userLocalData: UserData = {
      accessToken: signIn.token.accessToken,
      refreshToken: signIn.token.refreshToken,
      user_data: {
        'USER-email': signIn.user.email,
        'USER-name': signIn.user.name,
        'USER-permission': signIn.user.permissionsLevel,
      },
    };
    this.accountLoginService.userData = userLocalData;
  }
}
