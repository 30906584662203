// This file is generated, Do not edit manually.
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
}

export interface AccessoriesInput {
  material?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface AccessoryCleaningInput {
  aroma?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<DimensionInput>;
  material?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
}

export interface AccessoryInput {
  color?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<DimensionInput>;
  material?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  size?: InputMaybe<Scalars['String']>;
  typeOfSale: TypeOfSaleInput;
}

export interface AlcoholicDrinkInput {
  alcoholicStrength: Scalars['Float'];
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface ApparatusAndTestsInput {
  power?: InputMaybe<Scalars['Float']>;
  voltage?: InputMaybe<Scalars['Float']>;
}

export interface AromaticBeautyInput {
  aroma?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface AutomotiveInput {
  color?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<BazaarDimensionWhInput>;
  material?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Float']>;
  typeOfSale: TypeOfSaleInput;
  voltage?: InputMaybe<Scalars['Float']>;
}

export interface BarbecueInput {
  color?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<BazaarDimensionWhInput>;
  material?: InputMaybe<Scalars['String']>;
  typeOfSale: TypeOfSaleInput;
}

export interface BazaarAndUtilitiesInput {
  automotive?: InputMaybe<AutomotiveInput>;
  barbecue?: InputMaybe<BarbecueInput>;
  bedTableBath?: InputMaybe<BedTableBathInput>;
  construction?: InputMaybe<ConstructionInput>;
  disposablesAndPartySupplies?: InputMaybe<OrganizationSuppliesInput>;
  garden?: InputMaybe<GardenInput>;
  homeDecor?: InputMaybe<HomeDecorInput>;
  homeRepairs?: InputMaybe<HomeRepairsInput>;
  kitchen?: InputMaybe<KitchenInput>;
  lampAndCandle?: InputMaybe<LampAndCandleInput>;
  laundry?: InputMaybe<LaundryInput>;
  organization?: InputMaybe<OrganizationSuppliesInput>;
  otherBazaar?: InputMaybe<OtherBazaarInput>;
  restroom?: InputMaybe<RestroomInput>;
  school?: InputMaybe<KidInput>;
  tools?: InputMaybe<ToolsInput>;
  toy?: InputMaybe<KidInput>;
}

export interface BazaarDimensionWhdInput {
  diameter: Scalars['Float'];
  height: Scalars['Float'];
  width: Scalars['Float'];
}

export interface BazaarDimensionWhDepInput {
  depth: Scalars['Float'];
  height: Scalars['Float'];
  width: Scalars['Float'];
}

export interface BazaarDimensionWhInput {
  height: Scalars['Float'];
  width: Scalars['Float'];
}

export interface BazaarDimensionWHopDepInput {
  depth: Scalars['Float'];
  height?: InputMaybe<Scalars['Float']>;
  width: Scalars['Float'];
}

export interface BedTableBathInput {
  color: Scalars['String'];
  composition: Scalars['String'];
  dimension?: InputMaybe<BazaarDimensionWHopDepInput>;
  typeOfSale: TypeOfSaleInput;
}

export interface BreadsAndToastAndCakesInput {
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface ButcherAndFishShopInput {
  beef?: InputMaybe<DefaultButcherInput>;
  butcherOuther?: InputMaybe<DefaultButcherInput>;
  chicken?: InputMaybe<DefaultButcherInput>;
  fish?: InputMaybe<DefaultButcherInput>;
  pork?: InputMaybe<DefaultButcherInput>;
  seafood?: InputMaybe<DefaultButcherInput>;
}

export interface CandyInput {
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface Category {
  __typename?: 'Category';
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  name: Scalars['String'];
  subCategories?: Maybe<Array<Maybe<SubCategory>>>;
}

export interface CategoryProduct {
  __typename?: 'CategoryProduct';
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  name: Scalars['String'];
  subCategory: SubCategory;
}

export interface ChildishInput {
  accessory?: InputMaybe<AccessoryInput>;
  diaper?: InputMaybe<DiaperInput>;
  food?: InputMaybe<FoodInput>;
  hygieneAndCare?: InputMaybe<HygieneAndCareInput>;
}

export interface CleaningInput {
  accessoryCleaning?: InputMaybe<AccessoryCleaningInput>;
  automotiveCleaning?: InputMaybe<DefaultCleaningInput>;
  disposable?: InputMaybe<DisposableInput>;
  homeCleaning?: InputMaybe<DefaultCleaningInput>;
  insecticidesAndRepellents?: InputMaybe<InsecticidesAndRepellentsInput>;
  laundryCleaning?: InputMaybe<DefaultCleaningInput>;
  shoesCleaning?: InputMaybe<ShoesCleaningInput>;
}

export enum ClothesSizesEnum {
  G = 'G',
  Gg = 'GG',
  L = 'L',
  M = 'M',
  P = 'P',
  Pp = 'PP',
  S = 'S',
  Xgg = 'XGG',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL'
}

export interface ColdAndFrozenInput {
  coldFood?: InputMaybe<DefaultExtraFieldInput>;
  embeddedFoods?: InputMaybe<DefaultExtraFieldInput>;
  frozenFruit?: InputMaybe<DefaultExtraFieldInput>;
  frozenMeetAndbreaded?: InputMaybe<DefaultExtraFieldInput>;
  frozenVegetablesAndPreserves?: InputMaybe<FrozenVegetablesAndPreservesInput>;
  iceCream?: InputMaybe<IceCreamInput>;
  preparedAndSemiPrepared?: InputMaybe<DefaultExtraFieldInput>;
}

export interface CondomsAndLubricantsInput {
  flavor?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface ConstructionInput {
  color?: InputMaybe<Scalars['String']>;
  dimension: BazaarDimensionWhDepInput;
  material: Scalars['String'];
  typeOfSale: TypeOfSaleInput;
}

export interface CreateProductBatchInput {
  accessToken?: InputMaybe<Scalars['String']>;
  authorizationToken: Scalars['String'];
  products: Array<ProductInput>;
}

export interface CreateProductBatchResponse {
  __typename?: 'CreateProductBatchResponse';
  errors: Array<Maybe<Error>>;
  products: Array<Maybe<Product>>;
}

export interface CreateProductInput {
  accessToken?: InputMaybe<Scalars['String']>;
  barcode: Scalars['String'];
  brand?: InputMaybe<Scalars['String']>;
  categoryID: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  hasImage?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  measurementID?: InputMaybe<Scalars['String']>;
  measurementValue?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  subCategoryID: Scalars['String'];
  weight?: InputMaybe<Scalars['Float']>;
}

export interface DairyDrinksInput {
  flavor: Scalars['String'];
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface DairyInput {
  buttersAndMargarines?: InputMaybe<DefaultExtraFieldInput>;
  cheeseAndCurd?: InputMaybe<DefaultExtraFieldInput>;
  dairyDrinks?: InputMaybe<DairyDrinksInput>;
  derivatives?: InputMaybe<DerivativesInput>;
  milks?: InputMaybe<DefaultExtraFieldInput>;
  othersDairy?: InputMaybe<DefaultExtraFieldInput>;
  yogurt?: InputMaybe<DairyDrinksInput>;
}

export interface DefaultButcherInput {
  pack?: InputMaybe<PackingEnum>;
}

export interface DefaultCleaningInput {
  aroma?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
}

export interface DefaultExtraFieldInput {
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface DefaultFuelInput {
  flag: Scalars['String'];
  fuelType: FuelTypeEnum;
}

export interface DefaultRemedyInput {
  activePrinciple: Scalars['String'];
  dosage: Scalars['Float'];
  manufacturer: Scalars['String'];
  pack?: InputMaybe<PackingEnum>;
  pillAmount: Scalars['Int'];
}

export interface DeleteProductBatchInput {
  accessToken: Scalars['String'];
  barcodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
}

export interface DeleteProductByBarcodeInput {
  accessToken: Scalars['String'];
  barcode: Scalars['String'];
}

export interface DeleteProductByIdInput {
  accessToken: Scalars['String'];
  id: Scalars['ID'];
}

export interface DeodorantInput {
  aroma?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  type?: InputMaybe<DeodorantTypesEnum>;
  typeOfSale: TypeOfSaleInput;
}

export enum DeodorantTypesEnum {
  Aerosol = 'AEROSOL',
  Cream = 'CREAM',
  Rollon = 'ROLLON'
}

export interface DerivativesInput {
  flavor?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface DessertsAndConfectioneryInput {
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface DiaperInput {
  material?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  size?: InputMaybe<Scalars['String']>;
  typeOfSale: TypeOfSaleInput;
}

export interface DimensionInput {
  depth?: InputMaybe<Scalars['Float']>;
  diameter?: InputMaybe<Scalars['Float']>;
  height?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
}

export interface DisposableInput {
  aroma?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<DimensionInput>;
  material?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
}

export interface DrinkInput {
  beer?: InputMaybe<AlcoholicDrinkInput>;
  distilled?: InputMaybe<AlcoholicDrinkInput>;
  energeticAndIsotonic?: InputMaybe<EnergeticAndIsotonicInput>;
  otherDrink?: InputMaybe<OtherDrinkInput>;
  powderedRefreshmentAndJuice?: InputMaybe<FlavoredDrinkInput>;
  soda?: InputMaybe<FlavoredDrinkInput>;
  water?: InputMaybe<DefaultExtraFieldInput>;
  winesAndSparklingWines?: InputMaybe<WinesAndSparklingWinesInput>;
}

export interface EnergeticAndIsotonicInput {
  flavor?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface Error {
  __typename?: 'Error';
  code: Scalars['Int'];
  message: Scalars['String'];
  name: Scalars['String'];
}

export interface FirstAidInput {
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface FlavoredDrinkInput {
  flavor: Scalars['String'];
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface FoodInput {
  flavor?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface FoodSuplementsInput {
  pack?: InputMaybe<PackingEnum>;
  type?: InputMaybe<Scalars['String']>;
  typeOfSale: TypeOfSaleInput;
}

export interface FoodsInput {
  flavor?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface FrozenVegetablesAndPreservesInput {
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
  weightAfterDrainage?: InputMaybe<Scalars['Float']>;
}

export interface FuelInput {
  diesel?: InputMaybe<DefaultFuelInput>;
  ethanol?: InputMaybe<DefaultFuelInput>;
  gasoline?: InputMaybe<DefaultFuelInput>;
}

export enum FuelTypeEnum {
  Additivated = 'ADDITIVATED',
  Regular = 'REGULAR'
}

export interface GardenInput {
  color: Scalars['String'];
  dimension: BazaarDimensionWhDepInput;
  material: Scalars['String'];
  typeOfSale: TypeOfSaleInput;
}

export interface GeriatricInput {
  pack?: InputMaybe<PackingEnum>;
  size?: InputMaybe<ClothesSizesEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface GetProductByBarcodeInput {
  barcode: Scalars['String'];
}

export interface GetProductByIdInput {
  id: Scalars['ID'];
}

export interface GetProductResponse {
  __typename?: 'GetProductResponse';
  product?: Maybe<Product>;
}

export interface GetUploadUrlInput {
  accessToken?: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
}

export interface GroceryStoreInput {
  bean?: InputMaybe<DefaultExtraFieldInput>;
  breadsAndToastAndCakes?: InputMaybe<BreadsAndToastAndCakesInput>;
  candy?: InputMaybe<CandyInput>;
  chocolate?: InputMaybe<DefaultExtraFieldInput>;
  coffeeAndTeaAndChocolate?: InputMaybe<DefaultExtraFieldInput>;
  cookiesAndSnacks?: InputMaybe<DefaultExtraFieldInput>;
  dessertsAndConfectionery?: InputMaybe<DessertsAndConfectioneryInput>;
  flours?: InputMaybe<DefaultExtraFieldInput>;
  foodSuplements?: InputMaybe<FoodSuplementsInput>;
  grainAndCerealsAndSeeds?: InputMaybe<DefaultExtraFieldInput>;
  jelliesAndHoney?: InputMaybe<JelliesAndHoneyInput>;
  oilsAndOilsAndVinegars?: InputMaybe<DefaultExtraFieldInput>;
  pastaAndSauces?: InputMaybe<DefaultExtraFieldInput>;
  preservedAndCanned?: InputMaybe<DefaultExtraFieldInput>;
  rice?: InputMaybe<DefaultExtraFieldInput>;
  snackAccompaniment?: InputMaybe<DefaultExtraFieldInput>;
  soupsAndCreams?: InputMaybe<DefaultExtraFieldInput>;
  spicesAndCondiments?: InputMaybe<DefaultExtraFieldInput>;
  sugarsAndSweeteners?: InputMaybe<DefaultExtraFieldInput>;
}

export interface HomeDecorInput {
  color: Scalars['String'];
  dimension: BazaarDimensionWhInput;
  material?: InputMaybe<Scalars['String']>;
  typeOfSale: TypeOfSaleInput;
}

export interface HomeRepairsInput {
  color?: InputMaybe<Scalars['String']>;
  dimension: BazaarDimensionWhInput;
  material?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Float']>;
  typeOfSale: TypeOfSaleInput;
  voltage?: InputMaybe<Scalars['Float']>;
}

export interface HortifrutiInput {
  eggInput?: InputMaybe<DefaultExtraFieldInput>;
  freshHerbAndSpiceInput?: InputMaybe<DefaultExtraFieldInput>;
  fruitInput?: InputMaybe<DefaultExtraFieldInput>;
  nutAndDriedFruitsInput?: InputMaybe<DefaultExtraFieldInput>;
  otherHortifruitiInput?: InputMaybe<DefaultExtraFieldInput>;
  soyAndMushroomAndDerivativeInput?: InputMaybe<DefaultExtraFieldInput>;
  vegetablesInput?: InputMaybe<DefaultExtraFieldInput>;
  verdureInput?: InputMaybe<DefaultExtraFieldInput>;
}

export interface HortifrutiInputInput {
  egg?: InputMaybe<DefaultExtraFieldInput>;
  freshHerbAndSpice?: InputMaybe<DefaultExtraFieldInput>;
  fruit?: InputMaybe<DefaultExtraFieldInput>;
  nutAndDriedFruits?: InputMaybe<DefaultExtraFieldInput>;
  otherHortifruiti?: InputMaybe<DefaultExtraFieldInput>;
  soyAndMushroomAndDerivative?: InputMaybe<DefaultExtraFieldInput>;
  vegetables?: InputMaybe<DefaultExtraFieldInput>;
  verdure?: InputMaybe<DefaultExtraFieldInput>;
}

export interface HygieneAndCareInput {
  aroma?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface IceCreamInput {
  flavor: Scalars['String'];
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface InsecticidesAndRepellentsInput {
  aroma?: InputMaybe<Scalars['String']>;
  composition?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
}

export interface JelliesAndHoneyInput {
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface KidInput {
  color?: InputMaybe<Scalars['String']>;
  dimension: BazaarDimensionWhDepInput;
  material: Scalars['String'];
  typeOfSale: TypeOfSaleInput;
}

export interface KitchenInput {
  capacity?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  dimension: BazaarDimensionWhdInput;
  material?: InputMaybe<Scalars['String']>;
  typeOfSale: TypeOfSaleInput;
}

export interface LampAndCandleInput {
  color?: InputMaybe<Scalars['String']>;
  dimension: BazaarDimensionWhDepInput;
  material: Scalars['String'];
  power?: InputMaybe<Scalars['Float']>;
  typeOfSale: TypeOfSaleInput;
  voltage?: InputMaybe<Scalars['Float']>;
}

export interface LaundryInput {
  capacity?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<BazaarDimensionWhInput>;
  material?: InputMaybe<Scalars['String']>;
  typeOfSale: TypeOfSaleInput;
}

export interface ListCategoriesResponse {
  __typename?: 'ListCategoriesResponse';
  categories?: Maybe<Array<Maybe<Category>>>;
}

export interface ListMeasureUnitsResponse {
  __typename?: 'ListMeasureUnitsResponse';
  measureUnits?: Maybe<Array<Maybe<MeasureUnit>>>;
}

export interface ListSubCategoriesResponse {
  __typename?: 'ListSubCategoriesResponse';
  subCategories?: Maybe<Array<Maybe<SubCategory>>>;
}

export interface MeasureUnit {
  __typename?: 'MeasureUnit';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  symbol: Scalars['String'];
}

export interface MedicinesInput {
  activePrinciple: Scalars['String'];
  dosage: Scalars['Float'];
  pack?: InputMaybe<PackingEnum>;
  pillAmount: Scalars['Int'];
  typeOfSale: TypeOfSaleInput;
}

export interface Mutation {
  __typename?: 'Mutation';
  createProduct?: Maybe<Product>;
  createProductBatch?: Maybe<CreateProductBatchResponse>;
  deleteProductBatch?: Maybe<Scalars['Boolean']>;
  deleteProductByBarcode?: Maybe<Scalars['Boolean']>;
  deleteProductById?: Maybe<Scalars['Boolean']>;
  updateProduct?: Maybe<Product>;
}


export interface MutationCreateProductArgs {
  params: CreateProductInput;
}


export interface MutationCreateProductBatchArgs {
  params: CreateProductBatchInput;
}


export interface MutationDeleteProductBatchArgs {
  params: DeleteProductBatchInput;
}


export interface MutationDeleteProductByBarcodeArgs {
  params: DeleteProductByBarcodeInput;
}


export interface MutationDeleteProductByIdArgs {
  params: DeleteProductByIdInput;
}


export interface MutationUpdateProductArgs {
  params: UpdateProductInput;
}

export interface OrganizationSuppliesInput {
  capacity?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  dimension: BazaarDimensionWhDepInput;
  material: Scalars['String'];
  typeOfSale: TypeOfSaleInput;
}

export interface OrthopedicsAndAccessoriesInput {
  typeOfSale: TypeOfSaleInput;
}

export interface OtherBazaarInput {
  capacity?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<BazaarDimensionWhDepInput>;
  material: Scalars['String'];
  typeOfSale: TypeOfSaleInput;
}

export interface OtherDrinkInput {
  alcoholicStrength?: InputMaybe<Scalars['Float']>;
  flavor?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  type?: InputMaybe<WinesTypesEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface OthersPerfumeryInput {
  aroma?: InputMaybe<Scalars['String']>;
  clostheSize?: InputMaybe<ClothesSizesEnum>;
  color?: InputMaybe<Scalars['String']>;
  deodorantType?: InputMaybe<DeodorantTypesEnum>;
  flavor?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface OthersRemedyInput {
  activePrinciple?: InputMaybe<Scalars['String']>;
  dosage?: InputMaybe<Scalars['Float']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  pillAmount: Scalars['Int'];
}

export enum PackingEnum {
  Bag = 'BAG',
  Bottle = 'BOTTLE',
  Box = 'BOX',
  Can = 'CAN',
  Cup = 'CUP',
  LongNeck = 'LONG_NECK',
  Pot = 'POT',
  Tin = 'TIN',
  Tray = 'TRAY',
  Vacuum = 'VACUUM'
}

export interface PerfumeryAndBeautyInput {
  bathAccessories?: InputMaybe<AccessoriesInput>;
  beardAndWaxing?: InputMaybe<DefaultExtraFieldInput>;
  beardCare?: InputMaybe<DefaultExtraFieldInput>;
  condomsAndLubricants?: InputMaybe<CondomsAndLubricantsInput>;
  cosmetics?: InputMaybe<TincturesAndTreatmentsAndCosmeticsInput>;
  deodorant?: InputMaybe<DeodorantInput>;
  geriatric?: InputMaybe<GeriatricInput>;
  hairAcessories?: InputMaybe<AccessoriesInput>;
  handsAndFeet?: InputMaybe<AromaticBeautyInput>;
  intimateHygiene?: InputMaybe<AromaticBeautyInput>;
  oralHygiene?: InputMaybe<DefaultExtraFieldInput>;
  othersPerfumery?: InputMaybe<OthersPerfumeryInput>;
  protectorsAndRepellents?: InputMaybe<DefaultExtraFieldInput>;
  shampoosAndConditioners?: InputMaybe<DefaultExtraFieldInput>;
  soaps?: InputMaybe<AromaticBeautyInput>;
  tincturesAndTreatments?: InputMaybe<TincturesAndTreatmentsAndCosmeticsInput>;
  toiletPaper?: InputMaybe<DefaultExtraFieldInput>;
}

export interface PetInput {
  accessory?: InputMaybe<AccessoryInput>;
  foods?: InputMaybe<FoodsInput>;
  hygiene?: InputMaybe<DefaultExtraFieldInput>;
  medicines?: InputMaybe<MedicinesInput>;
  nutrition?: InputMaybe<DefaultExtraFieldInput>;
}

export interface Product {
  __typename?: 'Product';
  barcode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  measurement: MeasureUnit;
  measurementValue: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subCategory: SubCategory;
  weight: Scalars['Float'];
}

export interface ProductExists {
  __typename?: 'ProductExists';
  exists?: Maybe<Scalars['Boolean']>;
  productBarcode?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
}

export interface ProductExtraFieldsInput {
  bazzarAndUtilities?: InputMaybe<BazaarAndUtilitiesInput>;
  butcherAndFishShop?: InputMaybe<ButcherAndFishShopInput>;
  childish?: InputMaybe<ChildishInput>;
  cleaning?: InputMaybe<CleaningInput>;
  coldAndFrozen?: InputMaybe<ColdAndFrozenInput>;
  dairy?: InputMaybe<DairyInput>;
  drink?: InputMaybe<DrinkInput>;
  fuel?: InputMaybe<FuelInput>;
  groceryStore?: InputMaybe<GroceryStoreInput>;
  hortifruti?: InputMaybe<HortifrutiInput>;
  perfumeryAndBeauty?: InputMaybe<PerfumeryAndBeautyInput>;
  pet?: InputMaybe<PetInput>;
  remedy?: InputMaybe<RemedyInput>;
}

export interface ProductFail {
  __typename?: 'ProductFail';
  barcode: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  measurementID?: Maybe<Scalars['String']>;
  measurementValue?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subCategoryID: Scalars['String'];
}

export interface ProductInput {
  ProductExtraFields?: InputMaybe<ProductExtraFieldsInput>;
  barcode: Scalars['String'];
  brand?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  measurementID?: InputMaybe<Scalars['String']>;
  measurementValue?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  subCategoryID: Scalars['String'];
  weight?: InputMaybe<Scalars['Float']>;
}

export interface Query {
  __typename?: 'Query';
  getProductByBarcode?: Maybe<GetProductResponse>;
  getProductById?: Maybe<GetProductResponse>;
  getUploadUrl?: Maybe<Url>;
  listCategories?: Maybe<ListCategoriesResponse>;
  listMeasureUnits?: Maybe<ListMeasureUnitsResponse>;
  listSubCategories?: Maybe<ListSubCategoriesResponse>;
  validateProducts?: Maybe<ValidateProductsResponse>;
}


export interface QueryGetProductByBarcodeArgs {
  params: GetProductByBarcodeInput;
}


export interface QueryGetProductByIdArgs {
  params: GetProductByIdInput;
}


export interface QueryGetUploadUrlArgs {
  params: GetUploadUrlInput;
}


export interface QueryValidateProductsArgs {
  params: ValidateProductsInput;
}

export interface RemedyInput {
  allergiesAndInfections?: InputMaybe<DefaultRemedyInput>;
  antiInflammatory?: InputMaybe<DefaultRemedyInput>;
  apparatusAndTests?: InputMaybe<ApparatusAndTestsInput>;
  contraceptives?: InputMaybe<DefaultRemedyInput>;
  diabetes?: InputMaybe<DefaultRemedyInput>;
  eyes?: InputMaybe<DefaultRemedyInput>;
  firstAid?: InputMaybe<FirstAidInput>;
  fluAndCold?: InputMaybe<DefaultRemedyInput>;
  hypertension?: InputMaybe<DefaultRemedyInput>;
  mouthAndThroat?: InputMaybe<DefaultRemedyInput>;
  noseAndLung?: InputMaybe<DefaultRemedyInput>;
  orthopedicsAndAccessories?: InputMaybe<OrthopedicsAndAccessoriesInput>;
  othersRemedy?: InputMaybe<OthersRemedyInput>;
  painAndFever?: InputMaybe<DefaultRemedyInput>;
  stomachAndIntestine?: InputMaybe<DefaultRemedyInput>;
  vitamins?: InputMaybe<VitaminsInput>;
}

export interface RestroomInput {
  capacity?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<BazaarDimensionWhDepInput>;
  material?: InputMaybe<Scalars['String']>;
  typeOfSale: TypeOfSaleInput;
}

export interface S3ImageHeader {
  __typename?: 'S3ImageHeader';
  Authorization?: Maybe<Scalars['String']>;
  Host?: Maybe<Scalars['String']>;
  X_Amz_Content_Sha256?: Maybe<Scalars['String']>;
  X_Amz_Date?: Maybe<Scalars['String']>;
}

export interface S3ImageRequest {
  __typename?: 'S3ImageRequest';
  headers?: Maybe<S3ImageHeader>;
  url?: Maybe<Scalars['String']>;
}

export interface ShoesCleaningInput {
  pack?: InputMaybe<PackingEnum>;
}

export interface SubCategory {
  __typename?: 'SubCategory';
  category: Category;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<S3ImageRequest>;
  name: Scalars['String'];
}

export interface TincturesAndTreatmentsAndCosmeticsInput {
  color?: InputMaybe<Scalars['String']>;
  pack?: InputMaybe<PackingEnum>;
  typeOfSale: TypeOfSaleInput;
}

export interface ToolsInput {
  color?: InputMaybe<Scalars['String']>;
  dimension: BazaarDimensionWhDepInput;
  material: Scalars['String'];
  power?: InputMaybe<Scalars['Float']>;
  typeOfSale: TypeOfSaleInput;
  voltage?: InputMaybe<Scalars['Float']>;
}

export interface TypeOfSaleInput {
  amount?: InputMaybe<Scalars['Int']>;
  kit?: InputMaybe<Scalars['String']>;
  packInsideAmount?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<Scalars['Boolean']>;
}

export interface UpdateProductInput {
  accessToken: Scalars['String'];
  barcode: Scalars['String'];
  brand?: InputMaybe<Scalars['String']>;
  categoryID?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  hasImage?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  measurementID?: InputMaybe<Scalars['String']>;
  measurementValue?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  subCategoryID: Scalars['String'];
  weight?: InputMaybe<Scalars['Float']>;
}

export interface Url {
  __typename?: 'Url';
  url?: Maybe<Scalars['String']>;
}

export interface ValidateProductsInput {
  accessToken: Scalars['String'];
  productBarcodes: Array<Scalars['String']>;
}

export interface ValidateProductsResponse {
  __typename?: 'ValidateProductsResponse';
  authorizationToken?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ProductExists>>>;
}

export interface VitaminsInput {
  manufacturer: Scalars['String'];
  pack?: InputMaybe<PackingEnum>;
  pillAmount: Scalars['Int'];
}

export interface WinesAndSparklingWinesInput {
  alcoholicStrength: Scalars['Float'];
  pack?: InputMaybe<PackingEnum>;
  type: WinesTypesEnum;
  typeOfSale: TypeOfSaleInput;
}

export enum WinesTypesEnum {
  Dry = 'DRY',
  Liqueur = 'LIQUEUR',
  Red = 'RED',
  Rose = 'ROSE',
  Sparkling = 'SPARKLING',
  White = 'WHITE'
}

export type CreateProductBatchMutationVariables = Exact<{
  params: CreateProductBatchInput;
}>;


export type CreateProductBatchMutation = { __typename?: 'Mutation', createProductBatch?: { __typename?: 'CreateProductBatchResponse', errors: Array<{ __typename?: 'Error', code: number, message: string, name: string } | null>, products: Array<{ __typename?: 'Product', id: string } | null> } | null };

export type CreateProductMutationVariables = Exact<{
  params: CreateProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct?: { __typename?: 'Product', id: string } | null };

export type DeleteProductByIdMutationVariables = Exact<{
  params: DeleteProductByIdInput;
}>;


export type DeleteProductByIdMutation = { __typename?: 'Mutation', deleteProductById?: boolean | null };

export type DeleteProductBatchMutationVariables = Exact<{
  params: DeleteProductBatchInput;
}>;


export type DeleteProductBatchMutation = { __typename?: 'Mutation', deleteProductBatch?: boolean | null };

export type UpdateProductMutationVariables = Exact<{
  params: UpdateProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct?: { __typename?: 'Product', id: string, name: string, brand?: string | null, model?: string | null } | null };

export type GetProductByBarcodeQueryVariables = Exact<{
  params: GetProductByBarcodeInput;
}>;


export type GetProductByBarcodeQuery = { __typename?: 'Query', getProductByBarcode?: { __typename?: 'GetProductResponse', product?: { __typename?: 'Product', id: string, name: string } | null } | null };

export type GetUploadUrlQueryVariables = Exact<{
  params: GetUploadUrlInput;
}>;


export type GetUploadUrlQuery = { __typename?: 'Query', getUploadUrl?: { __typename?: 'Url', url?: string | null } | null };

export type ListCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCategoriesQuery = { __typename?: 'Query', listCategories?: { __typename?: 'ListCategoriesResponse', categories?: Array<{ __typename?: 'Category', id: string, name: string, color: string } | null> | null } | null };

export type ListMeasureUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListMeasureUnitsQuery = { __typename?: 'Query', listMeasureUnits?: { __typename?: 'ListMeasureUnitsResponse', measureUnits?: Array<{ __typename?: 'MeasureUnit', id: string, name: string, symbol: string } | null> | null } | null };

export type ListSubCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSubCategoriesQuery = { __typename?: 'Query', listSubCategories?: { __typename?: 'ListSubCategoriesResponse', subCategories?: Array<{ __typename?: 'SubCategory', id: string, name: string, category: { __typename?: 'Category', id: string } } | null> | null } | null };

export type ValidateProductsQueryVariables = Exact<{
  params: ValidateProductsInput;
}>;


export type ValidateProductsQuery = { __typename?: 'Query', validateProducts?: { __typename?: 'ValidateProductsResponse', authorizationToken?: string | null, products?: Array<{ __typename?: 'ProductExists', productBarcode?: string | null, exists?: boolean | null } | null> | null } | null };

export const CreateProductBatchDocument = gql`
    mutation createProductBatch($params: CreateProductBatchInput!) {
  createProductBatch(params: $params) {
    errors {
      code
      message
      name
    }
    products {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductBatchGQL extends Apollo.Mutation<CreateProductBatchMutation, CreateProductBatchMutationVariables> {
    document = CreateProductBatchDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductDocument = gql`
    mutation createProduct($params: CreateProductInput!) {
  createProduct(params: $params) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductGQL extends Apollo.Mutation<CreateProductMutation, CreateProductMutationVariables> {
    document = CreateProductDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProductByIdDocument = gql`
    mutation deleteProductById($params: DeleteProductByIdInput!) {
  deleteProductById(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProductByIdGQL extends Apollo.Mutation<DeleteProductByIdMutation, DeleteProductByIdMutationVariables> {
    document = DeleteProductByIdDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProductBatchDocument = gql`
    mutation deleteProductBatch($params: DeleteProductBatchInput!) {
  deleteProductBatch(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProductBatchGQL extends Apollo.Mutation<DeleteProductBatchMutation, DeleteProductBatchMutationVariables> {
    document = DeleteProductBatchDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductDocument = gql`
    mutation updateProduct($params: UpdateProductInput!) {
  updateProduct(params: $params) {
    id
    name
    brand
    model
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductGQL extends Apollo.Mutation<UpdateProductMutation, UpdateProductMutationVariables> {
    document = UpdateProductDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductByBarcodeDocument = gql`
    query getProductByBarcode($params: GetProductByBarcodeInput!) {
  getProductByBarcode(params: $params) {
    product {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductByBarcodeGQL extends Apollo.Query<GetProductByBarcodeQuery, GetProductByBarcodeQueryVariables> {
    document = GetProductByBarcodeDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUploadUrlDocument = gql`
    query getUploadUrl($params: GetUploadUrlInput!) {
  getUploadUrl(params: $params) {
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUploadUrlGQL extends Apollo.Query<GetUploadUrlQuery, GetUploadUrlQueryVariables> {
    document = GetUploadUrlDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListCategoriesDocument = gql`
    query listCategories {
  listCategories {
    categories {
      id
      name
      color
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCategoriesGQL extends Apollo.Query<ListCategoriesQuery, ListCategoriesQueryVariables> {
    document = ListCategoriesDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListMeasureUnitsDocument = gql`
    query listMeasureUnits {
  listMeasureUnits {
    measureUnits {
      id
      name
      symbol
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListMeasureUnitsGQL extends Apollo.Query<ListMeasureUnitsQuery, ListMeasureUnitsQueryVariables> {
    document = ListMeasureUnitsDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSubCategoriesDocument = gql`
    query ListSubCategories {
  listSubCategories {
    subCategories {
      id
      name
      category {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSubCategoriesGQL extends Apollo.Query<ListSubCategoriesQuery, ListSubCategoriesQueryVariables> {
    document = ListSubCategoriesDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateProductsDocument = gql`
    query validateProducts($params: ValidateProductsInput!) {
  validateProducts(params: $params) {
    products {
      productBarcode
      exists
    }
    authorizationToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateProductsGQL extends Apollo.Query<ValidateProductsQuery, ValidateProductsQueryVariables> {
    document = ValidateProductsDocument;
    client = 'products';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    