import { UntilDestroy } from '@ngneat/until-destroy';
import { InputDataService } from '@shared/services/input-data.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { FormControl } from '@ng-stack/forms';

@UntilDestroy()
@Component({
  selector: 'app-form-text-input',
  templateUrl: './form-text-input.component.html',
  styleUrls: [ './form-text-input.component.scss' ],
})
export class FormTextInputComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() inputType: HTMLInputElement['type'] = 'text';
  @Input() pattern: string | RegExp;
  @Input() patternValidator: string | RegExp;
  @Input() inputError: string | object;
  @Input() controlName: string;
  @Output() focusInput = new EventEmitter<boolean>();
  @Input() mask = null;
  @Input() prefix: string = null;
  @Input() thousandSeparator: ',' | '.' = '.';
  @Input() required = true;
  @Input() maxLength: string | number = 35;
  @Input() iconName = '';
  @Input() dataCy = 'input-text';
  @Input() tooltipText?: string;
  @Input() tooltipIcon?: toolTipIconName;
  @Input() tooltipAction?: (args?: any) => any;
  @Input() loading = false;
  @Input() autocomplete: string;
  @Output() iconClick = new EventEmitter();
  @Input() min: string;
  @Input() max: string;
  @Input() preventDot = false; 

  currentControl: FormControl;
  inputTypeAtInput: string;

  constructor(
    private rootFromGroup: FormGroupDirective,
  ) {
  }

  ngOnInit(): void {
    this.currentControl = this.rootFromGroup.control.controls[
      this.controlName
    ] as FormControl;

    if (this.inputType == 'number') {
      this.inputTypeAtInput = 'text';

      // Para mais informações sobre a máscara (mask)
      // visite: https://www.npmjs.com/package/ngx-mask
      if (!this.mask) {
        this.mask = 'separator.0';
      }

    }

  }

  ngOnChanges(): void {
    if (this.inputType == 'number') 
      this.inputTypeAtInput = 'text';
    else
      this.inputTypeAtInput = this.inputType;
  }

  onFocusOut(): void {
    this.focusInput.emit(false);
  }

  onFocusIn(): void {
    this.focusInput.emit(true);
  }

  /**
   * Trata o inputError se é uma string ou um objeto com as mensagens de erros. Somente um erro é exibido por vez
   * @param errorMsg pode ser a string do erro ou então o objeto com a chave o nome do erro e o valor a mensagem do erro
   * @returns string a ser exibida no erro
   */
  handleInputError(errorMsg: string | object): string {
    if (typeof errorMsg === 'string') return errorMsg;

    const errors = this.currentControl.errors;
    return errorMsg[Object.keys(errors || {})[0]];
  }

  getTooltipIcon(): string {
    switch (this.tooltipIcon) {
      case 'help-icon':
        return 'assets/dark-theme/offers/add-offers/ajuda.svg';
    }
  }

  checkForDot(input: Event): void {
    if(this.preventDot) {
      const { key } = (input as KeyboardEvent);
      if (key === '.') {
        input.preventDefault();
        input.stopPropagation();
      }
    }
  }

}

type toolTipIconName = 'help-icon';
