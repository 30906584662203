<main>
  <div class="row align-items-center modal-title">
    <div class="col-12">
      <h3 class="font-title-regular">
        Excluir {{ getTypeText() }}

        <button
          tabindex=""
          matRipple
          class="btn dark-btn close-modal-btn d-flex justify-content-center align-items-center"
          (click)="handleCloseModal()"
        >
          <img
            class="modal-close-image"
            src="assets/appbar/dark-theme/icon_cancelar.svg"
            alt="Fechar"
          />
        </button>
      </h3>
    </div>
  </div>

  <mat-divider></mat-divider>
  <form [formGroup]="form" (submit)="deleteUser()" class="delete-user-form">
    <p>Confirme a senha para excluir o {{ getTypeText() }}</p>
    <app-form-text-input
      controlName="password"
      [inputType]="hide ? 'password' : 'text'"
      label="Senha atual"
      [inputError]="handleFormError(form.get('password'))"
      [iconName]="hide ? 'visibility_off' : 'visibility'"
      (iconClick)="hide = !hide"
    >
    </app-form-text-input>
  
    <app-form-submit-button
      title="Excluir"
      [loading]="loadingSubmit"
      [disabled]="!form.valid || loadingSubmit"
    ></app-form-submit-button>
  </form>
</main>
<div class="snackbar">
  <ng-template #snackbar></ng-template>
</div>
