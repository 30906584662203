import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsService } from '@services/products.service';
import { CategoryWAsset } from '@shared/interfaces/backoffice/products/category';


@Component({
  selector: 'app-dropdown-categories',
  templateUrl: './dropdown-categories.component.html',
  styleUrls: [ './dropdown-categories.component.scss' ],
})
export class DropdownCategoriesComponent implements OnInit {
  @Input() disabled: Boolean;
  @Input() selected: FormControl;
  @Input() options: CategoryWAsset[] = [];
  @Input() required = false;
  @Input() error: string;

  hasError = false;

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.selected.valueChanges.subscribe(() => {
      this.hasError = this.selected.touched && !this.selected.valid;
    });
  }

  selectCategory(newCategory: CategoryWAsset): void {
    if (this.selected.value === newCategory.id) {
      this.selected.setValue(null);
    } else {
      this.selected.setValue(newCategory.id);
    }
  }

  getCategoryById(categoryId: string): CategoryWAsset {
    const category = this.options.find((category) => category.id === categoryId);
    return category;
  }

  get label(): string {
    return `Categoria ${this.required ? '*' : ''}`;
  }
}
