<label
  for="squaredThree"
  class="d-flex justify-content-center"
  [ngClass]="{
    'selected': selected,
    'rectangle': rectangle,
    'normal': !rectangle
  }"
>
  <input
    type="checkbox"
    [value]="selected"
    id="squaredThree"
    name="check"
    data-cy="select-item"
  />
  <img
    [src]="selected ? checkedTrueImage : checkedFalseImage"
    class=""
    alt="Checkbox"
  />
</label>
