
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UserAccountService } from '@services/user-account.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { SnackbarService } from '@services/snackbar.service';
import { handleErrors } from '@shared/utils/handleErrors';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: [ './send-email.component.scss' ],
})
export class SendEmailComponent implements OnInit {
  @ViewChild('snackbar', { read: ViewContainerRef, static: true })
  snackbar: ViewContainerRef;
  errorCode: number;
  routeSubscription$: Subscription;

  userEmail: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userAccountService: UserAccountService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.loadQueryParams();
    this.snackbarService.setRootViewContainerRef(this.snackbar);
  }

  async resendEmail(): Promise<void> {

    const params = {
      emailObject: { email: this.userEmail, permissionLevel: 'BACKOFFICE_USER' },
    };

    try {
      await this.userAccountService.resendEmailConfirmation(params).toPromise();
      this.snackbarService.successSnackbar('E-mail de confirmação reenviado.');
    } catch (err) {
      const error = handleErrors(err);
      if (error.canRetry)
        this.snackbarService.errorSnackbar(
          'Não foi possível reenviar o e-mail. Tente novamente.');
      else this.snackbarService.errorSnackbar(error.message);
    }
  }

  navigateToRegister(): void {
    this.router.navigateByUrl('/conta/cadastro');
  }

  private loadQueryParams(): void {
    this.routeSubscription$ = this.activatedRoute.queryParams
      .pipe(
        untilDestroyed(this),
        tap((params: any) => {
          this.userEmail = params['email'];
        })).subscribe();
  }
}
