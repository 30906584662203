import { FormControl, FormGroup } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
@Component({
  selector: 'app-form-calendar-interval-input',
  templateUrl: './form-calendar-interval-input.component.html',
  styleUrls: [ './form-calendar-interval-input.component.scss' ],
})
export class FormCalendarIntervalInputComponent implements OnInit {
  @Input() label = 'Período ativo';
  @Input() form: FormGroup;
  @Output() focusInput = new EventEmitter<boolean>();
  rangeGroup: FormGroup;
  today = new Date();
  hasError = false;
  errorMessage = '';

  constructor() {}
  ngOnInit(): void {
    this.rangeGroup = new FormGroup({
      controlStart: new FormControl(this.form.controls['range'].value[0]),
      controlEnd: new FormControl(this.form.controls['range'].value[1]),
    });
    this.rangeGroup.valueChanges.subscribe(() => {
      this.form.controls['range'].setValue([
        this.rangeGroup.controls['controlStart'].value,
        this.rangeGroup.controls['controlEnd'].value,
      ]);
    });
  }

  resetDataInput(): void {
    this.rangeGroup.controls['controlStart'].setValue(null);
    this.rangeGroup.controls['controlEnd'].setValue(null);
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    const date = new Date(cellDate);
    const startDate = new Date(this.rangeGroup.controls['controlStart'].value);
    const endDate = new Date(this.rangeGroup.controls['controlEnd'].value);
    if (view === 'month') {
      return date > startDate && date < endDate
        ? 'interval-color'
        : 'none-color';
    }
    return '';
  };

  handleErrors(): boolean {
    if (
      this.form.controls['range'].touched &&
      !this.rangeGroup.controls['controlStart'].value
    ) {
      this.errorMessage = 'Esse campo não pode estar vazio.';
      return true;
    } else if (
      this.rangeGroup.controls['controlStart'].value &&
      !this.rangeGroup.controls['controlEnd'].value
    ) {
      this.errorMessage = 'Insira a data de fim.';
      return true;
    } else {
      this.errorMessage = '';
      return false;
    }
  }
}
