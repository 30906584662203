import { Component, OnInit } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';

import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { handleErrors } from '@app/shared/utils/handleErrors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalStorageService } from '@services/local-storage.service';
import { SnackbarService } from '@services/snackbar.service';
import { StoresService } from '@services/stores.service';
import { UserAccountService } from '@services/user-account.service';
import {
    GetCitiesByStateIdCity as City,
} from '@shared/graphql/stores/stores-graphql';
import {
  GetStateState as State,
  SearchStoresCity,
  SearchStoresState,
} from '@shared/interfaces/backoffice/stores/store';
import { StoreTypes } from '@shared/interfaces/backoffice/storeTypes';

interface AddressForm {
  district: string;
  city: string;
  address: string;
  state: string;
  postalCode: string;
}

interface UserStateCity {
  country?: string;
  state: string;
  city: string;
}

@UntilDestroy()
@Component({
  selector: 'app-base-location',
  template: '',
  styles: [ '' ],
})
export class BaseLocationComponent implements OnInit {
  public ACCESS_TOKEN: string;

  states: State[] = [];
  selectedCities: string[] = [];
  selectedState = new FormControl(null);
  availableCities: City[] = [];
  selectedCity = new FormControl(null);
  selectedCityPlaceholder = new FormControl(null);
  storeTypes: StoreTypes[] = [];
  selectedTypes = new FormControl([]);

  // Geolocation
  waitingForLocation = true;
  userGeolocation: GeolocationPosition;
  userPermittedLocation = false;
  userStateCity: UserStateCity;

  loadingCities = false;

  constructor(
    public route: ActivatedRoute,
    public userAccountService: UserAccountService,
    public ls: LocalStorageService,
    public snackbarService: SnackbarService,
    public storesService: StoresService,
  ) {}

  ngOnInit(): void {
    this.ACCESS_TOKEN = this.userAccountService.userAccessToken;
    this.setStates();
    this.getStoreTypes();

    this.selectedState.valueChanges.pipe(
      untilDestroyed(this),
      tap(async (newStateId: string) => {
        this.loadCitiesByStateId(newStateId);
        this.onSearch();
      }),
    ).subscribe();

    this.selectedCity.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((value) => {
          this.onSearch();
        }),
      )
      .subscribe();

    this.selectedTypes.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.onSearch();
        }),
      )
      .subscribe();

    this.verifyGeolocationPermission();
  }

  // implementar na classe filho caso precise chamar algum método e não pegar só o valor
  onSearch(): void {}

  async setSelectedStateByName(stateName: string): Promise<void> {
    if (this.states.length === 0) {
      await this.setStates();
    }

    const actualState = this.states.find((s) => s.federativeUnit === stateName);
    if (!actualState)
      return;

    this.selectedState.setValue(actualState?.id, { emitEvent: false });
  }

  async setSelectedCityByName(cityName: string): Promise<void> {
    this.selectedCity.setValue(null, { emitEvent: false } );
    this.selectedCityPlaceholder.setValue(cityName);

    await this.setAvailableCities(this.selectedState.value);

    const cityFound = this.availableCities.find(
      (city) => city.name === cityName,
    );
    if(cityFound)
      this.selectedCity.setValue(cityFound?.id);
    else
      throw new Error('Cidade não encontrada.');
  }

  async loadCitiesByStateId(stateId: string, retry: boolean = true): Promise<void> {
    this.availableCities = [];
    this.selectedCity.setValue(null);
    if (!stateId) {
      this.availableCities = [];
      return;
    }

    this.loadingCities = true;
    try {
      const loadedCities = await this.loadCitiesRequest(stateId);
      this.availableCities = loadedCities.sort((a, b) => a.name.localeCompare(b.name));
    } catch (err) {
      if(retry) {
        const error = handleErrors(err);
        if (error.canRetry)
          this.snackbarService.retry(
            'Não foi possível buscar as cidades. Clique no botão para tentar novamente.',
            () => this.loadCitiesByStateId(stateId),
          );
        else this.snackbarService.errorSnackbar(error.message);
      } else {
        throw new Error('Não foi possível realizar a requisição');
      }

    } finally {
      this.loadingCities = false;
    }
    return;
  }

  async cepInputed(form: FormGroup): Promise<void> {
    // this.selectedCity.setValue(null);
    const cep = form.controls['cep'].value;
    if (!cep) return;

    try {
      const {
        city,
        state,
        address,
        district,
      } = await this.storesService.getAddressByCEP(cep);

      await this.setFormAddress({
        address,
        district,
        postalCode: cep,
        city,
        state,
      }, form);

      form.get('cep').setErrors(null);
    } catch (err) {
      if (err.message === 'CEP não encontrado') {
        form.get('cep').setErrors({ notFound: true });
        return;
      }
      if (err.message === 'Estados não encontrados') {
        this.snackbarService.retry(
          'Não foi possível carregar os estados. Clique no botão para tentar novamente.',
          () => {
            this.setStates();
          },
        );
        return;
      }

      if (err.message == 'Cidade não encontrada.') {
        this.snackbarService.errorSnackbar(
          'Não foi possível encontrar a cidade. Reescreva o CEP para tentar novamente.',
        );
        return;
      }

      const error = handleErrors(err);
      if (error.canRetry)
        if (error.code === 504) {
          this.snackbarService.errorSnackbar(
            'Erro de conexão. Tempo de espera extrapolado',
          );
        } else {
          this.snackbarService.errorSnackbar(
            'CEP não encontrado. Confira seus dados e tente novamente.',
          );
        }

      else {
        this.snackbarService.errorSnackbar('Ocorreu um erro ao tentar localizar o CEP');
      }
    }
  }

  async setFormAddress(data: AddressForm, form: FormGroup): Promise<void> {
    const { address, district, postalCode, city, state } = data;
    const controls = form.controls;

    await this.setSelectedStateByName(state);
    await this.setSelectedCityByName(city);
    
    if (!controls['cep'].value && !data.postalCode) return;

    controls['stateId'].setValue(this.selectedState.value);
    controls['cityId'].setValue(this.selectedCity.value);
    controls['address'].setValue(address);
    controls['district'].setValue(district);

    if (address == '' || district == '') {
      controls['address'].enable();
      controls['district'].enable();
    } else {
      controls['address'].disable();
      controls['district'].disable();
    }

    if (postalCode)
      controls['cep'].setValue(data.postalCode.replace('-', ''), {
        emitEvent: false,
      });
  }

  restoreFormAddress(
    form: FormGroup,
    addressInputName: string,
    districtInputName: string,
  ): void {
    form.get(`${addressInputName}`)?.setValue('');
    form.get(`${addressInputName}`)?.enable();

    form.get(`${districtInputName}`)?.setValue('');
    form.get(`${districtInputName}`)?.enable();
  }

  async setCityAndState(
    stateId: string,
    cityId: string,
  ): Promise<void> {
    this.selectedState.setValue(stateId);
    this.selectedCity.setValue(cityId);
  }

  getCityByCityId(cityId: string): SearchStoresCity {
    const city: any = this.availableCities.find(city => city.id === cityId);
    const state = this.getStateByStateId(this.selectedState.value);
    return {
      ...city,
      state,
    };
  }

  getStateByStateId(stateId: string): SearchStoresState {
    return (this.states as any).find((state) => state.id === stateId );
  }

  async setStates(): Promise<void> {
    try {
      const states = await this.storesService
        .getStates({ params: { accessToken: this.ACCESS_TOKEN } })
        .toPromise();
      this.sortItems(states);
      this.states = states;
    } catch (error) {
      throw 'Estados não encontrados';
    }
  }

  private sortItems(array: any[]): void{
    array.sort((x, y)=>{
      let a = x.name.toUpperCase(),
          b = y.name.toUpperCase();
      return a == b ? 0 : a > b ? 1 : -1;
    });
  }

  private async setAvailableCities(stateId: string): Promise<void> {
    const cities = await this.loadCitiesRequest(stateId);
    const handledCities = [ ...cities ];
    this.availableCities = handledCities.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }

  /**
   * Retorna a lista de cidades de um determinado estado passado como parâmetro.
   */
  private async loadCitiesRequest(stateId: string): Promise<City[]> {
    const {
      data: {
        getCitiesByStateId: { cities },
      },
    } = await this.storesService
      .getCitiesSubscription({
        params: { accessToken: this.ACCESS_TOKEN, stateId },
      })
      .toPromise();
    return [ ...cities as City[] ];
  }

  private verifyGeolocationPermission(): void {
    if (this.ls.getItem('userPermittedLocation') === null) return;
    const res = this.ls.getItem('userPermittedLocation');

    this.userPermittedLocation = Boolean(res);
    this.waitingForLocation = false;

    if (this.userPermittedLocation) {
      window.navigator.geolocation.getCurrentPosition((res) => {
        this.userGeolocation = res;

        this.getUserStateAndCity();
        this.onSearch();
      });
    }else{
      this.getUserStateAndCity();
      this.onSearch();
    }
  }

  private getStoreTypes(): void {
    this.storesService
      .getStoreTypes()
      .pipe(
        tap((value) => {
          this.storeTypes = value.map((item) => {
            return {
              ...item,
              selected: false,
            };
          });
          this.sortItems(this.storeTypes);
        }),
        catchError((err) => {
          const error = handleErrors(err);
          if (error.canRetry)
            this.snackbarService.retry(
              'Não foi possível carregar os tipos de estabelecimentos. Clique no botão para tentar novamente.',
              () => {
                this.getStoreTypes();
              },
            );
          else
            this.snackbarService.errorSnackbar(
              'Não foi possível carregar os tipos de estabelecimentos.');
          return err;
        }),
      )
      .subscribe();
  }

  protected getUserStateAndCity(): void {
    const { state, city } = this.route.snapshot.queryParams;
    if (state) {
      this.userStateCity = { state, city };
      this.selectedState.setValue(state);
      if(city) this.selectedCity.setValue(city);
    } else if (this.userPermittedLocation && this.userGeolocation) {
      this.storesService
        .GetStateCityByLatLongSubscription({
          params: {
            accessToken: this.ACCESS_TOKEN,
            latitude: this.userGeolocation.coords.latitude.toString(),
            longitude: this.userGeolocation.coords.longitude.toString(),
          },
        })
        .pipe(
          untilDestroyed(this),
          tap((value) => {
            this.userStateCity = value;
            const stateFound = this.states.find(
              (s) => s.name === this.userStateCity.state,
            );
            this.selectedState.setValue(stateFound.id);
            this.setSelectedCityByName(value.city);
          }),
          catchError((err) => {
            this.snackbarService.errorSnackbar(
              'Não foi possível obter sua localização.',
            );
            return err;
          }),
        )
        .subscribe();
    }
  }
}
