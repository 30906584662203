import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CategoryWAsset } from '@app/shared/interfaces/backoffice/products/category';
import { SubCategoryWSelected } from '@shared/interfaces/backoffice/products/subcategory';


@Component({
  selector: 'app-appbar-products-search',
  templateUrl: './appbar-products-search.component.html',
  styleUrls: [ './appbar-products-search.component.scss' ],
})
export class AppbarProductsSearchComponent {
  @Input() categories: CategoryWAsset[];
  @Input() selectedCategory: FormControl;
  @Output() changeCategory = new EventEmitter<string>();

  @Input() subcategories: SubCategoryWSelected[];
  @Input() selectedSubcategories: SubCategoryWSelected[] = null;
  @Output() changeSubcategories = new EventEmitter<string[]>();
  @Output() search = new EventEmitter<string>();

  searchInput = '';

  changeSelectedCategory(category: CategoryWAsset): void {
    this.changeCategory.emit(category.id);
  }

  changeSelectedSubCategory(subcategoriesId: string[]): void {
    this.changeSubcategories.emit(subcategoriesId);
  }

  onSearch(): void {
    this.search.emit(this.searchInput);
  }
}
