<div class="password-tooltip">
  <div class="container">
    A senha deve conter:
    <div
      [ngClass]="controlPassword.hasError('required') || controlPassword.hasError('minlength')  ? 'text-error' : 'text-approved'">
      <img src="{{ controlPassword.hasError('required') ||
      controlPassword.hasError('minlength') ? 'assets/dark-theme/account/icon-error.svg' :
      'assets/dark-theme/account/icon-success.svg' }}" />
      De 8 a 70 caracteres
    </div>
    <div
      [ngClass]="controlPassword.hasError('required') || controlPassword.hasError('hasCapitalCase')  ? 'text-error' : 'text-approved'">
      <img src="{{ controlPassword.hasError('required') ||
      controlPassword.hasError('hasCapitalCase') ? 'assets/dark-theme/account/icon-error.svg' :
      'assets/dark-theme/account/icon-success.svg' }}" />
      Pelo menos uma letra maíuscula
    </div>
    <div
      [ngClass]="controlPassword.hasError('required') || controlPassword.hasError('hasSmallCase')  ? 'text-error' : 'text-approved'">
      <img src="{{ controlPassword.hasError('required') ||
      controlPassword.hasError('hasSmallCase') ? 'assets/dark-theme/account/icon-error.svg' :
      'assets/dark-theme/account/icon-success.svg' }}" />
      Pelo menos uma letra minúscula
    </div>
    <div
      [ngClass]="controlPassword.hasError('required') || controlPassword.hasError('hasSpecialCharacters') ? 'text-error' : 'text-approved'">
      <img src="{{ controlPassword.hasError('required') ||
      controlPassword.hasError('hasSpecialCharacters') ? 'assets/dark-theme/account/icon-error.svg' :
      'assets/dark-theme/account/icon-success.svg' }}" />
      Pelo menos um caracter especial
    </div>
    <div
      [ngClass]="controlPassword.hasError('required') || controlPassword.hasError('hasNumber')  ? 'text-error' : 'text-approved'">
      <img src="{{ controlPassword.hasError('required') ||
      controlPassword.hasError('hasNumber') ? 'assets/dark-theme/account/icon-error.svg' :
      'assets/dark-theme/account/icon-success.svg' }}" />
      Pelo menos um número
    </div>
  </div>
  <div class="right-triangle"></div>
</div>
