import { Environment } from '@shared/interfaces/Environment';

export const environment: Environment = {
  PRODUCTION: false,
  DEFAULT_EMAIL: 'fofafab667@bulkbye.com',
  DEFAULT_PASSWORD: '!eE12345',
  // MOCK: true,
  MOCK: false,
  ENVIRONMENT: 'dev',
  API_KEY_USERS: 'da2-7irbodq5vnerlekr4axrjbw5aq',
  API_KEY_STORES: 'da2-lenhjomjgfc23ihmce3campxnq',
  API_KEY_PRODUCTS: 'da2-lnfyjkrsijegnkgeqzwu7i6tyu',
  API_KEY_GOOGLE: 'AIzaSyAF7O40ob1AjEOlVZYkf_vrVzPSTvEUiPc',
};
