// This file is generated, Do not edit manually.
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
}

export interface AuthenticationResultToken {
  __typename?: 'AuthenticationResultToken';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
}

export interface ConfirmPasswordInput {
  accessToken: Scalars['String'];
  passwordToConfirm: Scalars['String'];
}

export interface ConfirmSignUpInput {
  code: Scalars['String'];
  emailObject: EmailObject;
}

export interface CreateUserAdminInput {
  accessToken: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
}

export interface DeleteAnotherUserInput {
  accessToken: Scalars['String'];
  adminPassword: Scalars['String'];
  userId: Scalars['ID'];
  userPermissionLevel: Scalars['String'];
}

export interface DeleteUserInput {
  accessToken: Scalars['String'];
  emailObject: EmailObject;
  password: Scalars['String'];
}

export interface EmailObject {
  email: Scalars['String'];
  permissionLevel: Scalars['String'];
}

export interface EnableNotificationInput {
  accessToken: Scalars['String'];
}

export interface GetUserInfoInput {
  accessToken: Scalars['String'];
}

export interface GetUserInfoResponse {
  __typename?: 'GetUserInfoResponse';
  userAttributes: UserAttributes;
}

export interface Mutation {
  __typename?: 'Mutation';
  confirmSignUp?: Maybe<Scalars['Boolean']>;
  createUserAdmin?: Maybe<Scalars['Boolean']>;
  deleteAnotherUser?: Maybe<Scalars['Boolean']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  enableNotification?: Maybe<Scalars['Boolean']>;
  recoverPassword?: Maybe<Scalars['Boolean']>;
  refreshDeviceToken: RefreshDeviceTokenResponse;
  refreshSession: RefreshSessionResponse;
  requestPasswordRecovery?: Maybe<Scalars['Boolean']>;
  resendEmailConfirmation?: Maybe<Scalars['Boolean']>;
  signIn?: Maybe<SignInResponse>;
  signOut?: Maybe<Scalars['Boolean']>;
  signUp?: Maybe<Scalars['Boolean']>;
  updateEmail?: Maybe<Scalars['Boolean']>;
  updateName?: Maybe<Scalars['Boolean']>;
  updatePassword?: Maybe<Scalars['Boolean']>;
  updateUserInfo?: Maybe<GetUserInfoResponse>;
}


export interface MutationConfirmSignUpArgs {
  params: ConfirmSignUpInput;
}


export interface MutationCreateUserAdminArgs {
  params: CreateUserAdminInput;
}


export interface MutationDeleteAnotherUserArgs {
  params: DeleteAnotherUserInput;
}


export interface MutationDeleteUserArgs {
  params: DeleteUserInput;
}


export interface MutationEnableNotificationArgs {
  params: EnableNotificationInput;
}


export interface MutationRecoverPasswordArgs {
  params: RecoverPasswordInput;
}


export interface MutationRefreshDeviceTokenArgs {
  params: RefreshDeviceTokenInput;
}


export interface MutationRefreshSessionArgs {
  params: RefreshSessionInput;
}


export interface MutationRequestPasswordRecoveryArgs {
  params: RequestPasswordRecoveryInput;
}


export interface MutationResendEmailConfirmationArgs {
  params: ResendEmailConfirmationInput;
}


export interface MutationSignInArgs {
  params: SignInInput;
}


export interface MutationSignOutArgs {
  params: SignOutInput;
}


export interface MutationSignUpArgs {
  params: SignUpInput;
}


export interface MutationUpdateEmailArgs {
  params: UpdateEmailInput;
}


export interface MutationUpdateNameArgs {
  params: UpdateNameInput;
}


export interface MutationUpdatePasswordArgs {
  params: UpdatePasswordInput;
}


export interface MutationUpdateUserInfoArgs {
  params: UpdateUserInfoInput;
}

export interface Query {
  __typename?: 'Query';
  confirmPassword?: Maybe<Scalars['Boolean']>;
  getUserInfo: GetUserInfoResponse;
  resendEmailVerificationCode?: Maybe<Scalars['Boolean']>;
  searchUsersByPermissionLevel: SearchUsersByPermissionLevelResponse;
}


export interface QueryConfirmPasswordArgs {
  params: ConfirmPasswordInput;
}


export interface QueryGetUserInfoArgs {
  params: GetUserInfoInput;
}


export interface QueryResendEmailVerificationCodeArgs {
  params: ResendEmailVerificationCodeInput;
}


export interface QuerySearchUsersByPermissionLevelArgs {
  params: SearchUsersByPermissionLevelInput;
}

export interface RecoverPasswordInput {
  code: Scalars['String'];
  emailObject: EmailObject;
  password: Scalars['String'];
}

export interface RefreshDeviceTokenInput {
  accessToken: Scalars['String'];
  currentDeviceToken: Scalars['String'];
  newDeviceToken: Scalars['String'];
}

export interface RefreshDeviceTokenResponse {
  __typename?: 'RefreshDeviceTokenResponse';
  deviceId: Scalars['String'];
}

export interface RefreshSessionInput {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
}

export interface RefreshSessionResponse {
  __typename?: 'RefreshSessionResponse';
  accessToken?: Maybe<Scalars['String']>;
}

export interface RequestPasswordRecoveryInput {
  emailObject: EmailObject;
}

export interface ResendEmailConfirmationInput {
  emailObject: EmailObject;
}

export interface ResendEmailVerificationCodeInput {
  accessToken: Scalars['String'];
}

export interface SearchUsersByPermissionLevelInput {
  accessToken: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  paginationSize?: InputMaybe<Scalars['Int']>;
  userPermissionLevel: Scalars['String'];
}

export interface SearchUsersByPermissionLevelResponse {
  __typename?: 'SearchUsersByPermissionLevelResponse';
  nextToken?: Maybe<Scalars['String']>;
  users: Array<Maybe<User>>;
}

export interface SignInInput {
  deviceId?: InputMaybe<Scalars['String']>;
  deviceToken?: InputMaybe<Scalars['String']>;
  emailObject: EmailObject;
  password: Scalars['String'];
}

export interface SignInResponse {
  __typename?: 'SignInResponse';
  deviceId?: Maybe<Scalars['String']>;
  token?: Maybe<AuthenticationResultToken>;
  user?: Maybe<UserType>;
}

export interface SignOutInput {
  accessToken: Scalars['String'];
}

export interface SignUpInput {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  permissionLevel: Scalars['String'];
}

export interface UpdateEmailInput {
  accessToken: Scalars['String'];
  currentEmailObject: EmailObject;
  newEmail: Scalars['String'];
  password: Scalars['String'];
}

export interface UpdateNameInput {
  accessToken: Scalars['String'];
  newName: Scalars['String'];
}

export interface UpdatePasswordInput {
  accessToken: Scalars['String'];
  previousPassword: Scalars['String'];
  providedPassword: Scalars['String'];
}

export interface UpdateUserInfoInput {
  accessToken: Scalars['String'];
  birthDate?: InputMaybe<Scalars['String']>;
  identityDocument?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
}

export interface User {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permissionsLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface UserAttributes {
  __typename?: 'UserAttributes';
  birthDate?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['String']>;
  identityDocument?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissionsLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
  storesCreated?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
}

export interface UserType {
  __typename?: 'UserType';
  email: Scalars['String'];
  name: Scalars['String'];
  permissionsLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export type ConfirmSignUpMutationVariables = Exact<{
  params: ConfirmSignUpInput;
}>;


export type ConfirmSignUpMutation = { __typename?: 'Mutation', confirmSignUp?: boolean | null };

export type CreateUserAdminMutationVariables = Exact<{
  params: CreateUserAdminInput;
}>;


export type CreateUserAdminMutation = { __typename?: 'Mutation', createUserAdmin?: boolean | null };

export type DeleteAnotherUserMutationVariables = Exact<{
  params: DeleteAnotherUserInput;
}>;


export type DeleteAnotherUserMutation = { __typename?: 'Mutation', deleteAnotherUser?: boolean | null };

export type DeleteUserMutationVariables = Exact<{
  params: DeleteUserInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: boolean | null };

export type RecoverPasswordMutationVariables = Exact<{
  params: RecoverPasswordInput;
}>;


export type RecoverPasswordMutation = { __typename?: 'Mutation', recoverPassword?: boolean | null };

export type RefreshSessionMutationVariables = Exact<{
  params: RefreshSessionInput;
}>;


export type RefreshSessionMutation = { __typename?: 'Mutation', refreshSession: { __typename?: 'RefreshSessionResponse', accessToken?: string | null } };

export type RequestPasswordRecoveryMutationVariables = Exact<{
  params: RequestPasswordRecoveryInput;
}>;


export type RequestPasswordRecoveryMutation = { __typename?: 'Mutation', requestPasswordRecovery?: boolean | null };

export type ResendEmailConfirmationMutationVariables = Exact<{
  params: ResendEmailConfirmationInput;
}>;


export type ResendEmailConfirmationMutation = { __typename?: 'Mutation', resendEmailConfirmation?: boolean | null };

export type SignInMutationVariables = Exact<{
  params: SignInInput;
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn?: { __typename?: 'SignInResponse', token?: { __typename?: 'AuthenticationResultToken', accessToken: string, refreshToken: string } | null, user?: { __typename?: 'UserType', email: string, name: string, permissionsLevel?: Array<string | null> | null } | null } | null };

export type SignOutMutationVariables = Exact<{
  params: SignOutInput;
}>;


export type SignOutMutation = { __typename?: 'Mutation', signOut?: boolean | null };

export type SignUpMutationVariables = Exact<{
  params: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp?: boolean | null };

export type UpdateEmailMutationVariables = Exact<{
  params: UpdateEmailInput;
}>;


export type UpdateEmailMutation = { __typename?: 'Mutation', updateEmail?: boolean | null };

export type UpdateNameMutationVariables = Exact<{
  params: UpdateNameInput;
}>;


export type UpdateNameMutation = { __typename?: 'Mutation', updateName?: boolean | null };

export type UpdatePasswordMutationVariables = Exact<{
  params: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword?: boolean | null };

export type ConfirmPasswordQueryVariables = Exact<{
  params: ConfirmPasswordInput;
}>;


export type ConfirmPasswordQuery = { __typename?: 'Query', confirmPassword?: boolean | null };

export type GetUserInfoQueryVariables = Exact<{
  params: GetUserInfoInput;
}>;


export type GetUserInfoQuery = { __typename?: 'Query', getUserInfo: { __typename?: 'GetUserInfoResponse', userAttributes: { __typename?: 'UserAttributes', name?: string | null, email?: string | null, emailVerified?: string | null, permissionsLevel?: Array<string | null> | null, storesCreated?: number | null } } };

export type SearchUsersByPermissionLevelQueryVariables = Exact<{
  params: SearchUsersByPermissionLevelInput;
}>;


export type SearchUsersByPermissionLevelQuery = { __typename?: 'Query', searchUsersByPermissionLevel: { __typename?: 'SearchUsersByPermissionLevelResponse', nextToken?: string | null, users: Array<{ __typename?: 'User', id: string, name: string, email: string, permissionsLevel?: Array<string | null> | null } | null> } };

export const ConfirmSignUpDocument = gql`
    mutation confirmSignUp($params: ConfirmSignUpInput!) {
  confirmSignUp(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmSignUpGQL extends Apollo.Mutation<ConfirmSignUpMutation, ConfirmSignUpMutationVariables> {
    document = ConfirmSignUpDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserAdminDocument = gql`
    mutation createUserAdmin($params: CreateUserAdminInput!) {
  createUserAdmin(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserAdminGQL extends Apollo.Mutation<CreateUserAdminMutation, CreateUserAdminMutationVariables> {
    document = CreateUserAdminDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAnotherUserDocument = gql`
    mutation deleteAnotherUser($params: DeleteAnotherUserInput!) {
  deleteAnotherUser(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAnotherUserGQL extends Apollo.Mutation<DeleteAnotherUserMutation, DeleteAnotherUserMutationVariables> {
    document = DeleteAnotherUserDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($params: DeleteUserInput!) {
  deleteUser(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RecoverPasswordDocument = gql`
    mutation recoverPassword($params: RecoverPasswordInput!) {
  recoverPassword(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecoverPasswordGQL extends Apollo.Mutation<RecoverPasswordMutation, RecoverPasswordMutationVariables> {
    document = RecoverPasswordDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RefreshSessionDocument = gql`
    mutation refreshSession($params: RefreshSessionInput!) {
  refreshSession(params: $params) {
    accessToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RefreshSessionGQL extends Apollo.Mutation<RefreshSessionMutation, RefreshSessionMutationVariables> {
    document = RefreshSessionDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestPasswordRecoveryDocument = gql`
    mutation requestPasswordRecovery($params: RequestPasswordRecoveryInput!) {
  requestPasswordRecovery(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestPasswordRecoveryGQL extends Apollo.Mutation<RequestPasswordRecoveryMutation, RequestPasswordRecoveryMutationVariables> {
    document = RequestPasswordRecoveryDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendEmailConfirmationDocument = gql`
    mutation resendEmailConfirmation($params: ResendEmailConfirmationInput!) {
  resendEmailConfirmation(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendEmailConfirmationGQL extends Apollo.Mutation<ResendEmailConfirmationMutation, ResendEmailConfirmationMutationVariables> {
    document = ResendEmailConfirmationDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignInDocument = gql`
    mutation signIn($params: SignInInput!) {
  signIn(params: $params) {
    token {
      accessToken
      refreshToken
    }
    user {
      email
      name
      permissionsLevel
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignInGQL extends Apollo.Mutation<SignInMutation, SignInMutationVariables> {
    document = SignInDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignOutDocument = gql`
    mutation SignOut($params: SignOutInput!) {
  signOut(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignOutGQL extends Apollo.Mutation<SignOutMutation, SignOutMutationVariables> {
    document = SignOutDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignUpDocument = gql`
    mutation signUp($params: SignUpInput!) {
  signUp(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignUpGQL extends Apollo.Mutation<SignUpMutation, SignUpMutationVariables> {
    document = SignUpDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEmailDocument = gql`
    mutation updateEmail($params: UpdateEmailInput!) {
  updateEmail(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmailGQL extends Apollo.Mutation<UpdateEmailMutation, UpdateEmailMutationVariables> {
    document = UpdateEmailDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateNameDocument = gql`
    mutation updateName($params: UpdateNameInput!) {
  updateName(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateNameGQL extends Apollo.Mutation<UpdateNameMutation, UpdateNameMutationVariables> {
    document = UpdateNameDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePasswordDocument = gql`
    mutation updatePassword($params: UpdatePasswordInput!) {
  updatePassword(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePasswordGQL extends Apollo.Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables> {
    document = UpdatePasswordDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmPasswordDocument = gql`
    query confirmPassword($params: ConfirmPasswordInput!) {
  confirmPassword(params: $params)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmPasswordGQL extends Apollo.Query<ConfirmPasswordQuery, ConfirmPasswordQueryVariables> {
    document = ConfirmPasswordDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserInfoDocument = gql`
    query getUserInfo($params: GetUserInfoInput!) {
  getUserInfo(params: $params) {
    userAttributes {
      name
      email
      emailVerified
      permissionsLevel
      storesCreated
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserInfoGQL extends Apollo.Query<GetUserInfoQuery, GetUserInfoQueryVariables> {
    document = GetUserInfoDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchUsersByPermissionLevelDocument = gql`
    query searchUsersByPermissionLevel($params: SearchUsersByPermissionLevelInput!) {
  searchUsersByPermissionLevel(params: $params) {
    nextToken
    users {
      id
      name
      email
      permissionsLevel
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUsersByPermissionLevelGQL extends Apollo.Query<SearchUsersByPermissionLevelQuery, SearchUsersByPermissionLevelQueryVariables> {
    document = SearchUsersByPermissionLevelDocument;
    client = 'users';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    