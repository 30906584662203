

import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private ls = window.localStorage;

  defaultExpires = 86400;

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  public setItem(key: string, value: any): true {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  public getItem<T>(key: string): T {
    let value: any = this.ls.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  public removeItem(key: string): true {
    this.ls.removeItem(key);
    return true;
  }

  public clear(): void {
    this.ls.clear();
  }
}
