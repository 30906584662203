import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';

import { StoreByCSV } from '@app/shared/interfaces/backoffice/stores/store';
import { StoreTypesTranslator } from '@assets/StoreTypeTranslation';
import {
  CreateStoreBatchGQL,
  CreateStoreBatchMutation,
  CreateStoreGQL,
  CreateStoreMutation,
  CreateStoreMutationVariables,
  DeleteStoreBatchGQL,
  DeleteStoreBatchInput,
  DeleteStoreBatchMutation,
  DeleteStoreGQL,
  DeleteStoreInput,
  DeleteStoreMutation,
  GetCitiesByStateIdGQL,
  GetCitiesByStateIdQuery,
  GetCitiesByStateIdQueryVariables,
  GetStateCityByLatLongGQL,
  GetStateCityByLatLongQuery,
  GetStateCityByLatLongQueryVariables,
  GetStatesGQL,
  GetStatesQuery,
  GetStatesQueryVariables,
  GetStoreGQL,
  GetStoreInput,
  GetStoreQuery,
  GetStoreTypesGQL,
  GetUploadUrlGQL,
  SearchStoresGQL,
  SearchStoresInput,
  SearchStoresQuery,
  StoreBatchInput,
  StoreType,
  ValidateStoreBatchGQL,
  ValidateStoreBatchInput,
  ValidateStoreBatchQuery,
  UpdateStoreGQL,
  UpdateStoreInput,
  UpdateStoreMutation,
} from '@shared/graphql/stores/stores-graphql';

import { LocalStorageService } from './local-storage.service';
import { UserAccountService } from './user-account.service';
import { ImageHandlerService } from './image-handler.service';

type ResponseAddress = {
  bairro: string;
  localidade: string;
  logradouro: string;
  uf: string;
  erro: boolean;
};

export type CompleteAdress = {
  district: string;
  city: string;
  address: string;
  state: string;
};

type getCepInput = {
  cityName: string;
  federativeUnit: string;
  address: string;
};

type ResponseCep = {
  [key: string]: any;
};

const STORE_TYPES_KEY = 'store_types';
const STATES_KEY = 'states';
const USER_STATE_CITY_KEY = 'user_state_city';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  ACCESS_TOKEN: string;

  constructor(
    private http: HttpClient,
    private userAccountService: UserAccountService,
    private searchStoresGQL: SearchStoresGQL,
    private getCitiesByStateIdGQL: GetCitiesByStateIdGQL,
    private getStatesGQL: GetStatesGQL,
    private createStoreGQL: CreateStoreGQL,
    private getStoreGQL: GetStoreGQL,
    private deleteStoreGQL: DeleteStoreGQL,
    private deleteStoreBatchGQL: DeleteStoreBatchGQL,
    private getStoreTypesGQL: GetStoreTypesGQL,
    private validateStoreBatchGQL: ValidateStoreBatchGQL,
    private createStoreBatchGQL: CreateStoreBatchGQL,
    private updateStoreGQL: UpdateStoreGQL,
    private getStateCityByLatLongGQL: GetStateCityByLatLongGQL,
    private ls: LocalStorageService,
    private imageHandlerService: ImageHandlerService,
  ) {
    this.ACCESS_TOKEN = this.userAccountService.userAccessToken;
  }

  getStoreSubscription(
    params: GetStoreInput,
  ): Observable<ApolloQueryResult<GetStoreQuery>> {
    return this.getStoreGQL.fetch({ params });
  }

  getSearchStoresSubscription(
    params: SearchStoresInput,
  ): Observable<ApolloQueryResult<SearchStoresQuery>> {
    return this.searchStoresGQL.fetch({ params });
  }

  getStates({
    params,
  }: GetStatesQueryVariables): Observable<
    GetStatesQuery['getStates']['states']
  > {
    const statesCache: GetStatesQuery['getStates']['states'] =
      this.ls.getItem(STATES_KEY);
    if (!!statesCache) return of(statesCache);

    const states = this.getStatesGQL.fetch({ params }).pipe(
      map((data) => {
        return data.data.getStates.states.map((state) => {
          return { ...state };
        });
      }),
      tap((data) => {
        this.ls.setItem(STATES_KEY, data);
      }),
    );
    return states;
  }

  GetStateCityByLatLongSubscription({
    params,
  }: GetStateCityByLatLongQueryVariables): Observable<
    GetStateCityByLatLongQuery['getStateCityByLatLong']
  > {
    const UserstateCityCache: GetStateCityByLatLongQuery['getStateCityByLatLong'] =
      this.ls.getItem(USER_STATE_CITY_KEY);
    if (!!UserstateCityCache) return of(UserstateCityCache);

    const UserStatecity = this.getStateCityByLatLongGQL.fetch({ params }).pipe(
      map((data) => {
        return {
          country: data.data.getStateCityByLatLong.country,
          state: data.data.getStateCityByLatLong.state,
          city: data.data.getStateCityByLatLong.city,
        };
      }),
      tap((data) => {
        this.ls.setItem(USER_STATE_CITY_KEY, data);
      }),
    );

    return UserStatecity;
  }

  getCitiesSubscription({
    params,
  }: GetCitiesByStateIdQueryVariables): Observable<
    ApolloQueryResult<GetCitiesByStateIdQuery>
  > {
    return this.getCitiesByStateIdGQL.fetch({ params });
  }

  validateStoreBatchSubscription(
    params: ValidateStoreBatchInput,
  ): Observable<ApolloQueryResult<ValidateStoreBatchQuery>> {
    return this.validateStoreBatchGQL.fetch(
      { params },
      { fetchPolicy: 'no-cache' },
    );
  }

  createStoreBatch(
    items: StoreByCSV[],
    authorizationKey: string,
  ): Observable<
    FetchResult<
      CreateStoreBatchMutation,
      Record<string, any>,
      Record<string, any>
    >
  > {
    const storeBatch: StoreBatchInput[] = items.map((store) => {
      return {
        addressDetail: {
          cityId: store.cityId,
          complement: store.complement,
          district: store.district,
          number: store.number,
          street: store.street,
        },
        name: store.name,
        storeTypeId: store.storeTypeId,
        cnpj: store.cnpj,
      };
    });

    return this.createStoreBatchGQL.mutate({
      params: {
        accessToken: this.ACCESS_TOKEN,
        stores: storeBatch,
        authorizationKey,
      },
    });
  }

  addSingleStore({
    params,
  }: CreateStoreMutationVariables): Observable<
    FetchResult<CreateStoreMutation, Record<string, any>, Record<string, any>>
  > {
    return this.createStoreGQL.mutate({ params });
  }

  deleteStoreById(
    params: DeleteStoreInput,
  ): Observable<
    FetchResult<DeleteStoreMutation, Record<string, any>, Record<string, any>>
  > {
    return this.deleteStoreGQL.mutate({ params });
  }

  deleteStoreBatch(
    params: DeleteStoreBatchInput,
  ): Observable<
    FetchResult<
      DeleteStoreBatchMutation,
      Record<string, any>,
      Record<string, any>
    >
  > {
    return this.deleteStoreBatchGQL.mutate({ params });
  }

  updateStore(
    params: UpdateStoreInput,
  ): Observable<
    FetchResult<UpdateStoreMutation, Record<string, any>, Record<string, any>>
  > {
    return this.updateStoreGQL.mutate({ params });
  }

  getStoreTypes(): Observable<StoreType[]> {
    const storeTypesCache: StoreType[] = this.ls.getItem(STORE_TYPES_KEY);
    if (!!storeTypesCache) return of(storeTypesCache);

    const storeTypes = this.getStoreTypesGQL.fetch().pipe(
      map((data) => {
        return data.data.getStoreTypes.types.map((type) => {
          return { ...type, name: StoreTypesTranslator[type.name] };
        });
      }),
      tap((data) => {
        this.ls.setItem(STORE_TYPES_KEY, data);
      }),
    );
    return storeTypes;
  }

  async getAddressByCEP(cep: string): Promise<CompleteAdress> {
    if (cep.length !== 8) throw new Error('Formato de CEP incorreto');

    try {
      const {
        bairro: district,
        localidade: city,
        logradouro: address,
        uf: state,
        erro,
      } = await this.http
        .get<ResponseAddress>(`https://viacep.com.br/ws/${cep}/json`)
        .toPromise();

      if (erro) throw new Error('CEP não encontrado');
      return { state, city, district, address };
    } catch (err) {
      if (err.message === 'CEP não encontrado') throw new Error(err.message);
      throw new Error('Requisição falhou');
    }
  }

  async getCepByAddress(params: getCepInput): Promise<ResponseCep> {
    const { cityName, federativeUnit, address } = params;
    const cep = await this.http
      .get<ResponseCep>(
        `https://viacep.com.br/ws/${federativeUnit}/${cityName}/${address}/json`,
      )
      .toPromise();

    return { cep };
  }

  async uploadStoreImage(file: File, storeId: string): Promise<string> {
    return this.imageHandlerService.uploadImageToBucket(file, 'store', storeId);
  }
}
