<ng-template #appbar>
  <div class="filters-section d-flex justify-content-between align-items-center">
    <div class="dropwdowns d-inline-flex">
      <app-dropdown
        [options]="states"
        [selected]="selectedState"
        type="states"
        class="me-4"
        [hasSelections]="false"
        dataCy="dropdown-states"
        (click)="states.length === 0 ? retryLoadStates() : null">
      </app-dropdown>

      <ng-container *ngIf="!loadingCities else dropdownSpinner">
        <app-dropdown
          [options]="availableCities" class="me-4"
          [selected]="selectedCity"
          [hasSelections]="false"
          [disabled]="!selectedState.value"
          type="cities"
          dataCy="dropdown-cities">
        </app-dropdown>
      </ng-container>
      <app-dropdown
        [options]="storeTypes"
        [selected]="selectedTypes"
        [hasSelections]="true"
        [disabled]="storeTypes.length === 0"
        [showFilter]="false"
        type="storeTypesMulti"
        dataCy="dropdown-types">
      </app-dropdown>
    </div>
    <div class="select-items" *ngIf="showSelectOptions && storesLength > 0">
      <p 
        *ngIf="!isSelectOn else selectActions"
        matRipple
        class="itens-selection dark-btn"
        tabindex="0"
        (click)="changeSelectOn()"
      >
        Selecionar itens
      </p>
      <ng-template #selectActions>
        <p matRipple *ngIf="!selectAll && !(selectedStoresLength === storesLength )|| selectAll && selectedStoresLength === 0" class="itens-selection dark-btn" (click)="onSelectAll()" tabindex="0" data-cy="button-select-all">Selecionar tudo</p>
        <p matRipple *ngIf="selectAll && !(selectedStoresLength === 0)|| !selectAll && selectedStoresLength === storesLength" class="itens-selection dark-btn" (click)="onUnselectAll()" tabindex="0" data-cy="button-select-all">Desselecionar tudo</p>
        <p class="vl"></p>
        <p matRipple class="itens-selection dark-btn" (click)="onCancelSelect()" tabindex="0" data-cy="button-select-cancel">Cancelar</p>
      </ng-template>
    </div>
  </div>

  <div class="search-bar">
    <app-search-bar-lg 
      (triggerSearch)="onSearch($event)" 
      (resetSearch)="onResetSearch()" 
      [searchInput]="inputSearch">
    </app-search-bar-lg>
  </div>

  <div class="d-flex justify-content-between sort-by" *ngIf="storesLength !== 0">
    <p [style]="{ visibility: (!!inputSearch) ? null : 'hidden'}">
      Resultados encontrados para
      <span>"{{inputSearch}}"</span>
    </p>
    <div class="d-flex">
      <p>Classificar por</p>
      <app-sort-by
        [options]="sortOptions"
        [(selected)]="sortType"
        (triggerSort)="onSort()"
      ></app-sort-by>
    </div>
  </div>

</ng-template>

<ng-template #dropdownSpinner>
  <div class="dropdowns-loading">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
</ng-template>
