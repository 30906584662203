<div class="search-bar">
  <app-search-bar-lg
    (triggerSearch)="onSearch()"
  ></app-search-bar-lg>
</div>

<div class="categories d-flex">
  <app-dropdown-categories class="me-5"
    [selected]="selectedCategory">
  </app-dropdown-categories>

  <app-dropdown-subcategories
    [options]="subcategories"
    (filterEvent)="changeSelectedSubCategory($event)">
  </app-dropdown-subcategories>

  <ng-content></ng-content>
</div>
