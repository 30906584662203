import { SnackbarService } from '@core/services/snackbar.service';
import { ImageHandlerService } from '@services/image-handler.service';
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { readFileAsync } from '@app/shared/utils/readFileAsync-util';


export type ImageOutput = { file: File, image: string | SafeUrl };

@Component({
  selector: 'app-form-image-input',
  templateUrl: './form-image-input.component.html',
  styleUrls: [ './form-image-input.component.scss' ],
})
export class FormImageInputComponent implements OnChanges {
  @Output() imageUploadDone = new EventEmitter<ImageOutput>();
  @Input() borderFormat: 'retangle' | 'circle' = 'circle';
  @Input() resetForm = false;

  productImage: string | SafeUrl = 'assets/dark-theme/products/add-products/single/photo.svg';
  productImageName: string;
  imageValidated = false;
  formFillState = 1;

  constructor(
    private imageHandlerService: ImageHandlerService,
    private snackbarService: SnackbarService,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.resetForm) this.resetSelectedImage();
  }

  async setSelectedPhoto(target: EventTarget): Promise<boolean> {
    const image = (target as HTMLInputElement).files[0];

    try {
      this.imageHandlerService.checkImage(image);
      this.productImage = await readFileAsync(image, 'dataURL');
      this.productImage = this.domSanitizer.bypassSecurityTrustUrl(this.productImage as string);
      this.imageValidated = true;
      this.imageUploadDone.emit({ file: image, image: this.productImage });
      (target as HTMLInputElement).value = '';
      return true;
    } catch(err) {
      this.snackbarService.errorSnackbar(err.message);
      return false;
    }
  }

  resetSelectedImage(): void {
    this.productImage = 'assets/dark-theme/products/add-products/single/photo.svg';
    this.imageValidated = false;
  }
}
