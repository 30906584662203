<ng-template #appbarRequests>
  <div class="filters-section">
    <div #content [class.content]="content.children.length !== 0">
      <ng-content></ng-content>
    </div>
    <div class="search-bar">
      <app-search-bar-lg
        (triggerSearch)="onSearch($event)"
        (resetSearch)="onResetSearch()"
        [searchInput]="inputSearch"
      ></app-search-bar-lg>
    </div>

    <div
      class="d-flex justify-content-between sort-by"
    >
      <div class="d-flex">
        <app-dropdown
          class="sort-request-status"
          [selected]="sortRequestStatusType"
          [hasSelections]="false"
          [options]="requestTypes"
          type="requests"
        ></app-dropdown>
      </div>
      <div class="d-flex">
        <p>Classificar por</p>
        <app-sort-by [(selected)]="sortType" (selectedChange)="onSort($event)">
        </app-sort-by>
      </div>
    </div>
  </div>
</ng-template>
